<form>
  <h2 mat-dialog-title>Formulaire</h2>
  <mat-dialog-content *ngIf="entityForm">
    <!--  <form>-->
    <div fxLayout="row wrap" fxLayoutAlign="start center">
      <esomus-ng-select *ngIf="formOptions" [options]="formOptions"
                        [control]="entityForm.get('form')"
                        i18n-label="@@form" label="Formulaire" fxFlex="100" flex="100"></esomus-ng-select>
      <esomus-ng-select *ngIf="taskTypeOptions" [options]="taskTypeOptions"
                        [control]="entityForm.get('taskType')"
                        i18n-label="@@taskType" label="Type de tâche" fxFlex="100" flex="100"></esomus-ng-select>
    </div>
    <!--  </form>-->
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>Annuler</button>
    <esomus-submit (submit)="submit()"></esomus-submit>
  </mat-dialog-actions>
</form>
