import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FieldType as FormFieldType } from '../../../shared/form-builder/form-builder.component';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { TaskType } from '../../../core/models/task-type.model';
import { TaskTypeService } from '../../../core/api/task-type.service';

@Component({
  selector: 'esomus-task-type',
  templateUrl: './task-type.component.html',
  styleUrls: ['./task-type.component.sass']
})
export class TaskTypeComponent implements OnInit {
  entityForm: FormGroup;
  formFieldType = FormFieldType;
  taskType: TaskType;

  usageTypeOptions: EntityOption;
  typeOptions: EntityOption;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private taskTypeService: TaskTypeService,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((routeParams) => {
      this.entityForm = this.fb.group({
        label: [null],
        anomaly: [null],
        workload: [null],
      });

      const taskTypeID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);
      if (!isNaN(taskTypeID)) {
        this._getTaskType(taskTypeID);
      } else {
        this.taskType = new TaskType();
      }
    });
  }

  private _getTaskType(taskTypeID: number) {
    this.taskTypeService.find(taskTypeID)
      .subscribe((taskType: TaskType) => {
        this.taskType = taskType;
        FormHelper.initValues(this.taskType, this.entityForm);

        this.cd.detectChanges();
      });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    let entity = FormHelper.buildEntity(this.taskType, this.entityForm, {}) as TaskType;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      ((entity.id) ? this.taskTypeService.put(entity) : this.taskTypeService.post(entity)),
      (result: TaskType) => {
        this.entityForm.reset();
        this.toastrService.open((entity.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
        this.routeNameService.goTo('task_types');
      }
    );
  }

  getTaskTypeDeleteURL() {
    return this.taskTypeService.delete(this.taskType.id);
  }

  getTaskTypeSuccessURL() {
    return this.routeNameService.path('task_types');
  }
}
