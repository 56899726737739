import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { environment } from '../../../environments/environment';
import { Entity } from '../models/entity.model';
import { Data } from '../models/data.model';
import { PersonData } from '../models/person-data.model';

const crudPath: CRUDPath = {
  many: `/data/`,
  single: (id: number) => `/data`
};

@Injectable({
  providedIn: 'root'
})
export class PersonDataService extends CRUD<PersonData>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  putData(entity: Entity, personID: number, formID: number) {
    return this.put(entity, `/persons/${personID}/forms/${formID}`);
  }

  getData(personID: number, formID: number) {
    return this.http.get<Array<Data>>(`${environment.api.url}/persons/${personID}/forms/${formID}${this.crudPath.many}`);
  }
}
