import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ColumnType, TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { Entity } from '../../../core/models/entity.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { PersonService } from '../../../core/api/person.service';
import { ScreenEnum } from '../../../core/enum/screen.enum';

@Component({
  selector: 'esomus-person-list',
  templateUrl: './person-list.component.html',
  styleUrls: ['./person-list.component.sass']
})
export class PersonListComponent implements OnInit {
  @ViewChild('personTypeCell', { static: true }) personTypeCell: TemplateRef<any>;

  personTable: TableOptions;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private personService: PersonService,
  ) {
  }

  ngOnInit() {
    this.personTable = {
      columnDefs: [
        {
          name: null,
          prop: 'type', type: ColumnType.TEMPLATE, template: this.personTypeCell
        },
        {
          name: this.i18n({ value: 'Nom', id: 'name' }),
          prop: 'lastName',
        },
        {
          name: this.i18n({ value: 'Prénom', id: 'lastName' }),
          prop: 'firstName',
        },
      ],
      findDataCb: () => this.personService.findAll(),
      actions: {
        create: [
          {
            btnName: 'Ajouter une personne',
            createCb: () => {
              this.routeNameService.goTo('person_add', {}, { personType: 'person' });
            }
          },
          {
            btnName: 'Ajouter une société',
            createCb: () => {
              this.routeNameService.goTo('person_add', {}, { personType: 'company' });
            }
          },
          {
            btnName: 'Ajouter un groupe',
            createCb: () => {
              this.routeNameService.goTo('person_add', {}, { personType: 'group' });
            }
          },
        ],
        readCb: (entity: Entity) => this.routeNameService.goTo('person_view', { id: entity.id }),
      }
    };
  }

  get screenID() {
    return ScreenEnum.PERSON_LIST;
  }
}
