import { Component, OnInit } from '@angular/core';
import { ColumnSortDirection, TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { ObligationTypeService } from '../../../core/api/obligation-type.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { Params } from '@angular/router';
import { Entity } from '../../../core/models/entity.model';
import { ScreenEnum } from '../../../core/enum/screen.enum';

@Component({
  selector: 'esomus-obligation-type-list',
  templateUrl: './obligation-type-list.component.html',
  styleUrls: ['./obligation-type-list.component.sass']
})
export class ObligationTypeListComponent implements OnInit {

  obligationTypesTable: TableOptions;

  constructor(
    private obligationTypeService: ObligationTypeService,
    private i18n: I18n,
    private routeName: RouteNameService
  ) {
  }

  ngOnInit() {
    this.obligationTypesTable = {
      columnDefs: [
        {
          prop: 'label', width: '95%',
          name: this.i18n({value: 'Libellé', id: 'label'})
        },
      ],
      findDataCb: (searchData: Params) => this.obligationTypeService.findAll(),
      actions: {
        columnWidth: '5%',
        create: [
          {
            btnName: this.i18n({value: 'Ajouter un type d\'obligation', id: 'btnAddObligationType'}),
            createCb: () => this.routeName.goTo('obligation_type_add')
          }
        ],
        updateCb: (entity: Entity) => {
          this.routeName.goTo('obligation_type_id', {id: entity.id});
        },
      },
      defaultSortActive: 'label',
      defaultSortDirection: ColumnSortDirection.ASC
    };
  }

  get screenID() {
    return ScreenEnum.OBLIGATION_TYPE_LIST;
  }
}
