import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ActivatedRoute, Params } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { DashboardService } from '../../../core/api/dashboard.service';
import { Dashboard } from '../../../core/models/dashboard.model';

@Component({
  selector: 'esomus-dashboard-screen-wrapper',
  templateUrl: './dashboard-screen-wrapper.component.html',
  styleUrls: ['./dashboard-screen-wrapper.component.sass']
})
export class DashboardScreenWrapperComponent implements OnInit, OnChanges {
  dashboards: Dashboard[];

  @Input() screenID: number;
  @Input() itemID: number;

  params: Params;

  constructor(
    private el: ElementRef,
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private dashboardService: DashboardService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.screenID || changes.itemID) {
      console.log('Dashboard screen update', this.itemID, this.screenID);
      this.params = {
        type: 'dashboard'
      };
      if (this.itemID) {
        this.params['id'] = this.itemID;
      }
      if (this.screenID) {
        this.params['screenID'] = this.screenID;
      }

      this.dashboardService.findListByScreens(this.screenID, this.params).subscribe((result: Dashboard[]) => {
        this.dashboards = result;
        this.cd.detectChanges();
      });
    }
  }
}
