import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { Item } from '../../../core/models/item.model';
import { ColumnSortDirection, ColumnType, TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { BuildingService } from '../../../core/api/building.service';
import { ItemDescriptionService } from '../../../core/api/item-description.service';
import { CategoryService } from '../../../core/api/category.service';
import { ItemStatusService } from '../../../core/api/item-status.service';
import { UserService } from '../../../core/api/user.service';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ToastrService } from '../../../core/services/toastr.service';
import { Entity } from '../../../core/models/entity.model';
import { EquipmentService } from '../../../core/api/equipment.service';
import { ItemDataService } from '../../../core/api/item-data.service';
import { AuthService } from '../../../core/api/auth.service';
import { RightEnum } from '../../../core/enum/right.enum';
import { ScreenEnum } from '../../../core/enum/screen.enum';

@Component({
  selector: 'esomus-building-view',
  templateUrl: './building-view.component.html',
  styleUrls: ['./building-view.component.sass']
})
export class BuildingViewComponent implements OnInit {

  entityForm: FormGroup;
  fieldType = FieldType;
  building: Item;

  localsTable: TableOptions;

  nbLocals: number;

  showLocationImplementBtns: boolean;
  showLocationConfigureBtns: boolean;
  showEquipmentImplementBtns: boolean;
  showLocationManageBtns: boolean;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private buildingService: BuildingService,
    private itemDescriptionService: ItemDescriptionService,
    private categoryService: CategoryService,
    private itemStatusService: ItemStatusService,
    private userService: UserService,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private equipmentService: EquipmentService,
    private itemDataService: ItemDataService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    const buildingID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    this._getBuilding(buildingID);
  }

  private _getBuilding(id: number) {
    this.buildingService.find(id).subscribe((building: Item) => {
      this.authService.fetchPageRights({companyId: building.company.id, siteId: building.site.id, buildingId: building.id})
        .subscribe(() => {
          this.showLocationImplementBtns = this.authService.hasPageRight(RightEnum.LOCATION_IMPLEMENT);
          this.showLocationConfigureBtns = this.authService.hasPageRight(RightEnum.LOCATION_CONFIGURE);
          this.showEquipmentImplementBtns = this.authService.hasPageRight(RightEnum.EQUIPMENT_IMPLEMENT);
          this.showLocationManageBtns = this.authService.hasPageRight(RightEnum.LOCATION_MANAGE);

          this.building = building;

          this.localsTable = {
            columnDefs: [
              {
                name: this.i18n({value: 'Photo', id: 'picture'}),
                type: ColumnType.PICTURE, prop: 'picture', width: '10%'
              },
              {
                name: this.i18n({value: 'Nom', id: 'name'}),
                prop: 'label', width: '35%'
              },
              {
                name: this.i18n({value: 'Commentaire', id: 'comment'}),
                prop: 'comment', width: '50%'
              },
            ],
            findDataCb: () => this.buildingService.getAllLocals(this.building.id),
            actions: {
              canCreate: this.showLocationConfigureBtns,
              create: [
                {
                  btnName: this.i18n({value: 'Ajouter un local', id: 'btnAddLocal'}),
                  createCb: () => this.routeNameService.goTo('local_add', {buildingID: this.building.id})
                },
              ],
              readCb: (entity: Entity) => this.routeNameService.goTo('local_view', {id: entity.id}),
              // deleteCb: (entity: Entity) => this.itemDescriptionService.delete(entity.id)
            },
            defaultSortActive: 'label',
            defaultSortDirection: ColumnSortDirection.ASC
          };

          this.cd.detectChanges();
        });
      // if (!this.building || this.building.itemType.value !== 3) {
      //   return;
      // }
    });
  }

  editBuilding() {
    this.routeNameService.goTo('building_id', {id: this.building.id});
  }

  updateNbLocals(nbItems: number) {
    this.nbLocals = nbItems;
  }

  getCategoryURL() {
    return this.routeNameService.path('category_view', {familyID: this.building.family.id, id: this.building.category.id});
  }

  getItemDescriptionURL() {
    return this.routeNameService.path('item_description_view', {id: this.building.itemDescription.id});
  }

  getFetchDataURL(formID: number) {
    return this.itemDataService.getData(this.building.id, formID);
  }

  get screenID() {
    return ScreenEnum.BUILDING;
  }
}
