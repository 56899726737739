import { Component, EventEmitter, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { Params } from '@angular/router';
import { Entity } from '../../../core/models/entity.model';
import { FormService } from '../../../core/api/form.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { Form } from '../../../core/models/form.model';
import { FormHelper } from '../../../core/services/form-helper.service';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { of } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ScreenEnum } from '../../../core/enum/screen.enum';

@Component({
  selector: 'esomus-form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.sass']
})
export class FormListComponent implements OnInit {
  @ViewChild('headerTable', { static: true }) headerTable: TemplateRef<any>;

  types: EntityOption;
  formTable: TableOptions;
  form: FormGroup;
  search: EventEmitter<any> = new EventEmitter<any>();

  private params: Params;

  constructor(
    public formService: FormService,
    private i18n: I18n,
    private routeName: RouteNameService,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      formType: []
    });
    this.types = { get: () => of(FormHelper.getTypes()), propName: 'label' };
    this.params = {};

    this.formTable = {
      columnDefs: [
        {
          prop: 'name',
          name: this.i18n({ value: 'Nom', id: 'name' }),
          width: '45%'
        },
        {
          prop: 'type',
          name: this.i18n({ value: 'Type', id: 'type' }),
          valueCb: (form: Form) => FormHelper.getType(form.type),
          width: '45%'
        },
      ],
      findDataCb: (searchData: Params) => this.formService.findAll('', this.params),
      actions: {
        create: [
          {
            btnName: this.i18n({ value: 'Ajouter un formulaire', id: 'btnAddForm' }),
            createCb: () => this.routeName.goTo('form')
          }
        ],
        updateCb: (entity: Entity) => {
          this.routeName.goTo('form_id', { id: entity.id });
          // this.userService.find(entity.id).subscribe((user: User) => {
          //   this.formService.updateForm(UsersComponent.userFormID, user);
          // });
        },
        columnWidth: '10%',
        // deleteCb: (entity: Entity) => this.userService.delete(entity.id)
      },
      headTemplate: this.headerTable
    };
  }

  updateType(event: any) {
    delete this.params['formType'];

    if (event) {
      this.params['formType'] = event;
    }

    this.search.emit();
  }

  get screenID() {
    return ScreenEnum.FORM_LIST;
  }
}
