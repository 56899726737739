import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardItemComponent } from './components/dashboard-item/dashboard-item.component';
import { DashboardViewComponent } from './pages/dashboard-view/dashboard-view.component';
import { SharedModule } from '../shared/shared.module';
import { DashboardPrintSelectorComponent } from './components/dashboard-print-selector/dashboard-print-selector.component';
import { DashboardRedirectionComponent } from './pages/dashboard-redirection/dashboard-redirection.component';
import { DashboardInformationComponent } from './pages/dashboard-information/dashboard-information.component';
import { DashboardScreenWrapperComponent } from './components/dashboard-screen-wrapper/dashboard-screen-wrapper.component';

@NgModule({
  declarations: [DashboardItemComponent, DashboardViewComponent, DashboardPrintSelectorComponent, DashboardRedirectionComponent, DashboardInformationComponent, DashboardScreenWrapperComponent],
  exports: [
    DashboardPrintSelectorComponent,
    DashboardItemComponent,
    DashboardScreenWrapperComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class DashboardModule { }
