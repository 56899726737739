import { Entity } from '../../core/models/entity.model';

export class TableItemData extends Entity {
  id: number;
  commonID?: number;
  itemID?: number;

  company: number;
  companyLabel: string;
  site: number;
  siteLabel: string;
  building: number;
  buildingLabel: string;
  local: number;
  localLabel: string;

  family: number;
  familyLabel: string;
  category: number;
  categoryLabel: string;

  situations: Array<Entity>;
  count: number;
  status: string;

  whereLabel: string;
  whatLabel: string;
  situationLabel: string;

  constructor(baseTableItemData?: TableItemData) {
    super();

    if (baseTableItemData) {
      Object.assign(this, baseTableItemData);
    }
  }
}
