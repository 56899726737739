import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Entity } from 'src/app/core/models/entity.model';
import { Logger } from 'src/app/core/services/logger.service';
import { FormField, FormFieldOptions } from '../form-field';
import { map, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { NgSelectConfig } from '@ng-select/ng-select';

@Component({
  selector: 'esomus-ng-select',
  templateUrl: './ng-select.component.html',
  styleUrls: ['./ng-select.component.scss']
})
export class NgSelectComponent extends FormField implements OnInit, OnDestroy {
  itemList: Entity[];
  items: Entity[];

  @Input() multiple: boolean;
  @Input() disabled: boolean;
  @Input() options: EntityOption;
  @Input() minLength: number;

  @Output() valueChange: EventEmitter<any> = new EventEmitter();

  subscriptions: Subscription;
  ngControl: FormControl;

  private defaultValue: any;

  constructor(
    private cd: ChangeDetectorRef,
    private ngSelectConfig: NgSelectConfig
  ) {
    super();
    this.multiple = false;
    this.subscriptions = new Subscription();
  }


  ngOnInit() {
    this.ngControl = new FormControl();
    this.items = [];

    this.defaultValue = this.control.value;

    if (!this.options) {
      Logger.error('NgSelectComponent, property options is undefined');
    }

    this.ngControl.setValidators(this.control.validator);

    if (!this.options.propName) {
      this.options.propName = 'value';
    }

    if (this.multiple) {
      this.control.setValue([]);
    }

    if (this.disabled === null || this.disabled === undefined) {
      this.disabled = false;
    }

    if (this.disabled) {
      this.ngControl.disable();
    }

    this.loadEntities().subscribe((result: Array<Entity>) => {
      this.itemList = result;
      if (this.minLength) {
        this.ngSelectConfig.notFoundText = 'Min length required';
        this.items = [];
        this.cd.detectChanges();
      } else {
        this.items = result;
      }
      if (this.defaultValue === null || (this.defaultValue === null && this.options.autoSelect)) {
        return;
      } else {
        this.control.setValue(this.defaultValue);
      }
    });


    this.subscriptions.add(
      this.control.valueChanges.subscribe((value: any) => {
        this._updateValue();
      })
    );
  }

  loadEntities() {
    return this.options.get()
      .pipe(
        map((entities: Entity[]) => {
          if (this.options.sort) {
            entities.sort(this.options.sort);
          } else {
            // Alphanumeric
            entities.sort((a, b) => {
              if ((typeof a[this.options.propName] === 'string' || a[this.options.propName] instanceof String) &&
                (typeof b[this.options.propName] === 'string' || b[this.options.propName] instanceof String)) {
                return a[this.options.propName].localeCompare(b[this.options.propName]);
              } else {
                if (a[this.options.propName] < b[this.options.propName]) {
                  return -1;
                }
                if (a[this.options.propName] > b[this.options.propName]) {
                  return 1;
                }
                return 0;
              }
            });
          }
          return entities;
        }),
        tap((entities: Entity[]) => {
          if (this.options.autoSelect && entities.length === 1) {
            this.selected(entities[0]);
          }
          this._updateValue();
        })
      );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private _updateValue() {
    if (Array.isArray(this.control.value)) {
      // to number[]
      this.ngControl.setValue(this.control.value.map((entity: Entity) => entity.id));
    } else {
      this.ngControl.setValue(this.control.value);
    }
  }

  selected(entities: Entity | Entity[]): void {
    let value = null;
    if (Array.isArray(entities)) {
      value = [];
      for (const entity of entities) {
        value.push({id: entity.id});
      }
    } else if (entities) {
      value = entities.id;
    }
    this.control.setValue(value, {emitEvent: false});

    this.ngControl.markAsDirty();
    this.control.markAsDirty();

    this.valueChange.emit(value);
  }

  public search(searchString: object) {
    if (this.minLength) {
      if (searchString['term'].length < this.minLength) {
        this.ngSelectConfig.notFoundText = 'Min length required';
        this.items = [];
        this.cd.detectChanges();
      } else {
        this.ngSelectConfig.notFoundText = 'No items found';
        this.items = this.itemList;
        this.cd.detectChanges();
      }
    } else {
      this.items = this.itemList;
    }
  }
}

/**
 * <select></select> with or without HTTP Request (GET parameters or entities for the options)
 */
export class EntityField extends FormFieldOptions {
  type: 'entity';
  multiple?: boolean;
  options: EntityOption;
}

export class EntityOption {
  /**
   * Observable to subscribe to get data
   */
  get: () => Observable<Entity[]|any[]>;
  /**
   * Default: 'value' property name
   */
  propName?: string;
  autoSelect?: boolean;
  sort?: (a: any, b: any) => number;
}
