import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskWorkloadDialogComponent } from './components/task-workload-dialog/task-workload-dialog.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [TaskWorkloadDialogComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class WorkloadModule {
}
