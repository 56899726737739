<form>
  <mat-accordion multi="true" class="mat-edit">
    <mat-expansion-panel expanded="true" *ngIf="person">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{person.type === 1 ? 'Société' : 'Personne'}}: {{person.id ? 'Edition' : 'Création'}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="container">
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
          <div fxLayout="column" fxFlex="30" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <esomus-img fxFlex="100" [picture]="person.picture"></esomus-img>
              <esomus-input-file accept="image/*" [control]="entityForm.controls['picture.upload']"
                                 i18n-label="@@picture" label="Photo" fxFlex="100"
                                 flex="100"></esomus-input-file>
            </div>
          </div>
          <div fxLayout="column" fxFlex>
            <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px grid">
              <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('lastName')"
                            i18n-label="@@lastName" label="Nom" flex="100"
                            fxFlex="50" [autofocus]="true"></esomus-input>
              <esomus-input *ngIf="isPerson && entityForm.get('firstName')" [type]="fieldType.TEXT"
                            [control]="entityForm.get('firstName')"
                            i18n-label="@@firstName" label="Prénom" flex="100"
                            fxFlex="50"></esomus-input>
              <esomus-input *ngIf="!isGroup" [type]="fieldType.TEXT"
                            [control]="entityForm.get('internalNumber1')"
                            i18n-label="@@internalNumber1" label="Numéro interne 1" flex="100"
                            fxFlex="50"></esomus-input>
              <esomus-input *ngIf="isPerson && entityForm.get('internalNumber2')"
                            [type]="fieldType.TEXT" [control]="entityForm.get('internalNumber2')"
                            i18n-label="@@internalNumber2" label="Numéro interne 2" flex="100"
                            fxFlex="50"></esomus-input>
              <esomus-input *ngIf="!isPerson && entityForm.get('internalNumber2')"
                            [type]="fieldType.TEXT" [control]="entityForm.get('internalNumber2')"
                            i18n-label="@@vatNumber" label="Numéro de TVA" flex="100"
                            fxFlex="50"></esomus-input>
              <esomus-input *ngIf="!isGroup" [type]="fieldType.TEXT"
                            [control]="entityForm.get('title')"
                            i18n-label="@@title" label="Titre" flex="100"
                            fxFlex="50"></esomus-input>
              <esomus-input *ngIf="!isGroup" [type]="fieldType.TEXT"
                            [control]="entityForm.get('initial')"
                            i18n-label="@@initial" label="Initiale" flex="100"
                            fxFlex="50"></esomus-input>
              <esomus-input *ngIf="!isGroup" [type]="fieldType.TEXT"
                            [control]="entityForm.get('phone')"
                            i18n-label="@@phone" label="Téléphone" flex="100"
                            fxFlex="50"></esomus-input>
              <esomus-input *ngIf="!isGroup" [type]="fieldType.TEXT"
                            [control]="entityForm.get('fax')"
                            i18n-label="@@fax" label="Fax" flex="100"
                            fxFlex="50"></esomus-input>
              <esomus-input *ngIf="!isGroup" [type]="fieldType.TEXT"
                            [control]="entityForm.get('gsm')"
                            i18n-label="@@gsm" label="GSM" flex="100"
                            fxFlex="50"></esomus-input>
              <esomus-input *ngIf="!isGroup" [type]="fieldType.TEXT"
                            [control]="entityForm.get('mail')"
                            i18n-label="@@mail" label="Email" flex="100"
                            fxFlex="50"></esomus-input>
              <esomus-ng-select *ngIf="languageOptions && entityForm.contains('language')"
                                [options]="languageOptions"
                                [control]="entityForm.get('language')"
                                i18n-label="@@language" label="Langue" fxFlex="50"
                                flex="100"></esomus-ng-select>
              <esomus-textarea [control]="entityForm.get('comment')"
                               i18n-label="@@comment" label="Commentaire" flex="100"
                               fxFlex="100"></esomus-textarea>
              <esomus-line-data *ngIf="!entityForm.contains('form')" fxFlex="100" flex="50"
                                boldValue="Formulaire"
                                [textValue]="person?.form.name"></esomus-line-data>
              <esomus-ng-select *ngIf="formOptions && entityForm.contains('form')"
                                [options]="formOptions"
                                [control]="entityForm.get('form')"
                                i18n-label="@@form" label="Formulaire" fxFlex="100"
                                (valueChange)="updateForm()"
                                flex="100"></esomus-ng-select>
              <mat-checkbox *ngIf="!isGroup" [formControl]="entityForm.get('intern')"
                            i18n-label="@@intern" (change)="updateIntern($event)"
                            label="Interne" fxFlex="50">Interne
              </mat-checkbox>
            </div>
          </div>
        </div>
        <ng-container *ngIf="!isPerson">
          <ng-container *ngTemplateOutlet="personButtons"></ng-container>
        </ng-container>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="isPerson" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Informations complémentaires
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="container">
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px grid">
          <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
            <div *ngIf="isIntern" fxLayout="row wrap" fxLayoutAlign="start">
              <esomus-ng-select *ngIf="companyOptions && entityForm.controls['personLocation.company']"
                                [options]="companyOptions"
                                [control]="entityForm.controls['personLocation.company']"
                                i18n-label="@@company" label="Entreprise" fxFlex="100"
                                (valueChange)="updateSite()"
                                flex="100"></esomus-ng-select>
              <esomus-ng-select *ngIf="siteOptions && entityForm.controls['personLocation.site']"
                                [options]="siteOptions"
                                [control]="entityForm.controls['personLocation.site']"
                                i18n-label="@@site" label="Site" fxFlex="100"
                                (valueChange)="updateBuilding()"
                                flex="100"></esomus-ng-select>
              <esomus-ng-select *ngIf="buildingOptions && entityForm.controls['personLocation.building']"
                                [options]="buildingOptions"
                                [control]="entityForm.controls['personLocation.building']"
                                i18n-label="@@building" label="Building" fxFlex="100"
                                (valueChange)="updateLocal()"
                                flex="100"></esomus-ng-select>
              <esomus-ng-select *ngIf="localOptions && entityForm.controls['personLocation.local']"
                                [options]="localOptions"
                                [control]="entityForm.controls['personLocation.local']"
                                i18n-label="@@local" label="Local" fxFlex="100"
                                flex="100"></esomus-ng-select>
            </div>
          </div>
          <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <esomus-ng-select *ngIf="obligationOptions && entityForm.get('obligations')"
                                [options]="obligationOptions" [multiple]="true"
                                [control]="entityForm.get('obligations')"
                                i18n-label="@@obligations" label="Obligations" fxFlex="100"
                                flex="100"></esomus-ng-select>
            </div>
          </div>
        </div>
      </div>
      <esomus-form-component *ngIf="formID && forms"
                             [formID]="formID" [forms]="forms"
                             [formsMapping]="formsMapping"
                             [fetchDataURL]="getFetchDataURL(formID)"></esomus-form-component>
    </mat-expansion-panel>
    <ng-container *ngIf="isPerson">
      <ng-container *ngTemplateOutlet="personButtons"></ng-container>
    </ng-container>
  </mat-accordion>
</form>

<ng-template #personButtons>
  <div fxLayout="row wrap" fxLayoutAlign="start">
    <div class="btn-group-left" fxFlex="50">
      <esomus-delete-button
        *ngIf="person && person.id && person.active"
        [url]="getPersonDeleteURL()"
        [successUrl]="getPersonSuccessURL()"></esomus-delete-button>
      <esomus-enable-button
        *ngIf="person && person.id && !person.active"
        [url]="getPersonEnableURL()"
        [successUrl]="getPersonSuccessURL()"
      ></esomus-enable-button>
    </div>
    <div class="btn-group-right" fxFlex="50">
      <esomus-back-button></esomus-back-button>
      <esomus-submit (submit)="submit()"></esomus-submit>
    </div>
  </div>
</ng-template>
