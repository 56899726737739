<mat-accordion multi="true">
  <mat-expansion-panel *ngIf="documentsTable" expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Gestion documentaire
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
      <esomus-dashboard-print-selector [screenID]="screeenID"></esomus-dashboard-print-selector>
    </div>
    <esomus-table *ngIf="documentsTable"
                  [options]="documentsTable" [searchReceiver]="documentTableEmitter"></esomus-table>
    <esomus-dashboard-screen-wrapper [screenID]="screenID"></esomus-dashboard-screen-wrapper>
  </mat-expansion-panel>
</mat-accordion>

<esomus-document-version *ngIf="currentDocumentID" [previousID]="currentDocumentID"
                         [documentTableEmitter]="documentTableEmitter"></esomus-document-version>

<ng-template #tableHeader>
  <esomus-ng-select *ngIf="documentTypeOptions" [options]="documentTypeOptions"
                    (valueChange)="submitSearch()"
                    [control]="searchForm.get('documentType')" i18n-label="@@category"
                    label="Catégorie" fxFlex="100"></esomus-ng-select>
  <mat-checkbox [checked]="true" (change)="documentActiveChange($event)">Afficher les actifs</mat-checkbox>
</ng-template>

<ng-template #modal>
  <h3 mat-dialog-title>
    <span>Suppression d'un document</span>
  </h3>
  <div *ngIf="documentToDelete" mat-dialog-content style="min-width: 640px;">
    <span>{{documentToDelete.label}}</span>
  </div>
  <div mat-dialog-actions>
    <button type="button" mat-stroked-button color="success"
            i18n="@@saveAddress" (click)="deleteDocument()">Désactiver
    </button>
    <button type="button" mat-stroked-button i18n="@@cancel" (click)="closeDocumentModal()">Annuler
    </button>
  </div>
</ng-template>
