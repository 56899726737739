import { Component, OnInit } from '@angular/core';
import { ColumnSortDirection, ColumnType, TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Entity } from '../../../core/models/entity.model';
import { UserService } from '../../../core/api/user.service';
import { User } from '../../../core/models/user.model';
import { UserHelper } from '../../../core/services/user-helper.service';
import { Router } from '@angular/router';
import { ScreenEnum } from '../../../core/enum/screen.enum';

@Component({
  selector: 'esomus-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.sass']
})
export class UserListComponent implements OnInit {

  userTable: TableOptions;

  constructor(
    private i18n: I18n,
    private router: Router,
    private userService: UserService,
  ) {
  }

  ngOnInit() {
    this.userTable = {
      columnDefs: [
        {
          name: this.i18n({ value: 'Username', id: 'username' }),
          prop: 'username',
        },
        {
          name: this.i18n({ value: 'Email', id: 'email' }),
          prop: 'email',
        },
        {
          name: this.i18n({ value: 'Actif', id: 'active' }),
          prop: 'active', type: ColumnType.CHECKBOX
        },
        {
          name: this.i18n({ value: 'Light', id: 'light' }),
          prop: 'light', type: ColumnType.CHECKBOX
        },
        {
          name: this.i18n({ value: 'Langue', id: 'language' }),
          prop: 'language', valueCb: (item: User) => UserHelper.getLanguageLabel(item.language)
        },
        {
          name: this.i18n({ value: 'Dernière connexion', id: 'lastLogin' }),
          prop: 'lastLogin', type: ColumnType.DATE
        },
      ],
      findDataCb: () => this.userService.findAll(),
      actions: {
        create: [
          {
            btnName: 'Ajouter un utilisateur',
            createCb: () => this.router.navigate(['/users/add'])
          },
        ],
        readCb: (entity: Entity) => this.router.navigate(['/users', entity.id])
      },
      defaultSortActive: 'email',
      defaultSortDirection: ColumnSortDirection.ASC
    };
  }

  get screenID() {
    return ScreenEnum.USER_LIST;
  }
}
