export const environment = {
  production: true,
  disableLog: true,
  appName: 'Esomus [Demo]',
  languages: ['fr', 'nl'],
  api: {
    domain: 'api.demo.esomus.com',
    url: 'https://api.demo.esomus.com/api'
  },
  version: '12/05/2021 10:51:15'
};
