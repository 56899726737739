import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormService } from '../../../core/api/form.service';
import { EquipmentService } from '../../../core/api/equipment.service';
import { ItemWrapper } from '../../../core/models/item-wrapper.model';
import { ColumnType, TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { Params } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { of } from 'rxjs/internal/observable/of';
import { Item } from '../../../core/models/item.model';

@Component({
  selector: 'esomus-task-scope-partial-dialog',
  templateUrl: './task-scope-partial-dialog.component.html',
  styleUrls: ['./task-scope-partial-dialog.component.sass']
})
export class TaskScopePartialDialogComponent implements OnInit {
  tableOptions: TableOptions;
  @ViewChild('headerTable', { static: true })
  headerTable: TemplateRef<any>;
  @ViewChild('selectedCellTemplate', { static: true })
  selectedCellTemplate: TemplateRef<any>;
  search: EventEmitter<any> = new EventEmitter<any>();

  loading: boolean;

  fieldType = FieldType;
  taskTypeOptions: EntityOption;
  formOptions: EntityOption;
  entityForm: FormGroup;
  searchForm: FormGroup;

  private items: ItemWrapper[];
  private lsItems: Item[];
  private itemsToDisplay: Item[];

  containerOptions: EntityOption;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      data: {
        company: string,
        site: string,
        building?: string,
        local?: string,
        family?: string,
        category?: string,
        situation?: Array<string>,
      },
      title: string
    },
    private equipmentService: EquipmentService,
    private formService: FormService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private i18n: I18n,
    private dialogRef: MatDialogRef<TaskScopePartialDialogComponent>
  ) {
  }

  ngOnInit(): void {
    this.loading = true;

    this.equipmentService.findAllByFilter(this.data.data).subscribe(s => {
      this.items = s;
      this.loading = false;

      this.entityForm = this.fb.group({});

      this.lsItems = [];
      for (const item of this.items) {
        this.entityForm.addControl(`selected_${item.id}`, this.fb.control(false));
        this.lsItems.push(item);
        for (const subItem of item.items) {
          this.entityForm.addControl(`selected_${subItem.id}`, this.fb.control(false));
          this.lsItems.push(subItem);
        }
      }

      this.containerOptions = {
        get: () => of(this.items.filter(f => [6, 7].indexOf(f.itemType.value) !== -1 && f.items.length > 0)), propName: 'description'
      };

      this.searchForm = this.fb.group({
        container: [null]
      });

      this.tableOptions = {
        columnDefs: [
          {
            prop: 'selected',
            type: ColumnType.TEMPLATE, template: this.selectedCellTemplate
          },
          {
            prop: 'description',
            name: this.i18n({ value: 'Libellé', id: 'label' })
          },
          {
            prop: 'status.label',
            name: this.i18n({ value: 'Statut', id: 'statusLabel' })
          },
          {
            prop: 'situation.label',
            name: this.i18n({ value: 'Situation', id: 'situationLabel' })
          },
          {
            prop: 'containerName',
            name: this.i18n({ value: 'Dans', id: 'inContainer' }),
            valueCb: (entity: ItemWrapper) => entity.container && [6, 7].indexOf(entity.container.itemType.value) !== -1 ? entity.container.name : null
          },
        ],
        findDataCb: (searchData: Params) => of(this.itemsToDisplay),
        headTemplate: this.headerTable
      };

      this.updateItemsToDisplay();

      this.cd.detectChanges();
    });
  }

  updateItemsToDisplay() {
    const container = this.searchForm.get('container').value;
    this.itemsToDisplay = [];

    if (container) {
      const containerItem = this.items.find(f => f.id === container);
      // this.itemsToDisplay.push(containerItem); // Container is hidden in "Container search"
      for (const subItem of containerItem.items) {
        this.itemsToDisplay.push(subItem);
      }
    } else {
      for (const item of this.items) {
        this.itemsToDisplay.push(item);

        for (const subItem of item.items) {
          this.itemsToDisplay.push(subItem);
        }
      }
    }

    this.search.emit();
  }

  submit() {
    const result = [];
    const pattern = new RegExp(/selected_(\d+)/);

    for (const control in this.entityForm.controls) {
      if (!this.entityForm.controls.hasOwnProperty(control)) {
        continue;
      }

      const value = this.entityForm.controls[control].value;
      if (!value) {
        continue;
      }

      const id = parseInt(pattern.exec(control)[1], 10);
      const item = this.lsItems.find(f => f.id === id);

      result.push(item);
    }

    this.dialogRef.close(result);
  }
}
