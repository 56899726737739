import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskComponent } from './pages/task/task.component';
import { TaskViewComponent } from './pages/task-view/task-view.component';
import { TaskPlanificationComponent } from './pages/task-planification/task-planification.component';
import { SharedModule } from '../shared/shared.module';
import { ActionsAnomaliesModule } from '../actions-anomalies/actions-anomalies.module';
import { TaskPanelComponent } from './components/task-panel/task-panel.component';
import { TaskListComponent } from './pages/task-list/task-list.component';
import { FormsModule } from '../forms/forms.module';
import { DocumentsModule } from '../documents/documents.module';
import { MatDialogModule } from '@angular/material/dialog';
import { DashboardModule } from '../dashboard/dashboard.module';
import { ActionsModule } from '../actions/actions.module';
import { TaskScopePartialDialogComponent } from './components/task-scope-partial-dialog/task-scope-partial-dialog.component';
import { TaskStatusListDialogComponent } from './components/task-status-list-dialog/task-status-list-dialog.component';
import { TaskStatusDialogComponent } from './components/task-status-dialog/task-status-dialog.component';

@NgModule({
  declarations: [TaskComponent, TaskViewComponent, TaskPlanificationComponent, TaskPanelComponent, TaskListComponent, TaskScopePartialDialogComponent, TaskStatusListDialogComponent, TaskStatusDialogComponent],
  exports: [TaskPanelComponent],
  imports: [
    CommonModule,
    SharedModule,
    ActionsAnomaliesModule,
    FormsModule,
    DocumentsModule,
    MatDialogModule,
    DashboardModule,
    ActionsModule,
  ]
})
export class TasksModule {
}
