<mat-expansion-panel [expanded]="expanded">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Actions : {{nbItems}}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <esomus-table [options]="itemTable" [searchReceiver]="search" (getLength)="updateNbItems($event)"></esomus-table>
</mat-expansion-panel>

<ng-template #headerActionTable>
  <esomus-ng-select *ngIf="cOptions" [options]="cOptions"
                    [control]="form.get('c')" (valueChange)="cChange()"
                    i18n-label="@@c" label="Clôturé" fxFlex></esomus-ng-select>
  <ng-container *ngIf="taskID">
    <esomus-ng-select *ngIf="buildingOptions" [options]="buildingOptions"
                      [control]="form.get('building')" (valueChange)="buildingChange()"
                      i18n-label="@@building" label="Bâtiment" fxFlex></esomus-ng-select>
    <esomus-submit *ngIf="currentBuildingID" fxFlex label="Clôturer les actions" (submit)="openConfirmModal()"></esomus-submit>
  </ng-container>
</ng-template>

<ng-template #confirmModal>
  <form>
    <h3 mat-dialog-title>
      <span>Valider la clôture</span>
    </h3>
    <div mat-dialog-content style="min-width: 480px;">
      <p>Confirmez-vous la clôture des actions ?</p>
    </div>
    <div mat-dialog-actions>
      <esomus-submit label="Valider" (submit)="closeActions()"></esomus-submit>
      <button type="button" mat-stroked-button i18n="@@cancel" (click)="closeConfirmModal()">
        Annuler
      </button>
    </div>
  </form>
</ng-template>
