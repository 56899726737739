<h3 mat-dialog-title>
  <span>Historique des status</span>
</h3>
<div mat-dialog-content style="min-width: 640px;">
  <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px grid">
    <esomus-table fxFlex="100" [options]="statusTable"></esomus-table>
  </div>
</div>
<div mat-dialog-actions>
  <button type="button" mat-stroked-button i18n="@@close" (click)="closeModal()">Fermer
  </button>
</div>
