import { AbstractHelper } from './abstract-helper';

export class ItemHelper extends AbstractHelper {
  static getOwnerOptions() {
    return [
      {id: 0, label: 'Appartient au client'},
      {id: 1, label: 'Appartient à un autre fournisseur'},
      {id: 2, label: 'Appartient au fourniseur gestionnaire ESOMUS'}
    ];
  }

  static getOwnerLabel(type: number) {
    return this._getLabel(type, this.getOwnerOptions());
  }
}
