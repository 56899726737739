<mat-form-field *ngIf="options" [class.mat-form-field-invalid]="control && control.dirty && control.errors"
                [class.hidden]="!visible" appearance="outline" floatLabel="always" fxFlex.xs="100"
                [fxFlex]="flex">
  <mat-label>{{label}}</mat-label>
  <ng-select ngSelectMat [items]="items" [multiple]="multiple"
             [bindLabel]="options.propName" bindValue="id"
             placeholder="" (change)="selected($event)" appendTo="body" [formControl]="ngControl"
             (search)="search($event)">
  </ng-select>
  <esomus-input-error [control]="control" [required]="required"></esomus-input-error>
</mat-form-field>
