import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Item } from '../../../core/models/item.model';
import { RouteNameService } from '../../../core/services/route-name.service';
import { EquipmentService } from '../../../core/api/equipment.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { EquipmentStatusModalComponent } from '../equipment-status-modal/equipment-status-modal.component';
import { EquipmentConformityModalComponent } from '../equipment-conformity-modal/equipment-conformity-modal.component';
import { ItemHelper } from '../../../core/services/item-helper.service';
import { ScreenEnum } from '../../../core/enum/screen.enum';

@Component({
  selector: 'esomus-equipment-panel',
  templateUrl: './equipment-panel.component.html',
  styleUrls: ['./equipment-panel.component.sass']
})
export class EquipmentPanelComponent implements OnInit {

  @Input() equipment: Item;
  @Input() panelDisabled: boolean;
  @Input() expanded = true;
  @Input() fromEquipment = false;
  @Input() showBtns: boolean;

  label: string;
  isEquipment: boolean;
  isContainer: boolean;
  isVehicle: boolean;

  actionAvailable: () => boolean;
  actionStatusCb: () => any;
  actionConformityCb: () => any;

  situationColor: string;
  statusLabel: string;
  statusColor: string;
  conformityLabel: string;
  conformityColor: string;
  expirationLabel: string;
  expirationColor: string;

  constructor(
    private routeNameService: RouteNameService,
    private equipmentService: EquipmentService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.isContainer = this.equipment.itemType.value === 6;
    this.isVehicle = this.equipment.itemType.value === 7;
    this.isEquipment = this.equipment.itemType.value === 8;

    if (this.isContainer) {
      this.label = 'Conteneur';
    } else if (this.isVehicle) {
      this.label = 'Véhicule';
    } else {
      this.label = 'Equipement';
    }

    this.generateSituationColor();

    this.generateStatusColor();
    this.generateStatusLabel();

    if (this.equipment.itemDescription.controllable) {
      this.generateConformityColor();
      this.generateConformityLabel();
    }

    if (this.equipment.expirationDate) {
      this.generateExpirationLabel();
      this.generateExpirationColor();
    }

    if (this.fromEquipment) {
      this.actionAvailable = () => this.equipment.active;
      this.actionStatusCb = () => this.openStatusModal();

      if (this.equipment.itemDescription.controllable) {
        this.actionConformityCb = () => this.openConformityModal();
      }
    }
  }

  editEquipment() {
    this.routeNameService.goTo('equipment_id', {id: this.equipment.id});
  }

  get screenID() {
    switch (true) {
      case this.isEquipment:
        return ScreenEnum.EQUIPMENT;
      case this.isContainer:
        return ScreenEnum.CONTAINER;
      case this.isVehicle:
        return ScreenEnum.VEHICLE;
    }
    return null;
  }

  getContainerURL() {
    return this.routeNameService.path('equipment_view', {id: this.equipment.container.id});
  }

  getBuildingURL() {
    return this.equipment.building ? this.routeNameService.path('building_view', {id: this.equipment.building.id}) : null;
  }

  getLocalURL() {
    return this.equipment.local ? this.routeNameService.path('local_view', {id: this.equipment.local.id}) : null;
  }

  isInContainer() {
    return this.equipment.container && [6, 7].indexOf(this.equipment.container.itemType.value) !== -1;
  }

  // region Label / Color

  generateSituationColor() {
    switch (this.equipment.situation.value) {
      case 1:
        this.situationColor = 'success';
        break;
      case 2:
        this.situationColor = 'primary';
        break;
      case 3:
        this.situationColor = 'success';
        break;
      case 4:
        this.situationColor = 'success';
        break;
      case 5:
        this.situationColor = 'danger';
        break;
      default:
        this.situationColor = 'danger';
        break;
    }
  }

  generateStatusLabel() {
    if (this.equipment.status === null) {
      this.statusLabel = null;
      return;
    }

    this.statusLabel = this.equipment.status.label;
  }

  generateStatusColor() {
    if (this.equipment.status === null) {
      this.statusColor = null;
      return;
    }

    switch (this.equipment.status.state) {
      case 0:
        this.statusColor = 'success';
        break;
      case 1:
        this.statusColor = 'danger';
        break;
      case 2:
        this.statusColor = 'grey';
        break;
      default:
        // Fallback
        console.error('Fallback equipment status', this.equipment.status);
        switch (this.equipment.status.value) {
          case 0:
            this.statusColor = 'success';
            break;
          default:
            this.statusColor = 'danger';
            break;
        }
        break;
    }
  }

  generateConformityLabel() {
    if (this.equipment.conformity) {
      this.conformityLabel = 'Conforme';
    } else {
      this.conformityLabel = 'Non conforme';
    }
  }

  generateConformityColor() {
    if (this.equipment.conformity) {
      this.conformityColor = 'success';
    } else {
      this.conformityColor = 'danger';
    }
  }

  generateExpirationLabel() {
    let expirationDate = moment(this.equipment.expirationDate);
    let currentDate = moment();

    if (currentDate.isAfter(expirationDate, 'days')) {
      this.expirationLabel = 'Périmé';
    } else {
      let lastMonth = expirationDate.clone().subtract(1, 'months');
      if (lastMonth.isBefore(currentDate, 'days')) {
        this.expirationLabel = 'A remplacé';
      } else {
        this.expirationLabel = 'Péremption';
      }
    }
  }

  generateExpirationColor() {
    let expirationDate = moment(this.equipment.expirationDate);
    let currentDate = moment();

    if (currentDate.isAfter(expirationDate, 'days')) {
      this.expirationColor = 'danger';
    } else {
      let lastMonth = expirationDate.clone().subtract(1, 'months');
      if (lastMonth.isBefore(currentDate, 'days')) {
        this.expirationColor = 'warning';
      } else {
        this.expirationColor = 'success';
      }
    }
  }

  // endregion

  openStatusModal() {
    const dialogRef = this.dialog.open(EquipmentStatusModalComponent, {
      data: {equipment: this.equipment}, width: '780px'
    });

    dialogRef.afterClosed().subscribe((result: Item) => {
      if (result) {
        this.equipment.status = result.status;

        this.generateStatusLabel();
        this.generateStatusColor();

        this.cd.detectChanges();
      }
    });
  }

  openConformityModal() {
    const dialogRef = this.dialog.open(EquipmentConformityModalComponent, {
      data: {equipment: this.equipment}, width: '780px'
    });

    dialogRef.afterClosed().subscribe((result: Item) => {
      if (result) {
        this.equipment.conformity = result.conformity;

        this.generateConformityLabel();
        this.generateConformityColor();

        this.cd.detectChanges();
      }
    });
  }

  get ownerLabel() {
    return ItemHelper.getOwnerLabel(this.equipment.owner);
  }
}
