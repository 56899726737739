import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionAddComponent } from './pages/action-add/action-add.component';
import { ActionComponent } from './pages/action/action.component';
import { ActionViewComponent } from './pages/action-view/action-view.component';
import { SharedModule } from '../shared/shared.module';
import { EquipmentsModule } from '../equipments/equipments.module';
import { AnomaliesModule } from '../anomalies/anomalies.module';
import { ActionRealisationComponent } from './pages/action-realisation/action-realisation.component';
import { FormsModule } from '../forms/forms.module';
import { DocumentsModule } from '../documents/documents.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { ActionTaskTableComponent } from './components/action-task-table/action-task-table.component';

@NgModule({
  declarations: [ActionAddComponent, ActionComponent, ActionViewComponent, ActionRealisationComponent, ActionTaskTableComponent],
  exports: [
    ActionComponent,
    ActionTaskTableComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    EquipmentsModule,
    AnomaliesModule,
    FormsModule,
    DocumentsModule,
    DashboardModule
  ]
})
export class ActionsModule { }
