<mat-accordion multi="true" *ngIf="itemDescription">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Fiche : {{itemDescription.label}} - {{itemDescription.itemType.label}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayoutAlign="center center">
          <button *ngIf="showBtns" mat-flat-button (click)="editItemDescription()" color="success">
            <mat-icon>edit</mat-icon>
            Editer
          </button>
        </div>
        <esomus-dashboard-print-selector [screenID]="screenID"
                                         [itemID]="itemDescription.id"></esomus-dashboard-print-selector>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <esomus-info-box *ngIf="!itemDescription.active" fxFlex="20"
                         [active]="itemDescription.active"></esomus-info-box>
        <div fxLayout="column" fxFlex="30" fxFlex.xs="100">
          <div fxLayout="row wrap" fxLayoutAlign="start center">
            <esomus-img fxFlex="100" [picture]="itemDescription.picture"></esomus-img>
            <esomus-line-data fxFlex="100" boldFlex="50"
                              textFlex="50"
                              boldValue="Code esomus"
                              [textValue]="itemDescription.code"></esomus-line-data>
          </div>
        </div>
        <div fxLayout="column" fxFlex>
          <div fxLayout="row wrap" fxLayoutAlign="start">
            <esomus-line-data flex="50" fxFlex="100" boldValue="Libellé"
                              [textValue]="itemDescription.label"></esomus-line-data>
            <esomus-line-data flex="100" fxFlex="50" boldValue="Famille"
                              [textValue]="itemDescription.family.label"
                              [textURL]="getFamilyURL()"></esomus-line-data>
            <esomus-line-data flex="100" fxFlex="50" boldValue="Catégorie"
                              [textValue]="itemDescription.category.label"
                              [textURL]="getCategoryURL()"></esomus-line-data>
            <ng-container *ngIf="isEquipment">
              <esomus-line-data flex="100" fxFlex="50" boldValue="Contrôlable"
                                [textValue]="itemDescription.controllable ? 'Oui' : 'Non'"></esomus-line-data>
              <esomus-line-data flex="100" fxFlex="50" boldValue="Certification CE"
                                [textValue]="itemDescription.ceCertification ? 'Oui' : 'Non'"></esomus-line-data>
              <esomus-line-data flex="100" fxFlex="50" boldValue="Modèle"
                                [textValue]="itemDescription.model"></esomus-line-data>
              <esomus-line-data flex="100" fxFlex="50" boldValue="Type"
                                [textValue]="itemDescription.type"></esomus-line-data>
              <esomus-line-data flex="100" fxFlex="50" boldValue="Durée d'utilisation"
                                [textValue]="itemDescription.duration ? (itemDescription.duration.toString() + ' mois') : null"></esomus-line-data>
            </ng-container>
            <esomus-line-data flex="100" fxFlex="100" boldValue="Commentaire"
                              [textValue]="itemDescription.comment"
                              boldFlex="25" textFlex="75"
            ></esomus-line-data>
            <esomus-line-data *ngIf="isEquipment" flex="100" fxFlex="50" boldValue="Poids"
                              [textValue]="itemDescription.weight ? itemDescription.weight?.toString() + ' kg' : null"
            ></esomus-line-data>
            <esomus-line-data flex="100" fxFlex="50" boldValue="Code ERP"
                              [textValue]="itemDescription.erpCode"
            ></esomus-line-data>
            <esomus-line-data *ngIf="isEquipment" flex="100" fxFlex="50" boldValue="Code BENOR"
                              [textValue]="itemDescription.benorCode"
            ></esomus-line-data>
            <ng-container *ngIf="isBuilding">
              <esomus-line-data flex="50" fxFlex="100" boldValue="Contrôlable"
                                [textValue]="itemDescription.controllable ? 'Oui' : 'Non'"></esomus-line-data>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Information complémentaire
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <esomus-form-display *ngIf="itemDescription.category.form"
                               [fetchDataURL]="getFetchDataURL(itemDescription.category.form.id)"></esomus-form-display>
        </div>
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <esomus-form-display *ngIf="itemDescription.form"
                               [fetchDataURL]="getFetchDataURL(itemDescription.form.id)"></esomus-form-display>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <esomus-obligation-item-description-panel
    [itemDescription]="itemDescription"></esomus-obligation-item-description-panel>
  <esomus-document-table [showBtns]="showBtns" [parent]="itemDescription"
                         parentType="itemDescription"></esomus-document-table>
  <esomus-contact-table *ngIf="isEquipment" [parent]="itemDescription"
                        [parentType]="'itemDescription'"></esomus-contact-table>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Charge de travail : {{nbWorkloads}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <esomus-table *ngIf="workloadsTable" (getLength)="updateNbWorkloads($event)"
                  [options]="workloadsTable" [searchReceiver]="workloadTableEmitter"></esomus-table>
  </mat-expansion-panel>
  <esomus-item-description-task-form-panel [itemDescription]="itemDescription" [showBtns]="showBtns"></esomus-item-description-task-form-panel>
  <esomus-dashboard-screen-wrapper [screenID]="screenID"
                                   [itemID]="itemDescription.id"></esomus-dashboard-screen-wrapper>
</mat-accordion>
