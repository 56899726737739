import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { Item } from '../../../core/models/item.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { ItemDescriptionService } from '../../../core/api/item-description.service';
import { CategoryService } from '../../../core/api/category.service';
import { ItemStatusService } from '../../../core/api/item-status.service';
import { UserService } from '../../../core/api/user.service';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { FormsMap, FormsMappingMap } from '../../../forms/components/form-component/form-component.component';
import { BuildingService } from '../../../core/api/building.service';
import { ItemDataService } from '../../../core/api/item-data.service';
import { ScreenEnum } from '../../../core/enum/screen.enum';

@Component({
  selector: 'esomus-building',
  templateUrl: './building.component.html',
  styleUrls: ['./building.component.sass']
})
export class BuildingComponent implements OnInit {

  entityForm: FormGroup;
  fieldType = FieldType;

  building: Item;

  selectOptions: any;

  forms: FormsMap;
  formsMapping: FormsMappingMap;
  formsValidation: boolean[];

  nbForms: number;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private buildingService: BuildingService,
    private itemDescriptionService: ItemDescriptionService,
    private categoryService: CategoryService,
    private itemStatusService: ItemStatusService,
    private userService: UserService,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private itemDataService: ItemDataService
  ) {
    this.selectOptions = {};
  }

  ngOnInit() {
    this.entityForm = this.fb.group({
      description: [],
      comment: [],
      'picture.upload': [],
      code: [],
      omnium: [],
      renting: [],
      buyCode: [null, [Validators.maxLength(30)]],
      clientCode: [null, [Validators.maxLength(30)]],
      referenceCode: [null, [Validators.maxLength(30)]],
      'address.line1': [],
      'address.coordinates': [],
    });

    const buildingID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    this._getBuilding(buildingID);
  }

  private _getBuilding(id: number) {
    this.buildingService.find(id).subscribe((building: Item) => {
      this.building = building;

      if (this.building.category.form || this.building.itemDescription.form) {
        this.forms = {};
        this.formsMapping = {};
      }

      FormHelper.initValues(this.building, this.entityForm);

      this.cd.detectChanges();
    });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    this.nbForms = 1;
    this.formsValidation = [];

    for (const key in this.forms) {
      this.nbForms++;
      if (this.forms[key].invalid) {
        return;
      }
    }

    for (const key in this.formsMapping) {
      const entityData = FormHelper.buildEntity(this.formsMapping[key], this.forms[key], {});
      FormHelper.submitForm(
        this.cd,
        this.forms[key],
        this.itemDataService.putData(entityData, this.building.id, parseInt(key.substr(5), 10)),
        () => {
          this.formsValidation.push(true);
          this._handleFormsSubmit();
        }
      );
    }


    let entity = FormHelper.buildEntity(this.building, this.entityForm, {
      'picture.upload': {type: FieldType.FILE, multiple: false}
    }) as Item;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      this.buildingService.put(entity),
      () => {
        this.formsValidation.push(true);
        this._handleFormsSubmit();
      }
    );
  }

  private _handleFormsSubmit() {
    if (this.formsValidation.length === this.nbForms) {
      this.toastrService.open(ToastrMessageType.UPDATE);
      this.entityForm.reset();
      this.routeNameService.goTo('building_view', {id: this.building.id});
    }
  }

  getFetchDataURL(formID: number) {
    return this.itemDataService.getData(this.building.id, formID);
  }
}
