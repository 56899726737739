import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Params } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Observable, Subscription } from 'rxjs';
import { Entity } from 'src/app/core/models/entity.model';
import { ToastrMessageType, ToastrService } from 'src/app/core/services/toastr.service';
import { propertiesToValue } from 'src/app/core/utils.function';
import { FormEvent, FormEventType, FormService } from 'src/app/shared/form-builder/services/form.service';

@Component({
  selector: 'esomus-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() options: TableOptions;
  @Output() getLength: EventEmitter<number> = new EventEmitter<number>();
  @Input() searchReceiver: EventEmitter<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  dataSource: MatTableDataSource<any>;

  displayedColumns = [];
  columnsControl: FormControl;
  columnList = [];
  dataPropId: string;
  isLoadingData: boolean;
  isDeletingDataId: number;
  flexAlignHeaderFooter: string;

  searchData: Params;

  subscriptions: Subscription;

  ColumnType = ColumnType;

  constructor(
    private formService: FormService,
    private i18n: I18n,
    private toastrService: ToastrService
  ) {
    this.isLoadingData = false;
    this.subscriptions = new Subscription();
    this.columnsControl = new FormControl();
    this.searchData = {};
    this.flexAlignHeaderFooter = 'space-between center';
    this.isDeletingDataId = 0;
  }

  ngOnInit() {
    if (!this.options) {
      throw new Error('Property "options" is undefined for TableComponent');
    }

    // == FLEX ALIGN
    if (!this.options.actions || !this.options.actions.create || !this.options.actions.create.length) {
      if (!this.options.headTemplate) {
        this.flexAlignHeaderFooter = 'end center';
      }
    }

    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    //
    // == Create columns
    //
    let columnDefs = this.options.columnDefs;

    if (this.options.actions) {
      this.displayedColumns.push('type:actions');
      this.columnList.push({
        value: 'type:actions', name: null
      });
    }

    for (let i = 0; i < columnDefs.length; i++) {
      let columnDef = columnDefs[i];
      let prop = columnDef.prop;
      if (!prop) {
        prop = `type:${columnDef.type}`;
        columnDefs[i].prop = prop;
      }

      if (columnDef.isVisible === undefined || columnDef.isVisible) {
        this.displayedColumns.push(prop);
        this.columnList.push({ value: prop, name: columnDef.name });
      }

      if (columnDef.type === ColumnType.ID) {
        this.dataPropId = columnDef.prop;
      }
    }

    // Default firstLoad
    if (this.options.firstLoad === undefined) {
      this.options.firstLoad = true;
    }

    this.columnsControl.setValue(this.displayedColumns);
    let columnsSub = this.columnsControl.valueChanges.subscribe((values: string[]) => {
      this.displayedColumns = values;
    });
    this.subscriptions.add(columnsSub);

    //
    // == Subscribe Form Event
    //
    // CRUD Event
    if (this.options.crudFormID) {
      this.subscriptions.add(
        this.formService.catchForm(this.options.crudFormID, FormEventType.SUBMIT_DATA)
          .subscribe((event: FormEvent) => {
            // load old search data
            this._refreshData(this.searchData);
          })
      );
    }
    // Search form event
    if (this.options.searchFormID) {

      if (typeof this.options.columnToDisplayEnabled !== 'boolean') {
        this.options.columnToDisplayEnabled = true;
      }

      this.subscriptions.add(
        this.formService.catchForm(this.options.searchFormID, FormEventType.SUBMIT_DATA)
          .subscribe((event: FormEvent) => {
            this.searchData = event.data;
            this._refreshData(event.data);
          })
      );
    }

    if (this.searchReceiver) {
      this.subscriptions.add(
        this.searchReceiver.subscribe((searchData: Params) => {
          this.searchData = searchData;

          this._refreshData(searchData);
        }));
    }

    // Default search enabled
    if (typeof this.options.searchEnabled !== 'boolean') {
      this.options.searchEnabled = true;
    }
  }

  private _refreshData(searchData: Params) {
    this.isLoadingData = true;
    this.dataSource.data = [];
    this.options.findDataCb(searchData).subscribe((data: Entity[]) => {
      this.dataSource.data = data;
      this.isLoadingData = false;
      this.getLength.emit(data.length);
    });
  }

  ngAfterViewInit(): void {
    if (this.options.firstLoad) {
      this._refreshData({});
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Use for MatRow double click
   */
  editEntity(entity: Entity) {
    if (!this.options.actions) {
      return;
    } else if (this.options.actions.readCb) {
      this.options.actions.readCb(entity);
    } else if (this.options.actions.updateCb) {
      this.options.actions.updateCb(entity);
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getValue(data: any, columnDef: ColumnDef) {
    const prop = columnDef.prop;
    const propertyValue = `${columnDef.prop}_customValue`;

    if (columnDef.valueCb && !data.hasOwnProperty(propertyValue)) {
      data[propertyValue] = columnDef.valueCb(data);
    }

    if (data.hasOwnProperty(propertyValue)) {
      return data[propertyValue];
    }

    if (data.hasOwnProperty(prop)) {
      return data[prop];
    }

    data[prop] = propertiesToValue(prop, data);
    return data[prop];
  }

  deleteEntity(data: Entity, confirm?: boolean) {
    if (confirm === undefined) {
      this.isDeletingDataId = data.id;
      return;
    }

    if (confirm) {
      this.options.actions.deleteCb(data).subscribe(() => {
        this._refreshData(this.searchData);
        this.toastrService.open(ToastrMessageType.DELETE);
      });
    }
    this.isDeletingDataId = null;
  }
}

/**
 * = TABLE OPTIONS
 */
export class TableOptions {
  /**
   * To refresh data on submit search form
   * If exists : display 'create' buttons in the footer and columns list
   */
  searchFormID?: string;
  /**
   * To refresh data on submit crud form (Create or Update entity)
   */
  crudFormID?: string;
  footerVisible?: boolean;
  /**
   * Enable the field "column to display" in the header
   */
  columnToDisplayEnabled?: boolean;
  /**
   * Enable the field "search" in the header
   */
  searchEnabled?: boolean;
  /**
   * column name, visibility, property to display or template
   */
  columnDefs: ColumnDefs;
  /**
   * Property name to sorted
   */
  defaultSortActive?: string;
  /**
   * Sort direction : 'asc' or 'desc'
   */
  defaultSortDirection?: ColumnSortDirection;
  /**
   * callback to get entities according to the search data
   */
  findDataCb: TableFindDataCb;
  /**
   * Default: true
   */
  firstLoad?: boolean;
  rights?: any[]; // TODO
  /**
   * display buttons and callbacks on click event.
   *    - Create buttons are displayed above and below of the table.
   *    - The others buttons are displayed in the 'Actions' column.
   */
  actions?: TableActions;
  headTemplate?: TemplateRef<any>;
}

//
// == ColumnDef
//
export interface ColumnDef {
  prop?: string;
  name?: string;
  isVisible?: boolean;
  /**
   * Add footer cell
   */
  footerDef?: FooterTemplateRef | BasicFooterDef;
  /**
   * Width with unit (Ex: 100px)
   * Default: 100% / number of columns (except Actions column)
   */
  width?: string;
  /**
   * Custom value
   */
  valueCb?: (entity: Entity) => string | number;
}

export interface BasicColumnDef extends ColumnDef {
  type?: ColumnType.ID | ColumnType.CHECKBOX | ColumnType.DATE | ColumnType.PICTURE | ColumnType.ICON;
}

export interface ColumnTemplateRef extends ColumnDef {
  type: ColumnType.TEMPLATE;
  /**
   * The entity will be injected in the template (let-entity)
   */
  template: TemplateRef<any>;
}

//
// == FooterDef
//
export interface BasicFooterDef {
  type: ColumnType.VALUE;
  value: any;
}

export interface FooterTemplateRef {
  type: ColumnType.TEMPLATE;
  /**
   * The entities will be injected in the template (let-entities)
   */
  template: TemplateRef<any>;
}

//
// == ENUM
//
export enum ColumnType {
  ID = 'id',
  CHECKBOX = 'checkbox',
  DATE = 'date',
  TEMPLATE = 'template',
  VALUE = 'value',
  PICTURE = 'picture',
  ICON = 'icon'
}

export enum ColumnSortDirection {
  ASC = 'asc',
  DESC = 'desc'
}

//
// == TYPES
//
export type ColumnDefs = (ColumnTemplateRef | BasicColumnDef)[];
export type TableFindDataCb = (params: Params) => Observable<Entity[]>;
export type TableActions = {
  canCreate?: boolean;
  create?: { icon?: string, btnName: string; createCb: () => any; }[];
  readCb?: (entity: Entity) => any;
  canUpdate?: (entity: Entity) => boolean;
  updateCb?: (entity: Entity) => any;
  canDelete?: (entity: Entity) => boolean;
  deleteCb?: (entity: Entity) => Observable<any>;
  custom?: { icon?: string, label?: string, customCb: (entity: Entity) => any, visibleCb?: (entity: Entity) => any }[];
  /**
   * Width with unit (Ex: 100px)
   * Default: 50px
   */
  columnWidth?: string;
};
