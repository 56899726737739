import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { User } from '../../../core/models/user.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../core/api/user.service';
import { ItemDescriptionService } from '../../../core/api/item-description.service';
import { UserHelper } from '../../../core/services/user-helper.service';
import { ScreenEnum } from '../../../core/enum/screen.enum';

@Component({
  selector: 'esomus-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.sass']
})
export class UserViewComponent implements OnInit {
  user: User;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private itemDescriptionService: ItemDescriptionService,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    const userID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    if (!isNaN(userID)) {
      this._getUser(userID);
    }
  }

  private _getUser(userID: number) {
    this.userService.find(userID)
      .subscribe((user: User) => {
        this.user = user;

        this.cd.detectChanges();
      });
  }

  editUser() {
    this.router.navigate(['/users', this.user.id, 'edit']);
  }

  getUserLanguageLabel() {
    return UserHelper.getLanguageLabel(this.user.language);
  }

  get screenID() {
    return ScreenEnum.USER;
  }
}
