import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ActivatedRoute } from '@angular/router';
import { FamilyService } from '../../../core/api/family.service';
import { Family } from '../../../core/models/family.model';
import { RightEnum } from '../../../core/enum/right.enum';
import { AuthService } from '../../../core/api/auth.service';
import { ScreenEnum } from '../../../core/enum/screen.enum';

@Component({
  selector: 'esomus-family-view',
  templateUrl: './family-view.component.html',
  styleUrls: ['./family-view.component.sass'],
})
export class FamilyViewComponent implements OnInit {

  family: Family;

  showBtns: boolean;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private activatedRoute: ActivatedRoute,
    private familyService: FamilyService,
    private cb: ChangeDetectorRef,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    const familyID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    this.familyService.find(familyID)
      .subscribe((family: Family) => {
        this.family = family;

        this.cb.detectChanges();
      });

    this.authService.fetchPageRights({familyId: familyID}).subscribe(() => {
      this.showBtns = this.authService.hasRight(RightEnum.FAMILY_CONFIGURE);
    });
  }

  editFamily() {
    this.routeNameService.goTo('family_id', {id: this.family.id});
  }

  get screenID() {
    return ScreenEnum.FAMILY;
  }
}
