<mat-accordion multi="true">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Accidents / Incidents
      </mat-panel-title>
    </mat-expansion-panel-header>
    <esomus-table [options]="accidentsTable"></esomus-table>
  </mat-expansion-panel>
  <esomus-dashboard-screen-wrapper [screenID]="screenID"></esomus-dashboard-screen-wrapper>
</mat-accordion>
