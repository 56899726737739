import { Inject, Injectable } from '@angular/core';
import { ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MENU_SIDENAV, SidenavMenu } from './menu.provider';
import { SidenavItem } from './sidenav-item/sidenav-item.model';
import { RouteNameService } from '../../../../../core/services/route-name.service';
import { AuthService } from '../../../../../core/api/auth.service';
import { UserLogged } from '../../../../../core/models/user-logged.model';
import { Logger } from '../../../../../core/services/logger.service';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  private sidenavMenu: SidenavMenu;

  private itemsMap: ItemsMap;
  private itemsMapSize: number;

  /**
   * Array of itemID
   */
  private itemsOpened: string[];

  private user: UserLogged;

  private static _sortMenuItem(itemA: SidenavItem, itemB: SidenavItem) {
    // if (itemA.position < itemB.position) {
    //     return -1;
    // }
    // if (itemA.position > itemB.position) {
    //     return 1;
    // }
    return 0;
  }

  private static _addItemOpened(item: SidenavItem, itemsOpened: string[]) {
    item.isOpen = true;
    if (!itemsOpened.includes(item.id)) {
      itemsOpened.push(item.id);
    }
    if (item.parent) {
      SidenavService._addItemOpened(item.parent, itemsOpened);
    }
  }

  constructor(
    @Inject(MENU_SIDENAV)
    private menu: SidenavMenu,
    private router: Router,
    private routeNameService: RouteNameService,
    private authService: AuthService
  ) {
    this.itemsMap = {};
    this.itemsMapSize = 0;
    this.itemsOpened = [];
  }

  getSidenavItems() {
    return Object.values(this.itemsMap);
  }

  generateSidenavMenu(forceGeneration?: boolean) {
    if (this.sidenavMenu && !forceGeneration) {
      return;
    }
    console.log('Generate SIDENAV MENU');

    this.itemsMap = {};
    this.itemsMapSize = 0;
    this.itemsOpened = [];

    this.user = this.authService.getUser();
    Logger.log('USER RIGHTS', this.user.jsonRights);
    this.sidenavMenu = this._createSubItem(this.menu, null);

    this._toggleActiveBasedOnURL(this.router.url);

    this.router.events
      .pipe(filter((e: any) => e instanceof ActivationEnd || e instanceof NavigationEnd))
      .subscribe((e: ActivationEnd | NavigationEnd) => {
        if (e instanceof ActivationEnd) {
          return;
        }

        this._toggleActiveBasedOnURL(e.url);
      });
  }

  private _toggleActiveBasedOnURL(url: string) {
    // Remove previous items opened
    for (const itemID of this.itemsOpened) {
      this.itemsMap[itemID].isOpen = false;
    }
    this.itemsOpened = [];

    for (const key in this.itemsMap) {
      if (!this.itemsMap.hasOwnProperty(key)) {
        continue;
      }
      this.itemsMap[key].isActive = false;
    }

    for (const key in this.itemsMap) {
      if (!this.itemsMap.hasOwnProperty(key)) {
        continue;
      }
      const item = this.itemsMap[key];
      if (item.pathPattern) {
        if (item.pathPattern.test(url)) {
          item.isActive = true;
          SidenavService._addItemOpened(item, this.itemsOpened);
          break;
        }
      }
    }
  }

  getSidenavMenu() {
    return this.sidenavMenu;
  }

  /**
   * Return boolean => open/close
   */
  toggleItem(itemID: string) {
    const itemIndex = this.itemsOpened.findIndex((id: string) => id === itemID);
    // open
    if (itemIndex === -1) {
      this.itemsMap[itemID].isOpen = true;
      this.itemsOpened.push(itemID);
      return true;
    }
    this.itemsMap[itemID].isOpen = false;

    this.itemsOpened.splice(itemIndex, 1);
    return false;
  }

  private _createSubItem(menuItems: SidenavMenu, parent: SidenavItem) {
    let menuTemp = [];
    for (const menuItem of menuItems) {
      if (menuItem.rights) {
        let valid = true;
        for (const right of menuItem.rights) {
          if (this.user.jsonRights.hasOwnProperty(right) && !this.user.jsonRights[right]) {
            valid = false;
          } else {
            valid = true;
          }
        }

        if (!valid) {
          continue;
        }
      }

      let sidenavItem = new SidenavItem(menuItem);

      sidenavItem.parent = parent;
      sidenavItem.id = `${(parent) ? parent.id : 0}/${menuTemp.length}`;

      // if no position
      if (!sidenavItem.position) {
        sidenavItem.position = menuTemp.length;
      }

      // RouteName => Path
      if (sidenavItem.routeName) {
        sidenavItem.route = this.routeNameService.path(sidenavItem.routeName);
      } else if (sidenavItem.path) {
        sidenavItem.route = '/' + sidenavItem.path;
      }

      if (Array.isArray(sidenavItem.subItems)) {
        sidenavItem.subItems = this._createSubItem(sidenavItem.subItems, sidenavItem);
      } else {
        sidenavItem.subItems = [];
      }

      // add in map
      if (!this.itemsMap[sidenavItem.id]) {
        this.itemsMapSize++;
      }
      this.itemsMap[sidenavItem.id] = sidenavItem;
      menuTemp.push(sidenavItem);
    }

    return menuTemp.sort(SidenavService._sortMenuItem);
  }
}

interface ItemsMap {
  [itemID: string]: SidenavItem;
}

interface ItemsRoute {
  [route: string]: string[];
}
