<mat-accordion multi="true" *ngIf="dashboards && dashboards.length">
  <mat-expansion-panel expanded="true" *ngFor="let dashboard of dashboards">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{dashboard.label}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <esomus-dashboard-item [existingDashboard]="dashboard"></esomus-dashboard-item>
  </mat-expansion-panel>
</mat-accordion>
