import { Component, OnInit } from '@angular/core';
import { ColumnSortDirection, TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { ListComponentService } from '../../../core/api/list-component.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { Params } from '@angular/router';
import { Entity } from '../../../core/models/entity.model';
import { ScreenEnum } from '../../../core/enum/screen.enum';

@Component({
  selector: 'esomus-list-component-list',
  templateUrl: './list-component-list.component.html',
  styleUrls: ['./list-component-list.component.sass']
})
export class ListComponentListComponent implements OnInit {

  listTable: TableOptions;

  constructor(
    public listComponentService: ListComponentService,
    private i18n: I18n,
    private routeName: RouteNameService
  ) {
  }

  ngOnInit() {
    this.listTable = {
      columnDefs: [
        {
          prop: 'name',
          name: this.i18n({ value: 'Nom', id: 'name' })
        },
      ],
      findDataCb: (searchData: Params) => this.listComponentService.findAll(),
      actions: {
        create: [
          {
            btnName: this.i18n({ value: 'Ajouter une liste', id: 'btnAddList' }),
            createCb: () => this.routeName.goTo('list')
          }
        ],
        updateCb: (entity: Entity) => {
          this.routeName.goTo('list_id', { id: entity.id });
          // this.userService.find(entity.id).subscribe((user: User) => {
          //   this.formService.updateForm(UsersComponent.userFormID, user);
          // });
        },
        // deleteCb: (entity: Entity) => this.userService.delete(entity.id)
      },
      defaultSortActive: 'name',
      defaultSortDirection: ColumnSortDirection.ASC
    };
  }

  get screenID() {
    return ScreenEnum.LIST_COMPONENT_LIST;
  }
}
