import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Task } from '../../../core/models/task.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute, Params } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { TaskService } from '../../../core/api/task.service';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ToastrService } from '../../../core/services/toastr.service';
import { ColumnType, TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { of } from 'rxjs';
import { FormsMap } from '../../../forms/components/form-component/form-component.component';
import { TaskAssignmentService } from '../../../core/api/task-assignment.service';
import { TaskAssignment } from '../../../core/models/task-assignment.model';
import { ScreenEnum } from '../../../core/enum/screen.enum';

@Component({
  selector: 'esomus-task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.sass']
})
export class TaskViewComponent implements OnInit {
  task: Task;
  forms: FormsMap;

  scopeTable: TableOptions;
  assigmentTable: TableOptions;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private taskService: TaskService,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private taskAssignmentService: TaskAssignmentService
  ) {
  }

  ngOnInit() {
    const taskID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    this._getTask(taskID);
  }

  private _getTask(taskID: number) {
    this.taskService.find(taskID).subscribe((task: Task) => {
      this.task = task;

      if (this.task.form) {
        this.forms = {};
      }

      if (!this.task.taskScopes) {
        this.task.taskScopes = [];
      }

      if (this.task.generated) {
        this.assigmentTable = {
          columnDefs: [
            {
              prop: 'person.fullName',
              name: this.i18n({ value: 'Nom', id: 'name' })
            },
            {
              prop: 'role',
              name: this.i18n({ value: 'Rôle', id: 'role' })
            },
            {
              prop: 'comment',
              name: this.i18n({ value: 'Commentaire', id: 'comment' })
            },
            {
              prop: 'startDate', type: ColumnType.DATE,
              name: this.i18n({ value: 'De', id: 'from' })
            },
            {
              prop: 'endDate', type: ColumnType.DATE,
              name: this.i18n({ value: 'À', id: 'to' })
            },
            {
              prop: 'rdv',
              name: this.i18n({ value: 'RDV', id: 'rdv' })
            },
            {
              prop: 'intern', valueCb: (assignment: TaskAssignment) => assignment.person ? (assignment.person.intern ? 'I' : 'E') : null,
              name: this.i18n({ value: 'IE', id: 'ie' })
            },
          ],
          findDataCb: (searchData: Params) => this.taskAssignmentService.findAllByTask(this.task.id),
          actions: null
        };
      }

      this.scopeTable = {
        columnDefs: [
          {
            prop: 'fullWhere', width: '40%',
            name: this.i18n({ value: 'Où', id: 'where' })
          },
          {
            prop: 'fullWhat', width: '40%',
            name: this.i18n({ value: 'Quoi', id: 'what' })
          },
          {
            prop: 'status', width: '10%',
            name: this.i18n({ value: 'Situation', id: 'situation' })
          },
          {
            prop: 'count', width: '10%',
            name: this.i18n({ value: 'Tot.', id: 'tot.' })
          },
        ],
        findDataCb: (searchData: Params) => of(this.task.taskScopes),
        actions: null
      };

      this.cd.detectChanges();
    });
  }

  planification() {
    this.routeNameService.goTo('task_planification', { id: this.task.id });
  }

  get screenID() {
    return ScreenEnum.TASK;
  }
}
