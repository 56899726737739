<h3 mat-dialog-title>
  <span>Nouveau statut</span>
</h3>
<form *ngIf="entityForm">
  <div mat-dialog-content style="min-width: 640px;">
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px grid">
      <esomus-ng-select
        *ngIf="entityForm.contains('type') && statusOptions"
        [options]="statusOptions"
        [control]="entityForm.get('type')" i18n-label="@@status"
        label="Statut" fxFlex="50" flex="100"></esomus-ng-select>
      <esomus-input [type]="fieldType.DATE" [control]="entityForm.get('date')"
                    i18n-label="@@date" label="Date" flex="100"
                    fxFlex="50"></esomus-input>
    </div>
  </div>
  <div mat-dialog-actions>
    <button type="button" mat-stroked-button color="success"
            i18n="@@saveProfile" (click)="submit()">Sauver le statut
    </button>
    <button type="button" mat-stroked-button i18n="@@cancel" (click)="closeModal()">Annuler
    </button>
  </div>
</form>
