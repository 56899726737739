import { Component, Input, OnInit } from '@angular/core';
import { Accident } from '../../../core/models/accident.model';
import { RouteNameService } from '../../../core/services/route-name.service';
import { AccidentHelper } from '../../../core/services/accident-helper.service';
import { ScreenEnum } from '../../../core/enum/screen.enum';

@Component({
  selector: 'esomus-accident-panel',
  templateUrl: './accident-panel.component.html',
  styleUrls: ['./accident-panel.component.sass']
})
export class AccidentPanelComponent implements OnInit {

  accidentHelper = AccidentHelper;

  @Input() accident: Accident;
  @Input() expanded: boolean;

  constructor(
    private routeNameService: RouteNameService,
  ) { }

  ngOnInit() {
    if (this.expanded === null || this.expanded === undefined) {
      this.expanded = true;
    }
  }

  editAccident() {
    return this.routeNameService.goTo('accident_id', {id: this.accident.id});
  }

  get screenID() {
    return ScreenEnum.ACCIDENT;
  }
}
