import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Item } from '../../../core/models/item.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ActivatedRoute } from '@angular/router';
import { ItemDescriptionService } from '../../../core/api/item-description.service';
import { CompanyService } from '../../../core/api/company.service';
import { ColumnType, TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { Entity } from '../../../core/models/entity.model';
import { ItemDataService } from '../../../core/api/item-data.service';
import { AuthService } from '../../../core/api/auth.service';
import { RightEnum } from '../../../core/enum/right.enum';
import { ScreenEnum } from '../../../core/enum/screen.enum';

@Component({
  selector: 'esomus-company-view',
  templateUrl: './company-view.component.html',
  styleUrls: ['./company-view.component.sass']
})
export class CompanyViewComponent implements OnInit {
  company: Item;
  sitesTable: TableOptions;
  nbSites: number;

  showBtns: boolean;
  showLocationBtns: boolean;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private activatedRoute: ActivatedRoute,
    private companyService: CompanyService,
    private itemDescriptionService: ItemDescriptionService,
    private cd: ChangeDetectorRef,
    private itemDataService: ItemDataService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {
    const companyID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    this.authService.fetchPageRights({companyId: companyID}).subscribe(() => {
      this.showBtns = this.authService.hasPageRight(RightEnum.COMPANY_ADMINISTER);
      this.showLocationBtns = this.authService.hasPageRight(RightEnum.LOCATION_MANAGE);

      this.sitesTable = {
        columnDefs: [
          {
            name: this.i18n({value: 'Photo', id: 'picture'}),
            type: ColumnType.PICTURE, prop: 'picture', width: '10%'
          },
          {
            name: this.i18n({value: 'Nom', id: 'name'}),
            prop: 'label', width: '20%'
          },
          {
            name: this.i18n({value: 'Localité', id: 'locality'}),
            prop: 'address.locality', width: '15%'
          },
          {
            name: this.i18n({value: 'Adresse', id: 'address'}),
            prop: 'address.line1', width: '20%'
          },
          {
            name: this.i18n({value: 'Commentaire', id: 'comment'}),
            prop: 'comment', width: '30%'
          },
        ],
        findDataCb: () => this.companyService.getAllSites(companyID),
        actions: {
          canCreate: this.showBtns,
          create: [
            {
              btnName: this.i18n({value: 'Ajouter un site', id: 'btnAddSite'}),
              createCb: () => this.routeNameService.goTo('site_add', {companyID})
            },
          ],
          readCb: (entity: Entity) => this.routeNameService.goTo('site_view', {id: entity.id}),
          columnWidth: '5%'
        }
      };

      this._getCompany(companyID);
    });
  }

  private _getCompany(id: number) {
    this.companyService.find(id).subscribe((company: Item) => {
      this.company = company;

      this.cd.detectChanges();
    });
  }

  editCompany() {
    this.routeNameService.goTo('company_id', {id: this.company.id});
  }

  updateNbSites(nbItems: number) {
    this.nbSites = nbItems;
  }

  getFamilyURL() {
    return this.routeNameService.path('family_view', {id: this.company.family.id});
  }

  getCategoryURL() {
    return this.routeNameService.path('category_view', {familyID: this.company.family.id, id: this.company.category.id});
  }

  getItemDescriptionURL() {
    return this.routeNameService.path('item_description_view', {id: this.company.itemDescription.id});
  }

  getFetchDataURL(formID: number) {
    return this.itemDataService.getData(this.company.id, formID);
  }

  get screenID() {
    return ScreenEnum.COMPANY;
  }
}
