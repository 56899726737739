import { registerLocaleData } from '@angular/common';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeNlExtra from '@angular/common/locales/extra/nl';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { NgModule, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AccidentPersonsModule } from './accident-persons/accident-persons.module';
import { AccidentsModule } from './accidents/accidents.module';
import { AccidentTypesModule } from './accident-types/accident-types.module';
import { ActionsModule } from './actions/actions.module';
import { ActionsAnomaliesModule } from './actions-anomalies/actions-anomalies.module';
import { AddressesModule } from './addresses/addresses.module';
import { AnomaliesModule } from './anomalies/anomalies.module';
import { AnomalyTypesModule } from './anomaly-types/anomaly-type.module';
import { BuildingsModule } from './buildings/buildings.module';
import { CasesModule } from './cases/cases.module';
import { CategoriesModule } from './categories/categories.module';
import { CompaniesModule } from './companies/companies.module';
import { ContactModule } from './contact/contact.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DocumentsModule } from './documents/documents.module';
import { DocumentTypesModule } from './document-types/document-types.module';
import { EffectsModule } from './effects/effects.module';
import { EquipmentsModule } from './equipments/equipments.module';
import { FamiliesModule } from './families/families.module';
import { FormsModule } from './forms/forms.module';
import { ImpactsModule } from './impacts/impacts.module';
import { ItemModule } from './item/item.module';
import { ItemDescriptionsModule } from './item-descriptions/item-descriptions.module';
import { ItemLocationsModule } from './item-locations/item-locations.module';
import { ListComponentsModule } from './list-components/list-components.module';
import { ListValuesModule } from './list-values/list-values.module';
import { LocalsModule } from './locals/locals.module';
import { ObligationsModule } from './obligations/obligations.module';
import { ObligationTypesModule } from './obligation-types/obligation-types.module';
import { PersonsModule } from './persons/persons.module';
import { ProgrammingsModule } from './programmings/programmings.module';
import { RolesModule } from './roles/roles.module';
import { SearchModule } from './search/search.module';
import { SitesModule } from './sites/sites.module';
import { TasksModule } from './tasks/tasks.module';
import { TaskTypesModule } from './task-types/task-types.module';
import { UsersModule } from './users/users.module';
import { ZonesModule } from './zones/zones.module';
import { ParametersModule } from './parameters/parameters.module';
import { WorkloadModule } from './workload/workload.module';
import { NgJsonEditorModule } from 'ang-jsoneditor';

registerLocaleData(localeFr, 'fr', localeFrExtra);
registerLocaleData(localeNl, 'nl', localeNlExtra);

declare const require;

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule, WorkloadModule,
    AccidentPersonsModule, AccidentsModule, AccidentTypesModule, ActionsModule, ActionsAnomaliesModule, AddressesModule, AnomaliesModule,
    AnomalyTypesModule, BuildingsModule, CasesModule, CategoriesModule, CompaniesModule, ContactModule, DashboardModule, DocumentsModule,
    DocumentTypesModule, EffectsModule, EquipmentsModule, FamiliesModule, FormsModule, ImpactsModule, ItemModule, ItemDescriptionsModule,
    ItemLocationsModule, ListComponentsModule, ListValuesModule, LocalsModule, ObligationsModule, ObligationsModule, ObligationTypesModule,
    PersonsModule, ProgrammingsModule, RolesModule, SearchModule, SitesModule, TasksModule, TaskTypesModule, UsersModule, ZonesModule,
    NgJsonEditorModule
  ],
  providers: [
    {
      provide: TRANSLATIONS,
      useFactory: (locale) => {
        locale = locale || 'fr';
        return require(`raw-loader!../i18n/messages.${locale}.xlf`).default;
      }
    },
    {provide: TRANSLATIONS_FORMAT, useValue: 'xlf'},
    I18n
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
