import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { ItemDescriptionTaskForm } from '../models/item-description-task-form.model';

const crudPath: CRUDPath = {
  many: `/task_forms/`,
  single: (id: number) => `/task_forms/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class ItemDescriptionTaskFormService extends CRUD<ItemDescriptionTaskForm>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  postTaskForm(itemDescriptionTaskForm: ItemDescriptionTaskForm, itemDescriptionID: number): Observable<ItemDescriptionTaskForm> {
    return this.post(itemDescriptionTaskForm, `/item_descriptions/${itemDescriptionID}`);
  }

  deleteTaskForm(itemDescriptionTaskForm: ItemDescriptionTaskForm, itemDescriptionID: number): Observable<void> {
    return this.delete(itemDescriptionTaskForm.id, `/item_descriptions/${itemDescriptionID}`);
  }

  getTaskForm(itemDescriptionID: number, searchData?: Params): Observable<Array<ItemDescriptionTaskForm>> {
    return this.findAll(`/item_descriptions/${itemDescriptionID}`, searchData);
  }
}
