import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormFieldService } from '../../../core/api/form-field.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormField } from '../../../core/models/form-field.model';
import { FieldType as FormFieldType } from '../../../shared/form-builder/form-builder.component';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { Observable, of } from 'rxjs';
import { Entity } from '../../../core/models/entity.model';
import { Data } from '../../../core/models/data.model';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

@Component({
  selector: 'esomus-form-component',
  templateUrl: './form-component.component.html',
  styleUrls: ['./form-component.component.sass']
})
export class FormComponentComponent implements OnInit {

  formFieldType = FormFieldType;

  @Input() formID: number;
  @Input() forms: FormsMap;
  @Input() formsMapping: FormsMappingMap;
  @Input() fetchDataURL: Observable<any>;
  @Input() flex = '50';

  @Input() defaultDataURL: Observable<any>;
  @Input() loadDefault: boolean;

  @Input() disabled: boolean;

  formFullName: string;
  form: FormGroup;

  fields: Array<FormField>;
  emptyOption: EntityOption;
  fieldsOptions: any;

  formReady: boolean;

  constructor(
    private formFieldService: FormFieldService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.formReady = false;

    if (this.disabled === null || this.disabled === undefined) {
      this.disabled = false;
    }
    this.emptyOption = { get: () => of(), propName: 'empty' };
    this.formFullName = `form_${this.formID}`;
    if (!this.forms[this.formFullName]) {
      this.forms[this.formFullName] = this.fb.group({});
    }
    if (this.formsMapping && !this.formsMapping[this.formFullName]) {
      this.formsMapping[this.formFullName] = new Entity();
    }
    this.generateForm(this.formID, this.forms[this.formFullName]);
  }

  getFormFields(formID: number) {
    return this.formFieldService.findByFormID(formID);
  }

  generateForm(formID: number, form: FormGroup) {
    this.getFormFields(formID).subscribe((fields: Array<FormField>) => {
      this.fieldsOptions = [];

      this.form = form;
      this.fields = fields;
      for (let field of this.fields) {
        field.fullName = `form_${field.groupID}_${field.fieldID}`;
        if (this.formsMapping) {
          if (!this.formsMapping[this.formFullName].hasOwnProperty(field.fullName)) {
            this.formsMapping[this.formFullName][field.fullName] = null;
          }
        }

        switch (field.fieldTypeName) {
          case 'radio':
          case 'selectlist':
            this.fieldsOptions[field.fullName] = { get: () => of(field.selectData), propName: 'value' };
            break;
          case 'label':
            continue;
        }

        let controlOptions = null;
        if (this.disabled) {
          controlOptions = { value: '', disabled: true };
        }

        form.addControl(field.fullName, this.fb.control(controlOptions));
      }

      this.formReady = true;
      this.cd.detectChanges();


      if (this.fetchDataURL) {
        forkJoin([
          this.fetchDataURL as Observable<Array<Data>>,
          (this.loadDefault && this.defaultDataURL ? this.defaultDataURL : of([])) as Observable<Array<Data>>
        ]).subscribe(
          (results: Array<Array<Data>>) => {
            const result = [...results[1], ...results[0]];
            for (const data of result) {
              const filterFields = this.fields.filter((formField: FormField) => {
                return formField.fieldID === data.fieldID;
              });

              if (filterFields.length === 1) {
                const field = filterFields[0];

                switch (field.fieldTypeName) {
                  case 'radio':
                  case 'selectlist':
                    this.form.get(field.fullName).setValue(data.value === null ? null : parseInt(data.value, 10));
                    break;
                  case 'label':
                    break;
                  default:
                    this.form.get(field.fullName).setValue(data.value);
                }
              }
            }

            this.cd.detectChanges();          }
        );
        // this.fetchDataURL.subscribe((result: Array<Data>) => {
        //   for (const data of result) {
        //     const filterFields = this.fields.filter((formField: FormField) => {
        //       return formField.fieldID === data.fieldID;
        //     });
        //
        //     if (filterFields.length === 1) {
        //       const field = filterFields[0];
        //
        //       switch (field.fieldTypeName) {
        //         case 'radio':
        //         case 'selectlist':
        //           this.form.get(field.fullName).setValue(data.value === null ? null : parseInt(data.value, 10));
        //           break;
        //         case 'label':
        //           break;
        //         default:
        //           this.form.get(field.fullName).setValue(data.value);
        //       }
        //     }
        //   }
        //
        //   this.cd.detectChanges();
        // });
      }
    });
  }
}

export interface FormsMap {
  [key: string]: FormGroup;
}

export interface FormsMappingMap {
  [key: string]: Entity;
}
