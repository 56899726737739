<mat-progress-spinner color="primary" mode="indeterminate" diameter="36" class="m-center"
                      *ngIf="loading; else content"></mat-progress-spinner>

<ng-template #content>
  <h2 mat-dialog-title>{{data.title}}</h2>
  <mat-dialog-content>
    <esomus-table [options]="tableOptions" [searchReceiver]="search"></esomus-table>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Annuler</button>
    <button mat-button (click)="submit()" color="success">Valider</button>
  </mat-dialog-actions>
</ng-template>

<ng-template #headerTable>
  <esomus-ng-select *ngIf="containerOptions" [options]="containerOptions"
                    (valueChange)="updateItemsToDisplay()"
                    [control]="searchForm.get('container')" i18n-label="@@container"
                    label="Conteneur" fxFlex="100"></esomus-ng-select>
</ng-template>

<ng-template #selectedCellTemplate let-row>
  <mat-checkbox [formControl]="entityForm.get('selected_' + row.id.toString())"></mat-checkbox>
</ng-template>
