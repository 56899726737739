import { Entity } from './entity.model';

export class DocumentType extends Entity {
  label: string;
  group: string;
  duration: number;

  legal: boolean;
  unique: boolean;
  offline: boolean;
  type: number;
  usageType: number;
  active: boolean;
}
