<mat-accordion multi="true">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Résultat de la recherche
      </mat-panel-title>
    </mat-expansion-panel-header>
    <esomus-dashboard-item id="0" [params]="params"></esomus-dashboard-item>
  </mat-expansion-panel>
</mat-accordion>
