import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ItemDescriptionTaskForm } from '../../../core/models/item-description-task-form.model';
import { FormService } from '../../../core/api/form.service';
import { FormBuilder } from '@angular/forms';
import { FormsMap, FormsMappingMap } from '../../../forms/components/form-component/form-component.component';
import { FormDefaultValueService } from '../../../core/api/form-default-value.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';

@Component({
  selector: 'esomus-item-description-task-form-form-dialog',
  templateUrl: './item-description-task-form-form-dialog.component.html',
  styleUrls: ['./item-description-task-form-form-dialog.component.sass']
})
export class ItemDescriptionTaskFormFormDialogComponent implements OnInit {
  forms: FormsMap;
  formsMapping: FormsMappingMap;
  formsValidation: boolean[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { itemDescriptionTaskForm: ItemDescriptionTaskForm },
    private formService: FormService,
    private toastrService: ToastrService,
    private formDefaultValueService: FormDefaultValueService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<ItemDescriptionTaskFormFormDialogComponent>
  ) {
  }

  ngOnInit(): void {
    this.forms = {};
    this.formsMapping = {};
  }

  submit(): void {
    FormHelper.submitDynamicForms(0, this.forms, this.formsMapping, this.data.itemDescriptionTaskForm.id, this.formDefaultValueService, this.cd, null, (data) => {
      this.toastrService.open(ToastrMessageType.UPDATE);
      this.dialogRef.close();
    }, {
      itemDescriptionID: this.data.itemDescriptionTaskForm.itemDescription.id,
      itemDescriptionTaskFormID: this.data.itemDescriptionTaskForm.id
    });
  }

  getFetchDataURL(formID: number) {
    return this.formDefaultValueService.getData(formID, {
      itemDescriptionID: this.data.itemDescriptionTaskForm.itemDescription.id,
      itemDescriptionTaskFormID: this.data.itemDescriptionTaskForm.id
    });
  }
}
