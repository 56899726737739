import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { Item } from '../../../core/models/item.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { ItemDescriptionService } from '../../../core/api/item-description.service';
import { CategoryService } from '../../../core/api/category.service';
import { UserService } from '../../../core/api/user.service';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { EquipmentService } from '../../../core/api/equipment.service';
import { FormsMap, FormsMappingMap } from '../../../forms/components/form-component/form-component.component';
import { ItemDataService } from '../../../core/api/item-data.service';
import { ItemSituationService } from '../../../core/api/item-situation.service';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { PersonService } from '../../../core/api/person.service';
import { ItemHelper } from '../../../core/services/item-helper.service';
import { of } from 'rxjs';

@Component({
  selector: 'esomus-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.sass']
})
export class EquipmentComponent implements OnInit {

  itemSituationOptions: EntityOption;
  assignedToOptions: EntityOption;

  entityForm: FormGroup;
  fieldType = FieldType;
  equipment: Item;

  ownerOptions: EntityOption;
  linkOptions: EntityOption;

  forms: FormsMap;
  formsMapping: FormsMappingMap;
  formsValidation: boolean[];

  nbForms: number;

  equipmentToMove: boolean;
  equipmentToReplace: boolean;

  showOperationButtons: boolean;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private equipmentService: EquipmentService,
    private itemDescriptionService: ItemDescriptionService,
    private categoryService: CategoryService,
    private userService: UserService,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private itemDataService: ItemDataService,
    private itemSituationService: ItemSituationService,
    private personService: PersonService
  ) {
  }

  ngOnInit() {
    this.showOperationButtons = false;

    this.equipmentToReplace = false;
    this.activatedRoute.params.subscribe(() => {
      this.entityForm = this.fb.group({
        description: [],
        comment: [],
        location: [],
        internalCode: [],
        serialNumber: [],
        buildingDate: [],
        buyingDate: [],
        serviceDate: [],
        expirationDate: [],
        lastUseDate: [],
        maintenanceDate: [],
        checkDate: [],
        assignedTo: [],
        link: [],
        omnium: [],
        renting: [],
        owner: [],
        buyCode: [null, [Validators.maxLength(30)]],
        clientCode: [null, [Validators.maxLength(30)]],
        referenceCode: [null, [Validators.maxLength(30)]],
        'picture.upload': [],
      });

      const equipmentID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);
      this.showOperationButtons = !this.activatedRoute.snapshot.queryParamMap.has('post-creation');

      this._getEquipment(equipmentID);
    });
  }

  private _getEquipment(id: number) {
    this.equipmentService.find(id).subscribe((equipment: Item) => {
      this.equipment = equipment;

      this.ownerOptions = {get: () => of(ItemHelper.getOwnerOptions()), propName: 'label'};
      this.linkOptions = { get: () => this.equipmentService.findLink(this.equipment.id), propName: 'label' };

      if (this.equipment.category.form || this.equipment.itemDescription.form) {
        this.forms = {};
        this.formsMapping = {};
      }

      this.entityForm.addControl('situation', this.fb.control(this.equipment.situation.id));
      if (this.equipment.container && this.equipment.container.itemType.value === 6) {
        this.itemSituationOptions = { get: () => this.itemSituationService.findAllForContainerChild(), propName: 'label' };
      }
      this.assignedToOptions = {
        get: () => this.personService.findAll('', {
          companyID: -1,
          type: 'assignedTo',
          equipmentID: this.equipment.id
        }), propName: 'fullName'
      };

      FormHelper.initValues(this.equipment, this.entityForm);

      this.cd.detectChanges();
    });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    this.nbForms = 1;
    this.formsValidation = [];

    for (const key in this.forms) {
      this.nbForms++;
      if (this.forms[key].invalid) {
        return;
      }
    }

    for (const key in this.formsMapping) {
      const entityData = FormHelper.buildEntity(this.formsMapping[key], this.forms[key], {});
      FormHelper.submitForm(
        this.cd,
        this.forms[key],
        this.itemDataService.putData(entityData, this.equipment.id, parseInt(key.substr(5), 10)),
        () => {
          this.formsValidation.push(true);
          this._handleFormsSubmit();
        }
      );
    }

    let entity = FormHelper.buildEntity(this.equipment, this.entityForm, {
      buildingDate: { type: FieldType.DATE },
      buyingDate: { type: FieldType.DATE },
      serviceDate: { type: FieldType.DATE },
      expirationDate: { type: FieldType.DATE },
      lastUseDate: { type: FieldType.DATE },
      maintenanceDate: { type: FieldType.DATE },
      checkDate: { type: FieldType.DATE },
      'picture.upload': { type: FieldType.FILE, multiple: false }
    }) as Item;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      this.equipmentService.put(entity),
      () => {
        this.formsValidation.push(true);
        this._handleFormsSubmit();
      }
    );
  }

  private _handleFormsSubmit() {
    if (this.formsValidation.length === this.nbForms) {
      this.toastrService.open(ToastrMessageType.UPDATE);
      this.entityForm.reset();
      this.routeNameService.goTo('equipment_view', { id: this.equipment.id });
    }
  }

  getFetchDataURL(formID: number) {
    return this.itemDataService.getData(this.equipment.id, formID);
  }

  getDeleteURL() {
    return this.equipmentService.delete(this.equipment.id);
  }

  getSuccessURL() {
    return this.routeNameService.path('equipment_view', { id: this.equipment.id });
  }

  moveEquipment() {
    this.equipmentToMove = false;
    this.cd.detectChanges();
    this.equipmentToMove = true;
    this.cd.detectChanges();
  }

  replaceEquipment() {
    this.equipmentToReplace = false;
    this.cd.detectChanges();
    this.equipmentToReplace = true;
    this.cd.detectChanges();
  }
}
