import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { ColumnSortDirection, ColumnType, TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { User } from '../../../core/models/user.model';
import { Subject } from 'rxjs';
import { ProfileUserService } from '../../../core/api/profile-user.service';
import { Params, Router } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ProfileUser } from '../../../core/models/profile-user.model';
import { MatDialog } from '@angular/material/dialog';
import { ProfileUserDialogComponent } from '../profile-user-dialog/profile-user-dialog.component';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'esomus-profile-user-panel',
  templateUrl: './profile-user-panel.component.html',
  styleUrls: ['./profile-user-panel.component.sass']
})
export class ProfileUserPanelComponent implements OnInit, OnDestroy {

  @Input() expanded = true;

  @Input() user: User;

  profileTable: TableOptions;
  search: EventEmitter<any> = new EventEmitter<any>();
  nbProfiles: number;

  private profileUsers: ProfileUser[];

  private destroyed$: Subject<boolean>;

  constructor(
    private i18n: I18n,
    private profileUserService: ProfileUserService,
    private router: Router,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
  ) {
    this.destroyed$ = new Subject<boolean>();
  }

  ngOnInit(): void {
    this.profileTable = {
      columnDefs: [
        {
          name: this.i18n({ value: 'Ordre', id: 'sequence' }),
          prop: 'sequence', width: '10%'
        },
        {
          name: this.i18n({ value: 'Date de début', id: 'startDate' }),
          prop: 'startDate', width: '15%', type: ColumnType.DATE
        },
        {
          name: this.i18n({ value: 'Date de fin', id: 'endDate' }),
          prop: 'endDate', width: '15%', type: ColumnType.DATE
        },
        {
          name: this.i18n({ value: 'Profile', id: 'profileDescription' }),
          prop: 'profile.description', width: '30%'
        },
        {
          name: this.i18n({ value: 'groupe', id: 'group' }),
          prop: 'profile.group', width: '20%'
        },
      ],
      findDataCb: (searchData: Params) => this.profileUserService.findAllByUser(this.user.id).pipe(tap((result: ProfileUser[]) => {
        this.profileUsers = result;
      })),
      actions: {
        custom: [
          {
            icon: 'arrow_circle_down',
            customCb: (entity: ProfileUser) => this.profileUserService.postUpByUser(this.user.id, entity.id).subscribe(s => this.search.emit()),
            visibleCb: (entity: ProfileUser) => entity.sequence < this.profileUsers.length
          },
          {
            icon: 'arrow_circle_up',
            customCb: (entity: ProfileUser) => this.profileUserService.postDownByUser(this.user.id, entity.id).subscribe(s => this.search.emit()),
            visibleCb: (entity: ProfileUser) => entity.sequence > 1
          }
        ],
        create: [
          {
            btnName: 'Lier un profil',
            createCb: () => this.openDialog()
          },
        ],
        readCb: (entity: ProfileUser) => this.router.navigate(['/profiles', entity.profile.id, 'edit']),
        updateCb: (entity: ProfileUser) => this.openDialog(entity),
        columnWidth: '10%'
      },
      defaultSortActive: 'sequence',
      defaultSortDirection: ColumnSortDirection.ASC
    };
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  updateNbProfiles(nbProfiles: number) {
    this.nbProfiles = nbProfiles;
  }

  openDialog(profileUser?: ProfileUser) {
    const dialogRef = this.dialog.open(ProfileUserDialogComponent, {
      data: { user: this.user, profileUser }, width: '780px'
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.search.emit();
      }
    });
  }
}
