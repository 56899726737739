<mat-spinner *ngIf="isLoading"></mat-spinner>
<div #content class="container" [class.hidden]="isLoading">
  <div fxLayout="row wrap" fxLayoutAlign="start">
    <div fxFlex="100" style="margin-bottom: 15px;" *ngIf="dashboard && dashboard.view">
      <button color="success" mat-flat-button (click)="printDashboard()">
        <mat-icon>print</mat-icon>
        <span>&nbsp;Imprimer</span>
      </button>
    </div>
    <div fxFlex="100" *ngIf="dashboard && dashboard.view" [id]="dashboardID"></div>
    <esomus-table fxFlex="100" *ngIf="options" [options]="options"></esomus-table>
  </div>
</div>

<ng-template #headerTable>
  <ng-container *ngIf="headButtons && headButtons.length > 0">
    <ng-container *ngFor="let btn of headButtons">
      <button color="success" mat-flat-button (click)="headerButtonAction(btn)">
        <mat-icon>{{btn['icons']}}</mat-icon>
        <span *ngIf="btn['label']">&nbsp;{{btn['label']}}</span>
      </button>
    </ng-container>
  </ng-container>
</ng-template>
