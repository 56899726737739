import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ColumnType, TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { RouteNameService } from '../../../core/services/route-name.service';
import { Entity } from '../../../core/models/entity.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { EquipmentService } from '../../../core/api/equipment.service';
import { Params } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Person } from '../../../core/models/person.model';
import { Item } from '../../../core/models/item.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';

@Component({
  selector: 'esomus-equipment-table',
  templateUrl: './equipment-table.component.html',
  styleUrls: ['./equipment-table.component.sass']
})
export class EquipmentTableComponent implements OnInit, OnDestroy {
  @ViewChild('headerTable', { static: true }) headerTable: TemplateRef<any>;
  search: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('familyCategoryCell', { static: true }) familyCategoryCell: TemplateRef<any>;

  @Input() tableReloader: EventEmitter<any>;

  @Output() actionComplete: EventEmitter<any> = new EventEmitter<any>();

  @Input() parent: string;
  @Input() parentID: number;
  @Input() label: string;
  @Input() expanded = true;
  @Input() addParam: object;
  @Input() addLabel: string;
  @Input() showBtns: boolean;

  @Input() person: Person;
  @Input() link: Item;

  equipmentTable: TableOptions;

  nbEquipments: number;

  showModalPerson: boolean;

  searchData: Params;

  private destroyed$: Subject<boolean>;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private equipmentService: EquipmentService,
    private cd: ChangeDetectorRef,
    private toastrService: ToastrService,
  ) {
    this.destroyed$ = new Subject<boolean>();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit() {
    if (!this.addParam) {
      this.addParam = {};
    }

    this.searchData = {
      active: true
    };

    if (this.tableReloader) {
      this.tableReloader.pipe(takeUntil(this.destroyed$)).subscribe(() => this.search.emit());
    }

    this.equipmentTable = {
      columnDefs: [
        {
          name: this.i18n({ value: 'Photo', id: 'picture' }),
          type: ColumnType.PICTURE, prop: 'picture', width: '10%'
        },
        {
          name: this.i18n({ value: 'Nom', id: 'name' }),
          prop: 'label', width: '25%'
        },
        {
          name: this.i18n({ value: 'Modèle', id: 'model' }),
          prop: 'itemDescription.model', width: '15%'
        },
        {
          name: this.i18n({ value: 'Type', id: 'type' }),
          prop: 'itemDescription.type', width: '15%'
        },
      ],
      findDataCb: (searchData: Params) => {
        return this.equipmentService.getAllFromParent(this.parentID, this.parent, this.searchData);
      },
      actions: {
        custom: [],
        canCreate: this.showBtns,
        create: [
          {
            btnName: this.addLabel ? this.addLabel : 'Ajouter un équipement',
            createCb: () => {
              switch (this.parent) {
                case 'person':
                  this.showModalPerson = false;
                  this.cd.detectChanges();
                  this.showModalPerson = true;
                  this.cd.detectChanges();
                  break;
                case 'link':
                  this.routeNameService.goTo('equipment_link', { id: this.parentID }, this.addParam);
                  break;
                default:
                  this.routeNameService.goTo('equipment_add', { parentID: this.parentID }, this.addParam);
                  break;
              }
            }
          },
        ],
        readCb: (entity: Entity) => this.routeNameService.goTo('equipment_view', { id: entity.id }),
        columnWidth: '5%'
      },
      headTemplate: this.headerTable
    };

    if (this.parent === 'site') {
      this.equipmentTable.columnDefs.push(
        {
          name: this.i18n({ value: 'Bâtiment', id: 'building' }),
          prop: 'building.name', width: '20%'
        }
      );
    } else {
      this.equipmentTable.columnDefs.push(
        {
          name: this.i18n({ value: 'Famille / Catégorie', id: 'familyCategory' }), width: '20%',
          type: ColumnType.TEMPLATE, template: this.familyCategoryCell, prop: 'templateFamilyCategory'
        }
      );
    }

    this.equipmentTable.columnDefs.push(
      {
        name: this.i18n({ value: 'En ordre', id: 'inOrder' }),
        prop: 'inOrder', width: '20%', type: ColumnType.CHECKBOX,
      }
    );

    if (this.parent === 'link') {
      this.equipmentTable.actions.custom.push({
        icon: 'cancel', customCb: (item: Entity) => this.equipmentService.unlinkChild(this.parentID, item.id).subscribe(s => {
          this.actionComplete.emit({
            action: 'unlink', parentID: this.parentID, itemID: item.id
          });

          this.toastrService.open(ToastrMessageType.DELETE);

          this.search.emit();
        })
      });
    }
  }

  updateNbEquipments(nbEquipments: number) {
    this.nbEquipments = nbEquipments;
  }

  equipmentChange(event: MatCheckboxChange) {
    this.searchData = {
      ...this.searchData,
      active: event.checked
    };

    this.search.emit();
  }
}
