<form>
  <mat-accordion multi="true" class="mat-edit" *ngIf="action && entityForm">
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Action: {{action.description}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="container">
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div fxLayout="column" fxFlex="60">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <div fxLayout="column" fxFlex="50">
                <div fxLayout="row wrap" fxLayoutAlign="start">
                  <esomus-line-data fxFlex="100" boldValue="Elément lié"
                                    [textValue]="action.item.label"></esomus-line-data>
                  <esomus-line-data fxFlex="100" boldValue="Origine action"
                                    [textValue]="getOrigin()"></esomus-line-data>
                  <esomus-line-data fxFlex="100" boldValue="Type d'action"
                                    [textValue]="action.taskType.label"></esomus-line-data>
                </div>
              </div>
              <div fxLayout="column" fxFlex="50">
                <div fxLayout="row wrap" fxLayoutAlign="start">
                  <esomus-line-data fxFlex="100" boldValue="Date butoir" textType="date"
                                    [textValue]="action.deadlineDate"></esomus-line-data>
                  <esomus-line-data fxFlex="100" boldValue="Type d'action"
                                    [textValue]="getEmergency()"></esomus-line-data>
                </div>
              </div>
              <div fxLayout="column" fxFlex="100">
                <div fxLayout="row wrap" fxLayoutAlign="start">
                  <esomus-line-data fxFlex="100" boldFlex="25" textFlex="75" boldValue="Description"
                                    [textValue]="action.description"></esomus-line-data>
                  <esomus-line-data fxFlex="100" boldFlex="25" textFlex="75" boldValue="Commentaire"
                                    [textValue]="action.comment"></esomus-line-data>
                </div>
              </div>
            </div>
          </div>
          <div fxLayout="column" fxFlex="40">
            <div fxLayout="row wrap" fxLayoutAlign="start" style="border: 1px solid black;">
              <esomus-line-data fxFlex="100" boldValue="Entreprise" boldFlex="25" textFlex="75"
                                [textValue]="action.item.company ? action.item.company.name : null"></esomus-line-data>
              <esomus-line-data fxFlex="100" boldValue="Site" boldFlex="25" textFlex="75"
                                [textValue]="action.item.site ? action.item.site.name : null"></esomus-line-data>
              <esomus-line-data fxFlex="100" boldValue="Bâtiment" boldFlex="25" textFlex="75"
                                [textValue]="action.item.building ? action.item.building.name : null"></esomus-line-data>
              <esomus-line-data fxFlex="100" boldValue="Local" boldFlex="25" textFlex="75"
                                [textValue]="action.item.local ? action.item.local.name : null"></esomus-line-data>
              <esomus-line-data fxFlex="100" boldValue="Dans" boldFlex="25" textFlex="75"
                                [textValue]="action.item.container && [6, 7].indexOf(this.action.item.container.itemType.value) !== -1 ? action.item.container.name : null"></esomus-line-data>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Réalisation
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="container">
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px grid">
          <div fxLayout="column" fxFlex="30">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-img fxFlex="100" [picture]="action.executionPicture"></esomus-img>
              <esomus-input-file accept="image/*"
                                 [control]="entityForm.controls['executionPicture.upload']"
                                 i18n-label="@@picture" label="Photo" fxFlex="100"
                                 flex="100"></esomus-input-file>
            </div>
          </div>
          <div fxLayout="column" fxFlex="70">
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px grid">
              <esomus-input *ngIf="entityForm.contains('executionDate')" [type]="fieldType.DATE"
                            [control]="entityForm.get('executionDate')"
                            i18n-label="@@executionDate" label="Réalisé le" flex="100"
                            fxFlex="50"></esomus-input>
              <esomus-ng-select *ngIf="personOptions" [options]="personOptions"
                                [control]="entityForm.get('person')"
                                i18n-label="@@realizedBy" label="Réalisé par" fxFlex="50"
                                flex="100"></esomus-ng-select>
              <esomus-textarea [control]="entityForm.get('executionComment')" i18n-label="@@comment"
                               label="Commentaire" flex="100" fxFlex="100"></esomus-textarea>
              <esomus-ng-select [options]="statusOptions"
                                [control]="entityForm.get('status')"
                                i18n-label="@@status" label="Statut"
                                [fxFlex]="100" flex="50"></esomus-ng-select>
              <esomus-form-component *ngIf="action.form" [formID]="action.form.id" fxFlex="100"
                                     [forms]="forms" [formsMapping]="formsMapping" flex="100"
                                     [defaultDataURL]="defaultDataURL" [loadDefault]="true"
                                     [fetchDataURL]="fetchDataURL"></esomus-form-component>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="btn-group-right">
    <esomus-back-button></esomus-back-button>
    <esomus-submit (submit)="submit()"></esomus-submit>
  </div>
</form>
