import { Entity } from './entity.model';
import { Profile } from './profile.model';
import { User } from './user.model';

export class ProfileUser extends Entity {
  sequence: number;
  startDate: Date;
  endDate: Date;

  profile: Profile;
  user: User;
}
