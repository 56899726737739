import { Component, Input, OnInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import * as moment from 'moment';

@Component({
  selector: 'esomus-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent implements OnInit {

  @Input() flex = '100';
  @Input() line1: string;
  @Input() date1: string;
  @Input() icon1: string;
  @Input() date2: string;
  @Input() icon2: string;
  @Input() color: string;

  @Input() mainIcon: string;
  @Input() mainIconCb?: () => any;

  @Input() actionAvailable?: () => boolean;
  @Input() actionCb?: () => any;

  @Input() active: boolean;

  constructor(
    private i18n: I18n,
  ) { }

  ngOnInit() {
    if (!this.line1) {
      if (this.active !== undefined && this.active !== null) {
        if (this.active) {
          this.color = 'primary';
          this.line1 = this.i18n({value: 'Actif', id: 'active'});
        } else {
          this.color = 'danger';
          this.line1 = this.i18n({value: 'inactif', id: 'inactive'});
        }
      }
    }
    if (this.date1) {
      this.date1 = moment(this.date1).format('DD/MM/YYYY');
    }
  }

}
