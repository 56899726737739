import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TaskTypeService } from '../../../core/api/task-type.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { ItemDescriptionTaskForm } from '../../../core/models/item-description-task-form.model';
import { ItemDescriptionTaskFormService } from '../../../core/api/item-description-task-form.service';
import { FormService } from '../../../core/api/form.service';

@Component({
  selector: 'esomus-item-description-task-form-dialog',
  templateUrl: './item-description-task-form-dialog.component.html',
  styleUrls: ['./item-description-task-form-dialog.component.sass']
})
export class ItemDescriptionTaskFormDialogComponent implements OnInit {
  loading: boolean;
  entityForm: FormGroup;
  fieldType = FieldType;

  taskTypeOptions: EntityOption;
  formOptions: EntityOption;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { itemDescriptionTaskForm: ItemDescriptionTaskForm },
    private itemDescriptionTaskFormService: ItemDescriptionTaskFormService,
    private taskTypeService: TaskTypeService,
    private formService: FormService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<ItemDescriptionTaskFormDialogComponent>
  ) {
  }

  ngOnInit(): void {
    this.loading = true;

    this.entityForm = this.fb.group({
      form: [null, [Validators.required]],
      taskType: [null, [Validators.required]],
    });

    this.taskTypeOptions = {get: () => this.taskTypeService.findAll(), propName: 'label'};
    this.formOptions = {get: () => this.formService.findAllForTask(), propName: 'name'};

    this.loading = false;
  }

  submit(): void {
    if (this.entityForm.invalid) {
      return;
    }

    const entity = FormHelper.buildEntity(this.data.itemDescriptionTaskForm, this.entityForm, {}) as ItemDescriptionTaskForm;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      this.itemDescriptionTaskFormService.postTaskForm(entity, this.data.itemDescriptionTaskForm.itemDescriptionID),
      (result: ItemDescriptionTaskForm) => {
        this.dialogRef.close(result);
      }
    )
    ;
  }
}
