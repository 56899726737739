<mat-expansion-panel [expanded]="expanded">
  <mat-expansion-panel-header>
    <mat-panel-title>
      Accident
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="container">
    <div fxLayout="row wrap" fxLayoutAlign="start">
      <div fxLayout="column" [fxFlex]="100/3" fxFlex.xs="100">
        <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
          <div fxLayoutAlign="center center">
            <button mat-flat-button (click)="editAccident()" color="success">
              <mat-icon>edit</mat-icon>
              Editer
            </button>
          </div>
          <esomus-dashboard-print-selector [screenID]="screenID"
                                           [itemID]="accident.id"></esomus-dashboard-print-selector>
        </div>
      </div>
      <div fxLayout="column" [fxFlex]="100/3" fxFlex.xs="100">
        <div fxLayoutAlign="center center">
          <esomus-img style="max-width: 50px;" [picture]="accident.icon"></esomus-img>
        </div>
      </div>
      <div fxLayout="column" [fxFlex]="100/3" fxFlex.xs="100">
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <esomus-info-box fxFlex="100" [date1]="accident.date"
                           [line1]="accidentHelper.getAccidentStatusLabel(accident.status)"
                           [color]="accidentHelper.getAccidentStatusColor(accident.status)"
          ></esomus-info-box>
        </div>
      </div>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="0px grid">
      <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <esomus-line-data fxFlex="100" boldValue="N°" textClass="text-danger text-bold"
                            [textValue]="accident.insurerReference"></esomus-line-data>
          <esomus-line-data fxFlex="100" boldValue="Type d'accident"
                            [textValue]="accident.type.description"></esomus-line-data>
          <esomus-line-data fxFlex="100" boldValue="Date - heure" textType="datetime"
                            [textValue]="accident.date"></esomus-line-data>
          <esomus-line-data fxFlex="100" boldValue="Description"
                            [textValue]="accident.description"></esomus-line-data>
          <esomus-line-data fxFlex="100" boldValue="Commentaire"
                            [textValue]="accident.comment"></esomus-line-data>
          <esomus-line-data fxFlex="100" boldValue="Assureur"
                            [textURL]="'person_view'|path:{id: accident.insurer ? accident.insurer.id : null}"
                            [textValue]="accident.insurer ? accident.insurer.fullName : null"></esomus-line-data>
          <esomus-line-data fxFlex="100" boldValue="Sur le chemin du travail"
                            [boolValue]="accident.onTheWayToWork"></esomus-line-data>
        </div>
      </div>
      <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <esomus-line-data fxFlex="100" boldValue="Entreprise"
                            [textURL]="'company_view'|path:{id: accident.location.company ? accident.location.company.id : null}"
                            [textValue]="accident.location.company ? accident.location.company.name : null"></esomus-line-data>
          <esomus-line-data fxFlex="100" boldValue="Site"
                            [textURL]="'site_view'|path:{id: accident.location.site ? accident.location.site.id : null}"
                            [textValue]="accident.location.site ? accident.location.site.name : null"></esomus-line-data>
          <esomus-line-data fxFlex="100" boldValue="Bâtiment"
                            [textURL]="'building_view'|path:{id: accident.location.building ? accident.location.building.id : null}"
                            [textValue]="accident.location.building ? accident.location.building.name : null"></esomus-line-data>
          <esomus-line-data fxFlex="100" boldValue="Local"
                            [textURL]="'local_view'|path:{id: accident.location.local ? accident.location.local.id : null}"
                            [textValue]="accident.location.local ? accident.location.local.name : null"></esomus-line-data>
          <esomus-line-data fxFlex="100" boldValue="Localisation"
                            [textValue]="accident.placeDescription"></esomus-line-data>
          <esomus-line-data fxFlex="100" boldValue="En charge"
                            [textURL]="'person_view'|path:{id: accident.manager ? accident.manager.id : null}"
                            [textValue]="accident.manager ? accident.manager.fullName : null"></esomus-line-data>
          <!--          <esomus-info-box fxFlex="100" [line1]="getStatus()" [date1]="accident.deadlineDate" color="warning"></esomus-info-box>-->
        </div>
      </div>
    </div>
  </div>
</mat-expansion-panel>
