import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Params } from '@angular/router';
import { ProfileUser } from '../models/profile-user.model';

const crudPath: CRUDPath = {
  many: `/profiles/`,
  single: (id: number) => `/profiles/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class ProfileUserService extends CRUD<ProfileUser> {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  findOneByUser(userID: number, profileUserID: number) {
    return this.http.get(`${environment.api.url}/users/${userID}/profiles/${profileUserID}`);
  }

  findAllByUser(userID: number, params?: Params): Observable<ProfileUser[]> {
    return this.http.get(`${environment.api.url}/users/${userID}/profiles`, params);
  }

  postByUser(userID: number, data: any) {
    return this.post(data, `/users/${userID}`);
  }

  putByUser(userID: number, data: any) {
    return this.put(data, `/users/${userID}`);
  }

  postUpByUser(userID: number, profileUserID: number) {
    return this.http.post<ProfileUser>(`${environment.api.url}/users/${userID}/profiles/${profileUserID}/up`, null);
  }

  postDownByUser(userID: number, profileUserID: number) {
    return this.http.post<ProfileUser>(`${environment.api.url}/users/${userID}/profiles/${profileUserID}/down`, null);
  }
}
