import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Task } from '../../../core/models/task.model';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { TaskStatusService } from '../../../core/api/task-status.service';
import { TaskTypeStatusService } from '../../../core/api/task-type-status.service';
import { TaskStatus } from '../../../core/models/task-status.model';

@Component({
  selector: 'esomus-task-status-dialog',
  templateUrl: './task-status-dialog.component.html',
  styleUrls: ['./task-status-dialog.component.sass']
})
export class TaskStatusDialogComponent implements OnInit {
  entityForm: FormGroup;
  fieldType = FieldType;

  statusOptions: EntityOption;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      task: Task,
    },
    private taskTypeStatusService: TaskTypeStatusService,
    private taskStatusService: TaskStatusService,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<TaskStatusDialogComponent>
  ) {
  }

  ngOnInit(): void {
    this.statusOptions = { get: () => this.taskTypeStatusService.findAll(), propName: 'label', autoSelect: true };

    this.entityForm = this.fb.group({
      date: [null, [Validators.required]],
      type: [null, [Validators.required]],
    });
  }

  closeModal() {
    this.dialogRef.close(false);
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    const item = new TaskStatus();
    item.task = this.data.task;

    let entity = FormHelper.buildEntity(item, this.entityForm, {}) as TaskStatus;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      this.taskStatusService.postFromParent(this.data.task, entity),
      (result: TaskStatus) => {
        this.toastrService.open(ToastrMessageType.CREATE);

        this.dialogRef.close(result);
      }
    );
  }
}
