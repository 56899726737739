<ng-container *ngIf="dashboards && dashboards.length">
  <mat-expansion-panel *ngFor="let dashboard of dashboards">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{dashboard.label}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <esomus-dashboard-item [id]="dashboard.id" [screenID]="screenID" [itemID]="itemID" type="dashboard"></esomus-dashboard-item>
    </ng-template>
  </mat-expansion-panel>
</ng-container>
