import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { Form } from '../models/form.model';
import { FormTypeEnum } from '../enum/form-type.enum';

const crudPath: CRUDPath = {
  many: `/forms/`,
  single: (id: number) => `/forms/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class FormService extends CRUD<Form> {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  public findAllForDescription() {
    return this.findAll('', { formType: FormTypeEnum.FORM_DESCRIPTION });
  }

  public findAllForLocation() {
    return this.findAll('', { formType: FormTypeEnum.FORM_LOCATION });
  }

  public findAllForAnomaly() {
    return this.findAll('', { formType: FormTypeEnum.FORM_ANOMALY });
  }

  public findAllForTask() {
    return this.findAll('', { formType: FormTypeEnum.FORM_TASK });
  }

  public findAllForPerson() {
    return this.findAll('', { formType: FormTypeEnum.FORM_PERSON });
  }

  public findAllForAccident() {
    return this.findAll('', { formType: FormTypeEnum.FORM_ACCIDENT });
  }
}
