import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldType, FieldType as FormFieldType } from '../../../shared/form-builder/form-builder.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { Person } from '../../../core/models/person.model';
import { PersonService } from '../../../core/api/person.service';
import { CompanyService } from '../../../core/api/company.service';
import { SiteService } from '../../../core/api/site.service';
import { BuildingService } from '../../../core/api/building.service';
import { LocalService } from '../../../core/api/local.service';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { ItemLocation } from '../../../core/models/item-location.model';
import { ObligationService } from '../../../core/api/obligation.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormService } from '../../../core/api/form.service';
import { of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormsMap, FormsMappingMap } from '../../../forms/components/form-component/form-component.component';
import { PersonDataService } from '../../../core/api/person-data.service';

@Component({
  selector: 'esomus-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.sass']
})
export class PersonComponent implements OnInit, OnDestroy {
  entityForm: FormGroup;
  fieldType = FormFieldType;
  person: Person;

  isPerson: boolean;
  isCompany: boolean;
  isGroup: boolean;
  isIntern: boolean;

  formID: number;
  nbForms: number;

  formOptions: EntityOption;
  companyOptions: EntityOption;
  siteOptions: EntityOption;
  buildingOptions: EntityOption;
  localOptions: EntityOption;
  obligationOptions: EntityOption;
  languageOptions: EntityOption;

  forms: FormsMap;
  formsMapping: FormsMappingMap;
  formsValidation: boolean[];

  private destroyed$: Subject<boolean>;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private personService: PersonService,
    private companyService: CompanyService,
    private siteService: SiteService,
    private buildingService: BuildingService,
    private localService: LocalService,
    private obligationService: ObligationService,
    private formService: FormService,
    private personDataService: PersonDataService,
  ) {
    this.destroyed$ = new Subject<boolean>();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit() {
    this.activatedRoute.params.pipe(takeUntil(this.destroyed$))
      .subscribe((routeParams) => {
        this.entityForm = this.fb.group({
          lastName: [null, [Validators.required]],
          comment: [null],
          language: [null],
          'picture.upload': [null],
        });

        this.languageOptions = {
          propName: 'label', autoSelect: false, get: () => of([
            { id: 'fr', label: 'Français' },
            { id: 'en', label: 'Anglais' },
            { id: 'de', label: 'Allemand' },
            { id: 'nl', label: 'Néerlendais' },
          ])
        };

        const personID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);
        if (!isNaN(personID)) {
          this._getPerson(personID);
        } else {
          this.person = new Person();
          this.isPerson = this.activatedRoute.snapshot.queryParamMap.get('personType') === 'person';
          this.isCompany = this.activatedRoute.snapshot.queryParamMap.get('personType') === 'company';
          this.isGroup = this.activatedRoute.snapshot.queryParamMap.get('personType') === 'group';
          this.isIntern = false;

          let type = null;
          switch (true) {
            case this.isPerson:
              type = 0;
              break;
            case this.isCompany:
              type = 1;
              break;
            case this.isGroup:
              type = 2;
              break;
          }
          this.entityForm.addControl('type', this.fb.control(type));

          this._updateFormForPerson();
        }
      });
  }

  private _updateFormForPerson() {
    if (this.isGroup) {
      return;
    }

    this.entityForm.addControl('internalNumber1', this.fb.control(null));
    this.entityForm.addControl('internalNumber2', this.fb.control(null));
    this.entityForm.addControl('active', this.fb.control(null));
    this.entityForm.addControl('title', this.fb.control(null, [Validators.required]));
    this.entityForm.addControl('initial', this.fb.control(null));
    this.entityForm.addControl('phone', this.fb.control(null));
    this.entityForm.addControl('fax', this.fb.control(null));
    this.entityForm.addControl('gsm', this.fb.control(null));
    this.entityForm.addControl('mail', this.fb.control(null));

    if (!this.person.form) {
      this.formOptions = { get: () => this.formService.findAllForPerson(), propName: 'name' };
      this.entityForm.addControl('form', this.fb.control(null));
    } else {
      this.forms = {};
      this.formsMapping = {};
    }

    if (this.isCompany) {
      this.entityForm.addControl('intern', this.fb.control({ value: false, disabled: true }));
      return;
    }

    this.entityForm.addControl('intern', this.fb.control(this.person.intern));
    this.entityForm.addControl('firstName', this.fb.control(this.person.firstName, [Validators.required]));

    if (!this.person.personLocation) {
      this.person.personLocation = new ItemLocation();
    }

    this.companyOptions = { get: () => this.companyService.findAll('', { light: true }), propName: 'label', autoSelect: true };
    let companyID = this.person.personLocation.company ? this.person.personLocation.company.id : null;
    this.entityForm.addControl('personLocation.company', this.fb.control(companyID));

    if (!this.person.obligations) {
      this.person.obligations = [];
    }
    this.obligationOptions = { get: () => this.obligationService.findAll(), propName: 'label' };
    this.entityForm.addControl('obligations', this.fb.control(this.person.obligations));

    if (companyID) {
      this.updateSite();
    }

    this.cd.detectChanges();
  }

  updateSite() {
    let companyValue = this.entityForm.controls['personLocation.company'].value;

    this.siteOptions = null;
    this.entityForm.removeControl('personLocation.site');
    this.cd.detectChanges();

    if (companyValue) {
      this.siteOptions = { get: () => this.companyService.getAllSites(companyValue, { light: true }), propName: 'label' };
      let siteID = this.person.personLocation.site ? this.person.personLocation.site.id : null;
      this.entityForm.addControl('personLocation.site', this.fb.control(siteID));

      if (siteID) {
        this.updateBuilding();
      }
    }

    this.cd.detectChanges();
  }

  updateBuilding() {
    let siteValue = this.entityForm.controls['personLocation.site'].value;

    this.buildingOptions = null;
    this.entityForm.removeControl('personLocation.building');
    this.cd.detectChanges();

    if (siteValue) {
      this.buildingOptions = { get: () => this.siteService.getAllBuildings(siteValue, { light: true }), propName: 'label' };
      let buildingID = this.person.personLocation.building ? this.person.personLocation.building.id : null;
      this.entityForm.addControl('personLocation.building', this.fb.control(buildingID));

      if (buildingID) {
        this.updateLocal();
      }
    }

    this.cd.detectChanges();
  }

  updateLocal() {
    let buildingValue = this.entityForm.controls['personLocation.building'].value;

    this.localOptions = null;
    this.entityForm.removeControl('personLocation.local');
    this.cd.detectChanges();

    if (buildingValue) {
      this.localOptions = { get: () => this.buildingService.getAllLocals(buildingValue, { light: true }), propName: 'label' };
      let localID = this.person.personLocation.local ? this.person.personLocation.local.id : null;
      this.entityForm.addControl('personLocation.local', this.fb.control(localID));
    }

    this.cd.detectChanges();
  }

  private _getPerson(personID: number) {
    this.personService.find(personID)
      .subscribe((person: Person) => {
        this.person = person;
        if (this.person.form) {
          this.formID = this.person.form.id;
        }

        this.isPerson = this.person.type === 0;
        this.isCompany = this.person.type === 1;
        this.isGroup = this.person.type === 2;
        this.isIntern = this.isPerson && this.person.intern;

        this._updateFormForPerson();

        FormHelper.initValues(this.person, this.entityForm);

        this.cd.detectChanges();
      });
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    let entity = FormHelper.buildEntity(this.person, this.entityForm, {
      'picture.upload': { type: FieldType.FILE, multiple: false },
      obligations: { type: FieldType.SELECT, multiple: true }
    }) as Person;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      ((entity.id) ? this.personService.put(entity) : this.personService.post(entity)),
      (result: Person) => {
        FormHelper.submitDynamicForms(0, this.forms, this.formsMapping, result.id, this.personDataService, this.cd, {
          entity,
          result
        }, (data) => this._handleFormsSubmit(data));
      }
    );
  }

  private _handleFormsSubmit(data) {
    this.entityForm.reset();
    this.toastrService.open((data.entity.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);
    this.routeNameService.goTo('person_view', { id: data.result.id });
  }

  getPersonDeleteURL() {
    return this.personService.delete(this.person.id);
  }

  getPersonSuccessURL() {
    return this.routeNameService.path('person_view', { id: this.person.id });
  }

  getPersonEnableURL() {
    return this.personService.enable(this.person.id);
  }

  updateIntern($event: MatCheckboxChange) {
    this.isIntern = $event.checked;
  }

  updateForm() {
    this.formID = this.entityForm.get('form').value;

    this.forms = {};
    this.formsMapping = {};

    this.cd.detectChanges();
  }

  getFetchDataURL(formID: number) {
    return this.personDataService.getData(this.person.id, formID);
  }
}
