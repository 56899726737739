<mat-accordion multi="true" *ngIf="company" class="mat-edit">
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Entreprise: Edition
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <form>
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px">
          <div fxLayout="column" fxFlex="30" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-img fxFlex="100" [picture]="company.picture"></esomus-img>
              <esomus-input-file accept="image/*" [control]="entityForm.controls['picture.upload']"
                                 i18n-label="@@picture" label="Photo" fxFlex="100"
                                 flex="100"></esomus-input-file>
              <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('code')"
                            i18n-label="@@esomusCode" label="Code esomus" flex="100"
                            fxFlex="100"></esomus-input>
            </div>
          </div>
          <div fxLayout="column" fxFlex>
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px grid">
              <esomus-line-data fxFlex="100" flex="50" boldValue="Famille"
                                [textValue]="company.family.label"></esomus-line-data>
              <esomus-line-data fxFlex="100" flex="50" boldValue="Catégorie"
                                [textValue]="company.category.label"></esomus-line-data>
              <esomus-line-data fxFlex="100" flex="50" boldValue="Fiche"
                                [textValue]="company.itemDescription.label"></esomus-line-data>
              <esomus-input [autofocus]="true" [type]="fieldType.TEXT" [control]="entityForm.get('description')"
                            i18n-label="@@name" label="Nom" flex="50"
                            fxFlex="100"></esomus-input>
              <esomus-input [type]="fieldType.TEXT" [control]="entityForm.get('internalCode')"
                            i18n-label="@@vatNumber" label="TVA" flex="50"
                            fxFlex="100"></esomus-input>
              <esomus-input [type]="fieldType.DATE" [control]="entityForm.get('serviceDate')"
                            i18n-label="@@creationDate" label="Date création" flex="100"
                            [fxFlex]="50"></esomus-input>
              <esomus-input [type]="fieldType.DATE" [control]="entityForm.get('buyingDate')"
                            i18n-label="@@buyingDate" label="Date d'achat" flex="100"
                            [fxFlex]="50"></esomus-input>
              <mat-checkbox [formControl]="entityForm.get('omnium')"
                            i18n-label="@@omnium" label="Omnium" [fxFlex]="50">
                            Omnium
              </mat-checkbox>
              <mat-checkbox [formControl]="entityForm.get('renting')"
                            i18n-label="@@renting" label="Location" [fxFlex]="50">
                            Location
              </mat-checkbox>
              <esomus-input [control]="entityForm.get('buyCode')"
                            i18n-label="@@buyCode" label="Code d'achat" flex="100"
                            [fxFlex]="50"></esomus-input>
              <esomus-input [control]="entityForm.get('clientCode')"
                            i18n-label="@@clientCode" label="Code client" flex="100"
                            [fxFlex]="50"></esomus-input>
              <esomus-input [control]="entityForm.get('referenceCode')"
                            i18n-label="@@referenceCode" label="Référence" flex="100"
                            [fxFlex]="50"></esomus-input>
              <esomus-textarea [control]="entityForm.get('comment')" i18n-label="@@comment"
                               label="Commentaire" flex="100" fxFlex="100"></esomus-textarea>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel *ngIf="(company.category.form || company.itemDescription.form) && forms"
                       expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Information complémentaire
      </mat-panel-title>
    </mat-expansion-panel-header>
    <esomus-form-component *ngIf="company.category.form" [formID]="company.category.form.id"
                           [forms]="forms" [formsMapping]="formsMapping"
                           [fetchDataURL]="getFetchDataURL(company.category.form.id)"></esomus-form-component>
    <esomus-form-component *ngIf="company.itemDescription.form"
                           [formID]="company.itemDescription.form.id" [forms]="forms"
                           [formsMapping]="formsMapping"
                           [fetchDataURL]="getFetchDataURL(company.itemDescription.form.id)"></esomus-form-component>
  </mat-expansion-panel>
  <div class="btn-group-right">
    <esomus-back-button></esomus-back-button>
    <esomus-submit (submit)="submit()"></esomus-submit>
  </div>
</mat-accordion>
