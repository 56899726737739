import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonComponent } from './pages/person/person.component';
import { PersonViewComponent } from './pages/person-view/person-view.component';
import { PersonListComponent } from './pages/person-list/person-list.component';
import { SharedModule } from '../shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { PersonInputComponent } from './components/person-input/person-input.component';
import { AddressesModule } from '../addresses/addresses.module';
import { EquipmentsModule } from '../equipments/equipments.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { FormsModule } from '../forms/forms.module';

@NgModule({
  declarations: [PersonComponent, PersonViewComponent, PersonListComponent, PersonInputComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatDialogModule,
    AddressesModule,
    EquipmentsModule,
    DashboardModule,
    FormsModule
  ],
})
export class PersonsModule { }
