<h3 mat-dialog-title>
  <span>Profil utilisateur : {{ data.profileUser?.id ? 'Edition' : 'Création' }}</span>
</h3>
<form *ngIf="entityForm">
  <div mat-dialog-content style="min-width: 640px;">
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px grid">
      <esomus-ng-select
        *ngIf="!data.profileUser?.id && entityForm.contains('profile') && profileOptions"
        [options]="profileOptions"
        [control]="entityForm.get('profile')" i18n-label="@@profileDescription"
        label="Profile" fxFlex="100" flex="100"></esomus-ng-select>
      <esomus-line-data *ngIf="data.profileUser?.id" fxFlex="100" boldValue="Profile"
                        [textValue]="data.profileUser.profile.description + ' - ' + data.profileUser.profile.group">
      </esomus-line-data>
      <esomus-input [type]="fieldType.DATE" [control]="entityForm.get('startDate')"
                    i18n-label="@@startDate" label="Date de début" flex="100"
                    fxFlex="50"></esomus-input>
      <esomus-input [type]="fieldType.DATE" [control]="entityForm.get('endDate')"
                    i18n-label="@@endDate" label="Date de fin" flex="100"
                    fxFlex="50"></esomus-input>
    </div>
  </div>
  <div mat-dialog-actions>
    <button type="button" mat-stroked-button color="success"
            i18n="@@saveProfile" (click)="submit()">Sauver le profil
    </button>
    <button type="button" mat-stroked-button i18n="@@cancel" (click)="closeModal()">Annuler
    </button>
  </div>
</form>
