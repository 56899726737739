import { Entity } from './entity.model';
import { Item } from './item.model';
import { AnomalyType } from './anomaly-type.model';
import { Form } from './form.model';
import { Picture } from './picture.model';
import { Person } from './person.model';
import { ItemDescription } from './item-description.model';
import { TaskType } from './task-type.model';

export class ItemDescriptionTaskForm extends Entity {
  taskType: TaskType;
  form: Form;
  itemDescription: ItemDescription;
  itemDescriptionID: number;
}
