import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '../../../core/models/user.model';
import { ProfileUser } from '../../../core/models/profile-user.model';
import { ProfileService } from '../../../core/api/profile.service';
import { FormHelper } from '../../../core/services/form-helper.service';
import { ToastrMessageType, ToastrService } from '../../../core/services/toastr.service';
import { ProfileUserService } from '../../../core/api/profile-user.service';

@Component({
  selector: 'esomus-profile-user-dialog',
  templateUrl: './profile-user-dialog.component.html',
  styleUrls: ['./profile-user-dialog.component.sass']
})
export class ProfileUserDialogComponent implements OnInit {
  entityForm: FormGroup;
  fieldType = FieldType;

  profileOptions: EntityOption;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      user: User,
      profileUser?: ProfileUser
    },
    private profileService: ProfileService,
    private profileUserService: ProfileUserService,
    private toastrService: ToastrService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<ProfileUserDialogComponent>
  ) {
  }

  ngOnInit(): void {
    if (this.data.profileUser) {
      this.entityForm = this.fb.group({
        startDate: [this.data.profileUser.startDate, [Validators.required]],
        endDate: [this.data.profileUser.endDate, [Validators.required]],
      });
    } else {
      this.profileOptions = { get: () => this.profileService.findAll(), propName: 'description', autoSelect: true };

      this.entityForm = this.fb.group({
        startDate: [null, [Validators.required]],
        endDate: [null, [Validators.required]],
        profile: [null, [Validators.required]],
      });
    }
  }

  closeModal() {
    this.dialogRef.close(false);
  }

  submit() {
    if (this.entityForm.invalid) {
      return;
    }

    const item = this.data.profileUser ? this.data.profileUser : new ProfileUser();
    item.user = this.data.user;

    let entity = FormHelper.buildEntity(item, this.entityForm, {}) as ProfileUser;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      ((entity.id) ? this.profileUserService.putByUser(this.data.user.id, entity) : this.profileUserService.postByUser(this.data.user.id, entity)),
      (result: ProfileUser) => {
        this.toastrService.open((entity.id) ? ToastrMessageType.UPDATE : ToastrMessageType.CREATE);

        this.dialogRef.close(true);
      }
    );
  }
}
