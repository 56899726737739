<mat-accordion multi="true">
  <mat-expansion-panel expanded="true">
    <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
      <esomus-dashboard-print-selector [screenID]="screenID"></esomus-dashboard-print-selector>
    </div>
    <esomus-table [options]="taskTypeTable" [searchReceiver]="search"></esomus-table>
  </mat-expansion-panel>
  <esomus-dashboard-screen-wrapper [screenID]="screenID"></esomus-dashboard-screen-wrapper>
</mat-accordion>

<ng-template #headerTable>
  <mat-checkbox (change)="anomalyChange($event)">Voir anomalie</mat-checkbox>
</ng-template>
