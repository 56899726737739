import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { UserListComponent } from './pages/user-list/user-list.component';
import { UserComponent } from './pages/user/user.component';
import { UserViewComponent } from './pages/user-view/user-view.component';

const routes: Routes = [
  {
    path: '',
    component: UserListComponent,
    data: { routeName: 'users' }
  },
  {
    path: 'add',
    component: UserComponent,
    data: { routeName: 'user_add' }
  },
  {
    path: ':id/edit',
    component: UserComponent,
    data: { routeName: 'user_id' }
  },
  {
    path: ':id',
    component: UserViewComponent,
    data: { routeName: 'user_view' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule {
}
