<div class="info-box-container" [fxFlex]="flex">
  <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="5px grid">
    <div *ngIf="mainIcon" fxFlex="20">
      <mat-icon (click)="mainIconCb()" [class.text-pointer]="mainIconCb" class="main-icon icon-{{color}}">{{mainIcon}}</mat-icon>
    </div>
    <div [fxFlex]="mainIcon ? 80 : 100" class="info-box info-box-{{color}} ">
      <p class="info-box-text" [fxFlex]>
        <ng-container *ngIf="actionAvailable && actionCb && actionAvailable(); else noLink">
          <a class="text-underline text-pointer" (click)="actionCb()">{{line1}}</a>
        </ng-container>
        <ng-template #noLink>
          <span>{{line1}}</span>
        </ng-template>
        <ng-container *ngIf="date1">
          <br>{{date1}}
        </ng-container>
      </p>
    </div>
  </div>
</div>
