import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ActivatedRoute, Params } from '@angular/router';
import { ItemDescriptionService } from '../../../core/api/item-description.service';
import { ItemDescription } from '../../../core/models/item-description.model';
import { ItemDescriptionDataService } from '../../../core/api/item-description-data.service';
import { AuthService } from '../../../core/api/auth.service';
import { RightEnum } from '../../../core/enum/right.enum';
import { TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { TaskWorkloadService } from '../../../core/api/task-workload.service';
import { MatDialog } from '@angular/material/dialog';
import { TaskWorkload } from '../../../core/models/task-workload.model';
import { TaskWorkloadDialogComponent } from '../../../workload/components/task-workload-dialog/task-workload-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ScreenEnum } from '../../../core/enum/screen.enum';

@Component({
  selector: 'esomus-item-description-view',
  templateUrl: './item-description-view.component.html',
  styleUrls: ['./item-description-view.component.sass']
})
export class ItemDescriptionViewComponent implements OnInit, OnDestroy {
  nbWorkloads: number;
  workloadsTable: TableOptions;

  itemDescription: ItemDescription;
  isEquipment: boolean;
  isBuilding: boolean;

  showBtns: boolean;

  workloadTableEmitter: EventEmitter<any> = new EventEmitter<any>();

  private destroyed$: Subject<boolean>;

  constructor(
    private i18n: I18n,
    private routeNameService: RouteNameService,
    private activatedRoute: ActivatedRoute,
    private itemDescriptionService: ItemDescriptionService,
    private itemDescriptionDataService: ItemDescriptionDataService,
    private taskWorkloadService: TaskWorkloadService,
    private cd: ChangeDetectorRef,
    private authService: AuthService,
    private dialog: MatDialog
  ) {
    this.destroyed$ = new Subject<boolean>();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit() {
    const itemDescriptionID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

    this._getItemDescription(itemDescriptionID);

    this.workloadsTable = {
      columnDefs: [
        {
          prop: 'weight', width: '45%',
          name: this.i18n({ value: 'Poids', id: 'taskWorkloadWeight' })
        },
        {
          prop: 'taskType.label', width: '45%',
          name: this.i18n({ value: 'Type de tâche', id: 'taskType' })
        },
      ],
      findDataCb: (searchData: Params) => {
        return this.taskWorkloadService.getItemDescription(itemDescriptionID);
      },
      actions: {
        canCreate: this.showBtns,
        create: [
          {
            btnName: this.i18n({ value: 'Ajouter une charge de travail', id: 'btnAddTaskWorkload' }),
            createCb: () => {
              const taskWorkload = new TaskWorkload();
              taskWorkload.itemDescriptionID = this.itemDescription.id;
              taskWorkload.itemDescription = this.itemDescription;
              this.openTaskWorkloadDialog(taskWorkload);
            }
          },
        ],
        updateCb: (item: TaskWorkload) => {
            item.itemDescriptionID = this.itemDescription.id;
            this.openTaskWorkloadDialog(item);
        },
        canDelete: () => this.showBtns,
        deleteCb: (item: TaskWorkload) => this.taskWorkloadService.deletItemDescription(item, this.itemDescription.id),
        columnWidth: '10%'
      }
    };
  }

  public openTaskWorkloadDialog(taskWorkload: TaskWorkload) {
    const dialogRef = this.dialog.open(TaskWorkloadDialogComponent, {
      width: '560px',
      data: { taskWorkload }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$))
      .subscribe(s => {
        this.workloadTableEmitter.emit();
      });
  }

  updateNbWorkloads(nbWorkloads: number) {
    this.nbWorkloads = nbWorkloads;
  }

  private _getItemDescription(id: number) {
    this.itemDescriptionService.find(id)
      .subscribe((itemDescription: ItemDescription) => {
        this.authService.fetchPageRights({
          familyId: itemDescription.family.id,
          categoryId: itemDescription.category.id,
          itemDescriptionId: itemDescription.id
        }).subscribe(() => {
          this.showBtns = this.authService.hasPageRight(RightEnum.EQUIPMENT_CONFIGURE);

          this.itemDescription = itemDescription;
          this.isEquipment = [6, 7, 8].indexOf(this.itemDescription.itemType.value) !== -1;
          this.isBuilding = this.itemDescription.itemType.value === 3;

          this.cd.detectChanges();
        });
      });
  }

  getFamilyURL() {
    return this.routeNameService.path('family_view', { id: this.itemDescription.family.id });
  }

  getCategoryURL() {
    return this.routeNameService.path('category_view', { familyID: this.itemDescription.family.id, id: this.itemDescription.category.id });
  }

  editItemDescription() {
    return this.routeNameService.goTo('item_description_id', { id: this.itemDescription.id });
  }

  getFetchDataURL(formID: number) {
    return this.itemDescriptionDataService.getData(this.itemDescription.id, formID);
  }

  get screenID() {
    switch (this.itemDescription.itemType.value) {
      case 1:
        return ScreenEnum.ITEM_DESCRIPTION_COMPANY;
      case 2:
        return ScreenEnum.ITEM_DESCRIPTION_SITE;
      case 3:
        return ScreenEnum.ITEM_DESCRIPTION_BUILDING;
      case 4:
        return ScreenEnum.ITEM_DESCRIPTION_LOCAL;
      case 6:
        return ScreenEnum.ITEM_DESCRIPTION_CONTAINER;
      case 7:
        return ScreenEnum.ITEM_DESCRIPTION_VEHICLE;
      case 8:
        return ScreenEnum.ITEM_DESCRIPTION_EQUIPMENT;
    }
  }
}
