import { Entity } from './entity.model';
import { Family } from './family.model';
import { Category } from './category.model';
import { ItemType } from './item-type.model';
import { Picture } from './picture.model';
import { EsomusCode } from './esomus-code.model';
import { Form } from './form.model';
import { Obligation } from './obligation.model';

export class ItemDescription extends Entity {
  label: string;
  comment: string;
  model: string;
  type: string;
  duration: number;
  controllable: boolean;
  ceCertification: boolean;
  code: string;
  weight: number;
  erpCode: string;
  benorCode: string;

  family: Family;
  category: Category;
  itemType: ItemType;
  picture: Picture;
  esomusCode: EsomusCode;
  form: Form;

  active: boolean;

  obligationRisk: string;
  obligationEquipments: string;
  obligations: Array<Obligation>;
}
