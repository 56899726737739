<mat-expansion-panel [class.panel-disabled]="panelDisabled" [expanded]="expanded">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <a class="link-header" [routerLink]="'equipment_view'| path:{id: equipment.id}">{{label}}
        : {{equipment.label}}</a>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="container">
    <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
      <esomus-line-membership fxFlex="100" [item]="equipment"
                              [showAll]="false"></esomus-line-membership>
      <div fxLayoutAlign="center center">
        <button *ngIf="showBtns" mat-flat-button (click)="editEquipment()" color="success">
          <mat-icon>edit</mat-icon>
          Editer
        </button>
      </div>
      <esomus-dashboard-print-selector [visible]="fromEquipment" [screenID]="screenID"
                                       [itemID]="equipment.id"></esomus-dashboard-print-selector>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start">
      <div fxLayout="column" fxFlex="30" fxFlex.xs="100">
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <esomus-img fxFlex="100" [picture]="equipment.picture"></esomus-img>
          <esomus-line-data *ngIf="equipment.assignedTo" fxFlex="100" boldValue="Attribué à"
                            [textValue]="equipment.assignedTo.fullName"
                            [textURL]="'person_view'|path:{id: equipment.assignedTo.id}"></esomus-line-data>
        </div>
      </div>
      <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <esomus-line-data fxFlex="100" boldValue="Description"
                                [textValue]="equipment.label"></esomus-line-data>
              <esomus-line-data fxFlex="100" boldValue="Bâtiment"
                                [textValue]="equipment.building ? equipment.building.name : null"
                                [textURL]="getBuildingURL()"></esomus-line-data>
              <esomus-line-data fxFlex="100" boldValue="Local"
                                [textValue]="equipment.local ? equipment.local.name : null"
                                [textURL]="getLocalURL()"></esomus-line-data>
              <esomus-line-data *ngIf="isInContainer()" fxFlex="100" boldValue="Dans"
                                [textValue]="isInContainer() ? equipment.container.name : null"
                                [textURL]="isInContainer() ? getContainerURL() : null"></esomus-line-data>
              <esomus-line-data fxFlex="100" boldValue="Modèle"
                                [textValue]="equipment.itemDescription.model"></esomus-line-data>
              <esomus-line-data fxFlex="100" boldValue="Type"
                                [textValue]="equipment.itemDescription.type"></esomus-line-data>
              <esomus-line-data fxFlex="100" boldValue="Localisation"
                                [textValue]="equipment.location"></esomus-line-data>
            </div>
          </div>
          <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
            <div fxLayout="row wrap" fxLayoutAlign="start">
              <esomus-line-data fxFlex="100" boldValue="Omnium"
                                [boolValue]="equipment.omnium"></esomus-line-data>
              <esomus-line-data fxFlex="100" boldValue="Location"
                                [boolValue]="equipment.renting"></esomus-line-data>
            </div>
          </div>
          <esomus-line-data fxFlex="100" boldValue="Propriétaire"
                            boldFlex="25" textFlex="75"
                            [textValue]="ownerLabel"></esomus-line-data>
          <esomus-line-data fxFlex="100" boldValue="Commentaire"
                          boldFlex="25" textFlex="75"
                          [textValue]="equipment.comment"></esomus-line-data>
        </div>
      </div>
      <div fxLayout="column" fxFlex="20" fxFlex.xs="100">
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <esomus-info-box fxFlex="100" *ngIf="equipment.situation"
                           [line1]="equipment.situation.label"
                           [color]="situationColor"></esomus-info-box>
          <esomus-info-box fxFlex="100" *ngIf="equipment.status" [line1]="statusLabel"
                           [color]="statusColor" [actionAvailable]="actionAvailable"
                           [actionCb]="actionStatusCb"></esomus-info-box>
          <esomus-info-box *ngIf="equipment.itemDescription.controllable"
                           fxFlex="100" [line1]="conformityLabel" [color]="conformityColor"
                           [actionAvailable]="actionAvailable" [actionCb]="actionConformityCb"
                           [date1]="equipment.checkDate"></esomus-info-box>
          <esomus-info-box *ngIf="equipment.expirationDate" fxFlex="100"
                           [line1]="expirationLabel" [date1]="equipment.expirationDate"
                           [color]="expirationColor"></esomus-info-box>
          <img height="250" *ngIf="equipment.qrCode" [src]="equipment.qrCode" alt="QR Code">
        </div>
      </div>
    </div>
  </div>
</mat-expansion-panel>
