import { Entity } from '../models/entity.model';
import { FormGroup } from '@angular/forms';
import { FileInput } from 'ngx-material-file-input';
import { FieldType } from '../../shared/form-builder/form-builder.component';
import { Observable } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
import { AbstractHelper } from './abstract-helper';
import { FormsMap, FormsMappingMap } from '../../forms/components/form-component/form-component.component';
import { Params } from '@angular/router';

export class FormHelper extends AbstractHelper {

  private static nbForms: number;
  private static formsValidation = [];

  constructor() {
    super();
  }

  static getTypes() {
    return [
      { id: 1, label: 'Description' }, { id: 2, label: 'Localisation' }, { id: 3, label: 'Personne' },
      { id: 4, label: 'Anomalie' }, { id: 5, label: 'Tâche' }, { id: 6, label: 'Accident' },
    ];
  }

  static getType(type: number) {
    return this._getLabel(type, this.getTypes());
  }

  static initValues(entity: Entity, form: FormGroup) {
    let controls = form.controls;
    for (let field in controls) {
      if (!controls.hasOwnProperty(field)) {
        continue;
      }
      let data: Entity | number = entity;
      const properties = field.toString().split('.');
      let i = 0;

      while (i < properties.length && data !== null) {
        const property = properties[i];
        if (data[property] !== undefined) {
          data = data[property];
        }
        i++;
      }

      if (data !== null && typeof data === 'object' && data.hasOwnProperty('id')) {
        data = data.id;
      }

      controls[field].setValue(data);
    }
  }

  static submitForm(cd: ChangeDetectorRef, form: FormGroup, url: Observable<any>, successCb: (entity?: Entity) => void) {
    form.markAsTouched();
    url.subscribe(
      (result: Entity) => successCb(result),
      (errors: any) => FormHelper.setErrors(cd, errors, form)
    );
  }

  static setErrors(cd, event, form: FormGroup) {
    if (event.errors && event.errors.children) {
      const errors = event.errors.children;
      for (const property in errors) {
        if (errors.hasOwnProperty(property)) {
          const error = errors[property];
          if (error.hasOwnProperty('errors') && Array.isArray(error.errors)) {
            if (form.contains(property)) {
              form.controls[property].markAsTouched();
              form.controls[property].setErrors({
                custom: error.errors[0]
              });
            }
          }
        }
      }
      cd.detectChanges();
    }
  }

  static getQueryParams(query) {
    let params: Params = {};
    for (const key of Object.keys(query)) {
      if (query[key]) {
        if (query[key] instanceof Array) {
          query[key].forEach((item) => {
            params[`${key.toString()}[]`] = item;
          });
        } else {
          params[key.toString()] = query[key];
        }
      }
    }
    return params;
  }

  static buildEntity(baseEntity: Entity, form: FormGroup, types?: { [key: string]: { type: FieldType, multiple?: boolean } }): Entity {
    if (!types) {
      types = {};
    }
    let controls = form.controls;
    let entity = new Entity();

    if (baseEntity.id !== undefined && baseEntity.id !== null) {
      entity.id = baseEntity.id;
    }

    // tslint:disable-next-line:forin
    for (let field in controls) {
      const value = controls[field].value;
      let data = entity;

      if (value !== null && value !== '' && value !== -1) {
        const properties = field.split('.');

        for (let i = 0; i < properties.length; i++) {
          const property = properties[i];

          if (types[field] !== undefined) {
            switch (types[field].type) {
              case FieldType.GROUP:
                data[field] = this.buildEntity(baseEntity[field], form.get(field) as FormGroup, types);
                continue;
              case FieldType.JSON:
                data[field] = JSON.stringify(value);
                continue;
            }
          }

          if (data[property] === undefined && i + 1 < properties.length) {
            data[property] = {};
            data = data[property];
          } else if (typeof data[property] === 'object') {
            data = data[property];
          }

          if (i + 1 === properties.length) {
            if (types[field] !== undefined) {
              switch (types[field].type) {
                case FieldType.DATE:
                  data[property] = new Date(value);
                  break;
                case FieldType.FILE:
                  const fileInput: FileInput = value;
                  if (typeof fileInput === 'object') {
                    if (types[field].multiple) {
                      data[property] = fileInput.files;
                    } else if (fileInput.files.length) {
                      data[property] = fileInput.files[0];
                    }
                  }
                  break;
                case FieldType.SELECT:
                  if (types[field].multiple) {
                    data[property] = value.map((item: Entity) => item.id);
                  }
                  break;
                default:
                  data[property] = value;
              }
            } else {
              data[property] = value;
            }
          }
        }
      }
    }

    return entity;
  }

  static isInvalid(form: FormGroup): boolean {
    let isInvalid = false;

    for (let control in form.controls) {
      if (!form.controls.hasOwnProperty(control)) {
        continue;
      }
      let item = form.get(control);
      if (item instanceof FormGroup) {
        if (FormHelper.isInvalid(item)) {
          isInvalid = true;
        }
      } else {
        if (item.invalid) {
          isInvalid = true;
        }
      }
    }

    return isInvalid;
  }

  static submitDynamicForms(baseNbForms: number, forms: FormsMap, formsMapping: FormsMappingMap, id: number, service: any, cd: ChangeDetectorRef, callbackData: any, callback: any, serviceParams?: any) {
    FormHelper.nbForms = baseNbForms;
    FormHelper.formsValidation = [];

    for (const key in forms) {
      if (!forms.hasOwnProperty(key)) {
        continue;
      }

      FormHelper.nbForms++;
      if (forms[key].invalid) {
        return;
      }
    }

    for (const key in formsMapping) {
      if (!formsMapping.hasOwnProperty(key)) {
        continue;
      }

      const entityData = FormHelper.buildEntity(formsMapping[key], forms[key], {});
      FormHelper.submitForm(
        cd,
        forms[key],
        service.putData(entityData, id, parseInt(key.substr(5), 10), serviceParams),
        () => {
          FormHelper.formsValidation.push(true);
          if (FormHelper.nbForms === FormHelper.formsValidation.length) {
            callback(callbackData);
          }
        }
      );
    }

    if (FormHelper.nbForms === baseNbForms) {
      callback(callbackData);
    }
  }
}
