export enum ScreenEnum {
  COMPANY = 1,
  SITE = 2,
  BUILDING = 3,
  LOCAL = 4,
  CONTAINER = 5,
  VEHICLE = 6,
  EQUIPMENT = 7,
  FAMILY = 8,
  CATEGORY = 9,
  DOCUMENT_MANAGMENT = 10,
  ITEM_DESCRIPTION_COMPANY = 11,
  ITEM_DESCRIPTION_SITE = 12,
  ITEM_DESCRIPTION_BUILDING = 13,
  ITEM_DESCRIPTION_LOCAL = 14,
  ITEM_DESCRIPTION_CONTAINER = 15,
  ITEM_DESCRIPTION_VEHICLE = 16,
  ITEM_DESCRIPTION_EQUIPMENT = 17,
  ANOMALY = 18,
  ACTION = 19,
  TASK = 20,
  TASK_LIST = 21,
  PROGRAMMING = 22,
  PERSON = 23,
  USER = 24,
  FORM = 25,
  LIST_COMPONENT_LIST = 26,
  ANOMALY_TYPE_LIST = 27,
  TASK_TYPE_LIST = 28,
  DOCUMENT_TYPE_LIST = 29,
  OBLIGATION_TYPE_LIST = 31,
  OBLIGATION_LIST = 32,
  ROLE_LIST = 33,
  ACCIDENT_TYPE_LIST = 34,
  IMPACT_LIST = 35,
  ZONE_LIST = 36,
  ACCIDENT = 37,
  EFFECT_LIST = 38,
  PROGRAMMING_LIST = 39,
  PERSON_LIST = 40,
  USER_LIST = 41,
  FORM_LIST = 42,
  ACCIDENT_LIST = 43,
}
