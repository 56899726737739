import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { Observable } from 'rxjs';
import { Params } from '@angular/router';
import { TaskWorkload } from '../models/task-workload.model';

const crudPath: CRUDPath = {
  many: `/task_workloads/`,
  single: (id: number) => `/task_workloads/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class TaskWorkloadService extends CRUD<TaskWorkload> {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  postItemDescription(taskWorkload: TaskWorkload, itemDescriptionID: number): Observable<TaskWorkload> {
    return this.post(taskWorkload, `/item_descriptions/${itemDescriptionID}`);
  }

  putItemDescription(taskWorkload: TaskWorkload, itemDescriptionID: number): Observable<TaskWorkload> {
    return this.put(taskWorkload, `/item_descriptions/${itemDescriptionID}`);
  }

  deletItemDescription(taskWorkload: TaskWorkload, itemDescriptionID: number): Observable<void> {
    return this.delete(taskWorkload.id, `/item_descriptions/${itemDescriptionID}`);
  }

  getItemDescription(itemDescriptionID: number, searchData?: Params): Observable<Array<TaskWorkload>> {
    return this.findAll(`/item_descriptions/${itemDescriptionID}`, searchData);
  }
}
