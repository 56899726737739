<mat-accordion multi="true">
  <mat-expansion-panel expanded="true">
    <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
      <esomus-dashboard-print-selector [screenID]="screenID"></esomus-dashboard-print-selector>
    </div>
    <esomus-table [searchReceiver]="search" [options]="formTable"></esomus-table>
  </mat-expansion-panel>
  <esomus-dashboard-screen-wrapper [screenID]="screenID"></esomus-dashboard-screen-wrapper>
</mat-accordion>

<ng-template #headerTable>
  <esomus-ng-select *ngIf="form.contains('formType') && types"
                    [options]="types" [control]="form.get('formType')" (valueChange)="updateType($event)"
                    i18n-label="@@type" label="Type" [fxFlex]="100"
                    flex="100"></esomus-ng-select>
</ng-template>
