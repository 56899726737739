import { Entity } from './entity.model';
import { Form } from './form.model';
import { Person } from './person.model';
import { TaskType } from './task-type.model';
import { TaskScope } from './task-scope.model';
import { Task } from './task.model';
import { TaskTypeStatus } from './task-type-status.model';

export class TaskStatus extends Entity {
  date: Date;
  task: Task;
  type: TaskTypeStatus;
}
