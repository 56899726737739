<mat-accordion multi="true" *ngIf="anomaly">
  <esomus-equipment-panel [expanded]="false" [panelDisabled]="true"
                          [equipment]="anomaly.item"></esomus-equipment-panel>
  <esomus-anomaly-panel [fromAnomaly]="true" [expanded]="true"
                        [anomaly]="anomaly"></esomus-anomaly-panel>
  <esomus-action-anomaly-table [expanded]="true" [showAnomalyBtn]="false"
                               [showActionBtn]="showEquipmentManageBtns"
                               [actionAddRouteName]="'anomaly_action_add'"
                               [anomalyID]="anomaly.id"></esomus-action-anomaly-table>
  <mat-expansion-panel *ngIf="anomaly.form">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Information personalisée
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <esomus-form-display *ngIf="anomaly.form"
                               [fetchDataURL]="getFetchDataURL(anomaly.form.id)"></esomus-form-display>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <esomus-document-table [parent]="anomaly" parentType="anomaly"></esomus-document-table>
  <esomus-dashboard-screen-wrapper [screenID]="screenID"
                                   [itemID]="anomaly.id"></esomus-dashboard-screen-wrapper>
</mat-accordion>
