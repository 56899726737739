import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Task } from '../../../core/models/task.model';
import { RouteNameService } from '../../../core/services/route-name.service';
import { TaskHelper } from '../../../core/services/task-helper.service';
import { MatDialog } from '@angular/material/dialog';
import { TaskStatusListDialogComponent } from '../task-status-list-dialog/task-status-list-dialog.component';
import { TaskStatusDialogComponent } from '../task-status-dialog/task-status-dialog.component';
import { TaskStatus } from '../../../core/models/task-status.model';
import { ScreenEnum } from '../../../core/enum/screen.enum';

@Component({
  selector: 'esomus-task-panel',
  templateUrl: './task-panel.component.html',
  styleUrls: ['./task-panel.component.sass']
})
export class TaskPanelComponent implements OnInit {

  @Input() task: Task;
  @Input() expanded: boolean;
  @Input() showBtns: boolean;
  @Input() fromProgramming = false;
  @Input() fromTask = false;

  taskHelper = TaskHelper;
  openStatusListDialogCb: () => any;
  openStatusDialogCb: () => any;
  openStatusDialogAvailableCb: () => boolean;

  constructor(
    private routeNameService: RouteNameService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
  ) {
    this.expanded = true;
    this.showBtns = true;
  }

  ngOnInit() {
    this.openStatusListDialogCb = () => this.openStatusListDialog();
    this.openStatusDialogCb = () => this.openStatusDialog();
    this.openStatusDialogAvailableCb = () => this.showBtns && !this.fromProgramming;
  }

  editTask() {
    this.routeNameService.goTo('task_id', {id: this.task.id});
  }

  getEmergencyLabel() {
    return TaskHelper.getLabel(this.task.emergency);
  }

  getTaskViewURL() {
    return this.routeNameService.path('task_view', {id: this.task.id});
  }

  get screenID() {
    return ScreenEnum.TASK;
  }

  private openStatusListDialog() {
    this.dialog.open(TaskStatusListDialogComponent, {
      data: { task: this.task }, width: '780px'
    });
  }

  private openStatusDialog() {
    const dialogRef = this.dialog.open(TaskStatusDialogComponent, {
      data: { task: this.task }, width: '780px'
    });

    dialogRef.afterClosed().subscribe((result: TaskStatus) => {
      this.task.status = result.type.id;
      this.task.statusLabel = result.type.label;
      this.cd.detectChanges();
    });
  }
}
