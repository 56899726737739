import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { Task } from '../models/task.model';
import { TaskStatus } from '../models/task-status.model';

const crudPath: CRUDPath = {
  many: `/status/`,
  single: (id: number) => `/status/${id}`
};

@Injectable({
  providedIn: 'root'
})
export class TaskStatusService extends CRUD<TaskStatus>  {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  findAllByTask(taskID: number) {
    return this.findAll(`/tasks/${taskID}`);
  }

  postFromParent(task: Task, entity: TaskStatus) {
    return this.post(entity, `/tasks/${task.id}`);
  }
}
