<mat-accordion multi="true" *ngIf="accident">
  <esomus-accident-panel [accident]="accident" [expanded]="true"></esomus-accident-panel>
  <mat-expansion-panel *ngIf="accident.type.form">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Information complémentaire
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <esomus-form-display *ngIf="accident.type.form"
                               [fetchDataURL]="getFetchDataURL(accident.type.form.id)"></esomus-form-display>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <esomus-document-table [parent]="accident" parentType="accident"></esomus-document-table>
  <mat-expansion-panel *ngIf="accident.linkType !== null && accident.linkType !== undefined" expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Elément lié
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <esomus-line-data boldValue="Type d'élément"
                            [textValue]="accidentHelper.getAccidentLinkLabel(accident.linkType)"></esomus-line-data>
        </div>
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <esomus-line-data boldValue="Elément"
                            [textValue]="getLinkedLabel()"></esomus-line-data>
        </div>
        <div fxLayout="column" fxFlex="100" fxFlex.xs="100">
          <esomus-line-data boldValue="Description" [textValue]="accident.linkedDescription" boldFlex="25"
                            textFlex="75"></esomus-line-data>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <esomus-accident-person-panel [expanded]="true"
                                [accident]="accident"></esomus-accident-person-panel>
  <esomus-action-anomaly-table *ngIf="accident.linkType === 0" [expanded]="true" [showAnomalyBtn]="false"
                               label="Actions correctrices"
                               [actionAddRouteName]="'accident_action_add'"
                               [accidentID]="accident.id"></esomus-action-anomaly-table>
  <esomus-document-table [parent]="accident" parentType="closedAccident"
                         label="Documents de clôture"></esomus-document-table>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Clôture
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="container">
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" [fxFlex]="100/3" fxFlex.xs="100">
          <div class="dashboard-print-container" fxLayout="row wrap" fxLayoutAlign="start">
            <div fxLayoutAlign="center center">
              <button *ngIf="accident.closedDate === null" mat-flat-button
                      (click)="closeAccident()" color="warn">
                <mat-icon>edit</mat-icon>
                Clôturer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start">
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <esomus-line-data boldValue="Date de clôture" textType="date"
                            [textValue]="accident.closedDate"></esomus-line-data>
        </div>
        <div fxLayout="column" fxFlex="50" fxFlex.xs="100">
          <esomus-line-data boldValue="Clôturer par"
                            [textValue]="accident?.closedBy?.person?.fullName"></esomus-line-data>
        </div>
        <div fxLayout="column" fxFlex="100" fxFlex.xs="100">
          <esomus-line-data boldValue="Commentaire" [textValue]="accident.closedComment" boldFlex="25"
                            textFlex="75"></esomus-line-data>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <esomus-dashboard-screen-wrapper [screenID]="screenID" [itemID]="accident.id"></esomus-dashboard-screen-wrapper>
</mat-accordion>
