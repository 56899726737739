import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Accident } from '../../../core/models/accident.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AccidentService } from '../../../core/api/accident.service';
import { UserService } from '../../../core/api/user.service';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ToastrService } from '../../../core/services/toastr.service';
import { AccidentDataService } from '../../../core/api/accident-data.service';
import { AccidentHelper } from '../../../core/services/accident-helper.service';
import { ScreenEnum } from '../../../core/enum/screen.enum';

@Component({
  selector: 'esomus-accident-view',
  templateUrl: './accident-view.component.html',
  styleUrls: ['./accident-view.component.sass']
})
export class AccidentViewComponent implements OnInit {

  accident: Accident;
  accidentHelper = AccidentHelper;

  constructor(
    private i18n: I18n,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private accidentService: AccidentService,
    private userService: UserService,
    private routeNameService: RouteNameService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private accidentDataService: AccidentDataService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(() => {
      const accidentID = parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);

      this._getAccident(accidentID);
    });
  }

  private _getAccident(id: number) {
    this.accidentService.find(id).subscribe((accident: Accident) => {
      this.accident = accident;

      this.cd.detectChanges();
    });
  }

  getFetchDataURL(formID: number) {
    return this.accidentDataService.getData(this.accident.id, formID);
  }

  getLinkedLabel() {
    switch (this.accident.linkType) {
      case 0:
        return this.accident.linkedEquipment.name;
      case 1:
        return this.accident.linkedAnomaly.description;
      case 2:
        return this.accident.linkedAction.description;
      case 3:
        return this.accident.linkedAccident.description;
    }
  }

  closeAccident() {
    if (this.accident.closedDate === null) {
      this.routeNameService.goTo('accident_close', {id: this.accident.id});
    }
  }

  get screenID() {
    return ScreenEnum.ACCIDENT;
  }
}
