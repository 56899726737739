<form>
  <h2 mat-dialog-title>Valeur par défaut : <i>{{data.itemDescriptionTaskForm.form.name}} / {{data.itemDescriptionTaskForm.taskType.label}}</i></h2>
  <mat-dialog-content>
    <esomus-form-component *ngIf="data.itemDescriptionTaskForm.form.id && forms"
                           [formID]="data.itemDescriptionTaskForm.form.id" [forms]="forms"
                           [formsMapping]="formsMapping" flex="100"
                           [fetchDataURL]="getFetchDataURL(data.itemDescriptionTaskForm.form.id)"></esomus-form-component>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>Annuler</button>
    <esomus-submit (submit)="submit()"></esomus-submit>
  </mat-dialog-actions>
</form>
