import { Injectable } from '@angular/core';
import { CRUD, CRUDPath } from './crud';
import { HttpWrapperService } from '../services/http-wrapper.service';
import { environment } from '../../../environments/environment';
import { Entity } from '../models/entity.model';
import { Data } from '../models/data.model';
import { FormDefaultValue } from '../models/form-default-value.model';
import { Params } from '@angular/router';

const crudPath: CRUDPath = {
  many: `/default_values/`,
  single: (id: number) => `/default_values`
};

@Injectable({
  providedIn: 'root'
})
export class FormDefaultValueService extends CRUD<FormDefaultValue> {

  constructor(
    protected http: HttpWrapperService
  ) {
    super(http, crudPath);
  }

  putData(entity: Entity, itemID: number, formID: number, params?: Params) {
    return this.put(entity, `/forms/${formID}`, params);
  }

  getData(formID: number, params: Params) {
    return this.http.get<Array<Data>>(`${environment.api.url}/forms/${formID}${this.crudPath.many}`, params);
  }
}
