import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { TaskWorkloadService } from '../../../core/api/task-workload.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '../../../shared/form-builder/form-builder.component';
import { TaskWorkload } from '../../../core/models/task-workload.model';
import { FormHelper } from '../../../core/services/form-helper.service';
import { ItemDescription } from '../../../core/models/item-description.model';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { TaskTypeService } from '../../../core/api/task-type.service';

@Component({
  selector: 'esomus-task-workload-dialog',
  templateUrl: './task-workload-dialog.component.html',
  styleUrls: ['./task-workload-dialog.component.sass']
})
export class TaskWorkloadDialogComponent implements OnInit {
  loading: boolean;
  entityForm: FormGroup;
  fieldType = FieldType;

  taskTypeOptions: EntityOption;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { taskWorkload: TaskWorkload },
    private taskWorkloadService: TaskWorkloadService,
    private taskTypeService: TaskTypeService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<TaskWorkloadDialogComponent>
  ) {
  }

  ngOnInit(): void {
    this.loading = true;
    console.log(this.data);

    this.entityForm = this.fb.group({
      weight: [this.data.taskWorkload.weight, [Validators.required]],
      taskType: [this.data.taskWorkload.taskType ? this.data.taskWorkload.taskType.id : null, [Validators.required]]
    });

    this.taskTypeOptions = {get: () => this.taskTypeService.findAll(), propName: 'label'};

    this.loading = false;
  }

  submit(): void {
    if (this.entityForm.invalid) {
      return;
    }

    const entity = FormHelper.buildEntity(this.data.taskWorkload, this.entityForm, {}) as TaskWorkload;

    FormHelper.submitForm(
      this.cd,
      this.entityForm,
      this.data.taskWorkload.id ?
        this.taskWorkloadService.putItemDescription(entity, this.data.taskWorkload.itemDescriptionID) : this.taskWorkloadService.postItemDescription(entity, this.data.taskWorkload.itemDescriptionID),
      (result: TaskWorkload) => {
        this.dialogRef.close(result);
      }
    )
    ;
  }
}
