import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TaskService } from '../../../core/api/task.service';
import { TaskStatusService } from '../../../core/api/task-status.service';
import { ColumnSortDirection, ColumnType, TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { Task } from '../../../core/models/task.model';
import { Params } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { TaskStatus } from '../../../core/models/task-status.model';
import { TaskHelper } from '../../../core/services/task-helper.service';

@Component({
  selector: 'esomus-task-status-list-dialog',
  templateUrl: './task-status-list-dialog.component.html',
  styleUrls: ['./task-status-list-dialog.component.sass']
})
export class TaskStatusListDialogComponent implements OnInit {
  statusTable: TableOptions;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      task: Task,
    },
    private taskService: TaskService,
    private taskStatusService: TaskStatusService,
    private cd: ChangeDetectorRef,
    private i18n: I18n,
    private dialogRef: MatDialogRef<TaskStatusListDialogComponent>
  ) { }

  ngOnInit(): void {
    this.statusTable = {
      columnDefs: [
        {
          name: this.i18n({ value: 'Date du statut', id: 'statusDate' }),
          prop: 'date', type: ColumnType.DATE, width: '50%'
        },
        {
          name: this.i18n({ value: 'Statut', id: 'statusLabel' }),
          prop: 'type.label', width: '50%'
        },
      ],
      findDataCb: (searchData: Params) => this.taskStatusService.findAllByTask(this.data.task.id),
      defaultSortActive: 'date',
      defaultSortDirection: ColumnSortDirection.DESC
    };
  }

  closeModal() {
    this.dialogRef.close(false);
  }
}
