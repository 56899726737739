<form>
  <mat-accordion multi="true" class="mat-edit" *ngIf="task">
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Tâche : {{ !task.id ? 'Création' : 'Edition'}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="container">
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div fxLayout="column" fxFlex="30">
            <mat-checkbox *ngIf="isEditable" [formControl]="entityForm.get('definition')"
                          (change)="updateDefinition()" [disabled]="!!task.nbProgrammings"
                          i18n-label="@@definition" label="Canevas" fxFlex="50">Canevas
            </mat-checkbox>
          </div>
          <div fxLayout="column" fxFlex="70">
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px grid">
              <esomus-ng-select *ngIf="isEditable && personOptions" [options]="personOptions"
                                [control]="entityForm.get('person')" [disabled]="!task.id"
                                i18n-label="@@definedBy" label="Defini par" fxFlex="100"
                                flex="100"></esomus-ng-select>
              <esomus-line-data *ngIf="!isEditable" boldValue="Defini par" fxFlex="50" flex="100"
                                [textValue]="task.person ? task.person.fullName : null"></esomus-line-data>
              <esomus-ng-select *ngIf="isEditable && taskTypeOptions" [options]="taskTypeOptions"
                                [control]="entityForm.get('taskType')"
                                i18n-label="@@type" label="Type" fxFlex="100"
                                flex="100"></esomus-ng-select>
              <esomus-line-data *ngIf="!isEditable" boldValue="Type" fxFlex="50" flex="100"
                                [textValue]="task.taskType.label"></esomus-line-data>
              <esomus-input *ngIf="isEditable" [type]="fieldType.TEXT" [autofocus]="true"
                            [control]="entityForm.get('description')"
                            i18n-label="@@description" label="Description" flex="100"
                            fxFlex="100"></esomus-input>
              <esomus-line-data *ngIf="!isEditable" boldValue="Description" fxFlex="50" flex="100"
                                [textValue]="task.description"></esomus-line-data>
              <esomus-input *ngIf="isEditable" [type]="fieldType.TEXT"
                            [control]="entityForm.get('comment')"
                            i18n-label="@@comment" label="Commentaire" flex="100"
                            fxFlex="100"></esomus-input>
              <esomus-line-data *ngIf="!isEditable" boldValue="Commentaire" fxFlex="50" flex="100"
                                [textValue]="task.comment"></esomus-line-data>
              <esomus-ng-select *ngIf="isEditable && emergencyOptions" [options]="emergencyOptions"
                                [control]="entityForm.get('emergency')"
                                i18n-label="@@emergency" label="Urgence" fxFlex="50"
                                flex="100"></esomus-ng-select>
              <esomus-line-data *ngIf="!isEditable" boldValue="Urgence" fxFlex="50" flex="100"
                                [textValue]="emergencyLabel"></esomus-line-data>
              <esomus-input *ngIf="isEditable && entityForm.get('deadlineDate')"
                            [type]="fieldType.DATE" [control]="entityForm.get('deadlineDate')"
                            i18n-label="@@deadlineDate" label="Date butoir" flex="100"
                            fxFlex="50"></esomus-input>
              <esomus-line-data *ngIf="!isEditable" boldValue="Date butoir" fxFlex="50" flex="100"
                                [textValue]="task.deadlineDate" textType="date"></esomus-line-data>
              <esomus-ng-select *ngIf="statusOptions" [options]="statusOptions"
                                [control]="entityForm.get('status')"
                                i18n-label="@@status" label="Statut" fxFlex="50"
                                flex="100"></esomus-ng-select>
              <esomus-line-data *ngIf="!statusOptions" boldValue="Statut" fxFlex="50" flex="100"
                                [textValue]="statusLabel"></esomus-line-data>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel expanded="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Formulaire de réalisation
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="container">
        <div fxLayout="row wrap" fxLayoutAlign="start">
          <div fxLayout="column" fxFlex="30">
          </div>
          <div fxLayout="column" fxFlex="70">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-ng-select *ngIf="formOptions" [options]="formOptions"
                                [control]="entityForm.get('form')"
                                i18n-label="@@type" label="Type" fxFlex="50"
                                flex="100"></esomus-ng-select>
              <esomus-line-data *ngIf="!isEditable" boldValue="Type" fxFlex="50" flex="100"
                                [textValue]="task.form ? task.form.name : null"></esomus-line-data>
            </div>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel expanded="true" *ngIf="task">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Portée
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="container">
        <div fxLayout="row wrap" fxLayoutAlign="start" fxLayoutGap="5px grid">
          <div *ngIf="!task.generated" fxLayout="column" fxFlex="50">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-ng-select *ngIf="companyOptions" [options]="companyOptions"
                                [control]="scopeForm.get('company')"
                                i18n-label="@@company" label="Entreprise" fxFlex="100"
                                flex="100" (valueChange)="updateSite()"></esomus-ng-select>
              <esomus-ng-select *ngIf="scopeForm.get('site')" [options]="siteOptions"
                                [control]="scopeForm.get('site')"
                                i18n-label="@@site" label="Site" fxFlex="100"
                                flex="100" (valueChange)="updateBuilding()"></esomus-ng-select>
              <esomus-ng-select *ngIf="scopeForm.get('building')" [options]="buildingOptions"
                                [control]="scopeForm.get('building')"
                                i18n-label="@@building" label="Bâtiment" fxFlex="100"
                                flex="100" (valueChange)="updateLocal()"></esomus-ng-select>
              <esomus-ng-select *ngIf="scopeForm.get('local')" [options]="localOptions"
                                [control]="scopeForm.get('local')"
                                i18n-label="@@local" label="Local" fxFlex="100"
                                flex="100"></esomus-ng-select>
            </div>
          </div>
          <div *ngIf="!task.generated" fxLayout="column" fxFlex="50">
            <div fxLayout="row wrap" fxLayoutAlign="start center">
              <esomus-ng-select *ngIf="familyOptions" [options]="familyOptions"
                                [control]="scopeForm.get('family')"
                                i18n-label="@@family" label="Famille" fxFlex="100"
                                flex="100" (valueChange)="updateCategory()"></esomus-ng-select>
              <esomus-ng-select *ngIf="categoryOptions" [options]="categoryOptions"
                                [control]="scopeForm.get('category')"
                                i18n-label="@@category" label="Catégorie" fxFlex="100"
                                flex="100"></esomus-ng-select>
              <esomus-ng-select *ngIf="situationOptions" [options]="situationOptions"
                                [control]="scopeForm.get('situation')" (valueChange)="updateSituation()"
                                i18n-label="@@situation" label="Situation" fxFlex="100"
                                flex="100" [multiple]="true"></esomus-ng-select>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px">
              <button *ngIf="hasCompany && hasFamily && hasSituation" type="button" (click)="addData()" color="success" mat-flat-button>
                  Ajouter une portée
              </button>
              <button *ngIf="hasCompany && hasSite" type="button" (click)="openPartialScopeDialog()" color="success" mat-flat-button>
                Ajouter une portée partielle
              </button>
            </div>
          </div>
          <div fxLayout="column" fxFlex="100">
            <esomus-table *ngIf="tableOptions" [searchReceiver]="tableEmitter"
                          [options]="tableOptions"></esomus-table>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="btn-group-right">
    <esomus-back-button></esomus-back-button>
    <esomus-submit (submit)="submit()"></esomus-submit>
  </div>
</form>

<ng-template #formModal>
  <form>
    <h3 mat-dialog-title>
      <span>Valider le changement de statut</span>
    </h3>
    <div mat-dialog-content>
      <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap.gt-xs="10px">
        <p>Le changement de statut de la tâche peut impacter {{task.nbActions}} action(s).
          Voulez-vous continuer ?</p>
      </div>
    </div>
    <div mat-dialog-actions>
      <esomus-submit (submit)="confirmTask()"></esomus-submit>
      <button type="button" mat-stroked-button i18n="@@cancel" (click)="closeTaskModal()">
        Annuler
      </button>
    </div>
  </form>
</ng-template>
