import { Provider } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { SidenavItemConfig } from './sidenav-item/sidenav-item.model';
import { FULL_PARAMETERS_RIGHTS, RightEnum } from '../../../../../core/enum/right.enum';

export type SidenavMenu = SidenavItemConfig[];

export const MENU_SIDENAV = 'menu_token';

export const menuProvider: Provider = {
  provide: MENU_SIDENAV,
  deps: [I18n],
  useFactory: (i18n: I18n): SidenavMenu => [
    // =====================================================================================================
    // == DASHBOARD
    // =====================================================================================================
    {
      name: i18n({value: 'Dashboard', id: 'dashboard'}),
      icon: 'speed',
      routeName: 'dashboard_view',
      subItems: [],
      position: null
    },
    // =====================================================================================================
    // == INFORMATION
    // =====================================================================================================
    {
      name: i18n({value: 'Information', id: 'information'}),
      icon: 'notification_important',
      routeName: 'dashboard_information',
      subItems: [],
      position: null
    },
    // =====================================================================================================
    // == INFORMATION
    // =====================================================================================================
    {
      name: i18n({value: 'Entreprises', id: 'companies'}),
      icon: 'apartment',
      routeName: 'companies',
      pathPattern: /^\/(companies|sites|buildings|locals|equipments).*/,
      subItems: [],
      position: null,
    },
    // =====================================================================================================
    // == FAMILY
    // =====================================================================================================
    {
      name: i18n({value: 'Familles', id: 'families'}),
      icon: 'folder',
      routeName: 'families',
      pathPattern: /^\/(families|categories|item-descriptions).*/,
      subItems: [],
      position: null,
      rights: [RightEnum.FAMILIES]
    },
    // =====================================================================================================
    // == PERSON
    // =====================================================================================================
    {
      name: i18n({value: 'Personnes', id: 'persons'}),
      icon: 'perm_contact_calendar',
      routeName: 'persons',
      pathPattern: /^\/persons.*/,
      subItems: [],
      position: null,
      rights: [RightEnum.PERSONS]
    },
    // =====================================================================================================
    // == TASK
    // =====================================================================================================
    {
      name: i18n({value: 'Tâches', id: 'tasks'}),
      icon: 'list',
      routeName: 'tasks',
      pathPattern: /^\/tasks.*/,
      subItems: [],
      position: null,
      rights: [RightEnum.TASKS]
    },
    // =====================================================================================================
    // == PROGRAMMING
    // =====================================================================================================
    {
      name: i18n({value: 'Programmation', id: 'programmings'}),
      icon: 'calendar_today',
      routeName: 'programmings',
      pathPattern: /^\/programmings.*/,
      subItems: [],
      position: null,
      rights: [RightEnum.PROGRAMMING]
    },
    // =====================================================================================================
    // == ACCIDENT
    // =====================================================================================================
    {
      name: i18n({value: 'Accidents', id: 'accidents'}),
      icon: 'warning',
      routeName: 'accidents',
      pathPattern: /^\/accidents.*/,
      subItems: [],
      position: null,
      rights: [RightEnum.ACCIDENTS]
    },
    // =====================================================================================================
    // == PARAMETERS
    // =====================================================================================================
    {
      name: i18n({value: 'Paramètres', id: 'params'}),
      icon: 'settings',
      subItems: [
        {
          name: i18n({value: 'Types de document', id: 'documentType'}),
          icon: 'description',
          routeName: 'document_types',
          pathPattern: /^\/document-types.*/,
          subItems: [],
          position: null,
          rights: [RightEnum.DOCUMENT_TYPES]
        },
        {
          name: i18n({value: 'Gestion documentaire', id: 'documentManagement'}),
          icon: 'library_books',
          routeName: 'documents',
          pathPattern: /^\/documents.*/,
          subItems: [],
          position: null,
          rights: [RightEnum.DOCUMENT_MANAGEMENT]
        },
        {
          name: i18n({value: 'Types d\'anomalie', id: 'anomalyType'}),
          icon: 'error',
          routeName: 'anomaly_types',
          pathPattern: /^\/anomaly-types.*/,
          subItems: [],
          position: null,
          rights: [RightEnum.ANOMALY_TYPES]
        },
        {
          name: i18n({value: 'Types de tâche', id: 'taskType'}),
          icon: 'list',
          routeName: 'task_types',
          pathPattern: /^\/task-types.*/,
          subItems: [],
          position: null,
          rights: [RightEnum.TASK_TYPES]
        },
        {
          name: i18n({value: 'Types d\'obligation', id: 'obligationType'}),
          icon: 'bookmarks',
          routeName: 'obligation_types',
          pathPattern: /^\/obligation-types.*/,
          subItems: [],
          position: null,
          rights: [RightEnum.OBLIGATION_TYPES]
        },
        {
          name: i18n({value: 'Obligations', id: 'obligations'}),
          icon: 'class',
          routeName: 'obligations',
          pathPattern: /^\/obligations.*/,
          subItems: [],
          position: null,
          rights: [RightEnum.OBLIGATIONS]
        },
        {
          name: i18n({value: 'Roles', id: 'roles'}),
          icon: 'supervised_user_circle',
          routeName: 'roles',
          pathPattern: /^\/roles.*/,
          subItems: [],
          position: null,
          rights: [RightEnum.ROLES]
        },
        {
          name: i18n({value: 'Types d\'accident', id: 'accidentType'}),
          icon: 'warning',
          routeName: 'accident_types',
          pathPattern: /^\/accident-types.*/,
          subItems: [],
          position: null,
          rights: [RightEnum.ACCIDENT_TYPES]
        },
        {
          name: i18n({value: 'Impact', id: 'impact'}),
          icon: 'healing',
          routeName: 'impacts',
          pathPattern: /^\/impacts.*/,
          subItems: [],
          position: null,
          rights: [RightEnum.IMPACTS]
        },
        {
          name: i18n({value: 'Zone', id: 'zone'}),
          icon: 'accessibility',
          routeName: 'zones',
          pathPattern: /^\/zones.*/,
          subItems: [],
          position: null,
          rights: [RightEnum.ZONES]
        },
        {
          name: i18n({value: 'Effet', id: 'effect'}),
          icon: 'flare',
          routeName: 'effects',
          pathPattern: /^\/effects.*/,
          subItems: [],
          position: null,
          rights: [RightEnum.EFFECTS]
        },
      ],
      position: null,
      rights: FULL_PARAMETERS_RIGHTS
    },
    // =====================================================================================================
    // == REPORTS
    // =====================================================================================================
    {
      name: i18n({value: 'Administration', id: 'administration'}),
      icon: 'settings',
      subItems: [
        {
          name: i18n({value: 'Listes', id: 'lists'}),
          icon: 'menu',
          routeName: 'lists',
          pathPattern: /^\/lists.*/,
          subItems: [],
          position: null,
          rights: [RightEnum.LISTS]
        },
        {
          name: i18n({value: 'Formulaires', id: 'forms'}),
          icon: 'assignment',
          routeName: 'forms',
          pathPattern: /^\/forms.*/,
          subItems: [],
          position: null,
          rights: [RightEnum.FORMS]
        },
        {
          name: i18n({value: 'Utilisateurs', id: 'users'}),
          icon: 'account_circle',
          path: 'users',
          pathPattern: /^\/users.*/,
          subItems: [],
          position: null,
          rights: [RightEnum.USERS]
        },
        {
          name: i18n({value: 'Configuration', id: 'configuration'}),
          icon: 'settings',
          path: 'parameters',
          pathPattern: /^\/parameters.*/,
          subItems: [],
          position: null,
          rights: [RightEnum.PARAMETERS]
        },
        {
          name: i18n({value: 'Définitions', id: 'definitions'}),
          icon: 'settings',
          path: 'report-definitions',
          pathPattern: /^\/report-definitions.*/,
          subItems: [],
          position: null,
          rights: [RightEnum.PARAMETERS]
        },
        {
          name: i18n({value: 'Modèles', id: 'models'}),
          icon: 'settings',
          path: 'report-models',
          pathPattern: /^\/report-models.*/,
          subItems: [],
          position: null,
          rights: [RightEnum.PARAMETERS]
        },
        {
          name: i18n({value: 'CSS', id: 'css'}),
          icon: 'settings',
          path: 'report-web-styles',
          pathPattern: /^\/report-web-styles.*/,
          subItems: [],
          position: null,
          rights: [RightEnum.PARAMETERS]
        },
        {
          name: i18n({value: 'Profiles', id: 'profiles'}),
          icon: 'settings',
          path: 'profiles',
          pathPattern: /^\/profiles.*/,
          subItems: [],
          position: null,
          rights: [RightEnum.PARAMETERS]
        },
      ],
      rights: FULL_PARAMETERS_RIGHTS
    }
  ]
};
