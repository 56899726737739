import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ItemDescription } from '../../../core/models/item-description.model';
import { TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { Subject } from 'rxjs';
import { Params } from '@angular/router';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ItemDescriptionTaskForm } from '../../../core/models/item-description-task-form.model';
import { ItemDescriptionTaskFormService } from '../../../core/api/item-description-task-form.service';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ItemDescriptionTaskFormDialogComponent } from '../item-description-task-form-dialog/item-description-task-form-dialog.component';
import { ItemDescriptionTaskFormFormDialogComponent } from '../item-description-task-form-form-dialog/item-description-task-form-form-dialog.component';

@Component({
  selector: 'esomus-item-description-task-form-panel',
  templateUrl: './item-description-task-form-panel.component.html',
  styleUrls: ['./item-description-task-form-panel.component.sass']
})
export class ItemDescriptionTaskFormPanelComponent implements OnInit, OnChanges, OnDestroy {

  @Input() itemDescription: ItemDescription;
  @Input() showBtns: boolean;

  nbTaskForms: number;
  taskFormsTable: TableOptions;
  taskFormTableEmitter: EventEmitter<any> = new EventEmitter<any>();

  private detroyed$: Subject<boolean>;

  constructor(
    private i18n: I18n,
    private itemDescriptionTaskFormService: ItemDescriptionTaskFormService,
    private cd: ChangeDetectorRef,
    private dialog: MatDialog
  ) {
    this.detroyed$ = new Subject<boolean>();
  }

  ngOnInit(): void {
    this.taskFormsTable = {
      columnDefs: [
        {
          prop: 'form.name', width: '45%',
          name: this.i18n({ value: 'Formulaire', id: 'form' })
        },
        {
          prop: 'taskType.label', width: '45%',
          name: this.i18n({ value: 'Type de tâche', id: 'taskType' })
        },
      ],
      findDataCb: (searchData: Params) => {
        return this.itemDescriptionTaskFormService.getTaskForm(this.itemDescription.id);
      },
      actions: {
        canCreate: this.showBtns,
        create: [
          {
            btnName: this.i18n({ value: 'Ajouter un formulaire', id: 'btnAddTaskForm' }),
            createCb: () => {
              const itemDescriptionTaskForm = new ItemDescriptionTaskForm();
              itemDescriptionTaskForm.itemDescriptionID = this.itemDescription.id;
              this.openDialog(itemDescriptionTaskForm);
            }
          },
        ],
        canUpdate: () => this.showBtns,
        updateCb: (item: ItemDescriptionTaskForm) => this.openFormDialog(item),
        canDelete: () => this.showBtns,
        deleteCb: (item: ItemDescriptionTaskForm) => this.itemDescriptionTaskFormService.deleteTaskForm(item, this.itemDescription.id),
        columnWidth: '10%'
      }
    };
  }

  ngOnDestroy() {
    this.detroyed$.next();
    this.detroyed$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.itemDescription) {
      console.log(changes.itemDescription);
      this.taskFormTableEmitter.emit();
    }
  }

  openDialog(itemDescriptionTaskForm: ItemDescriptionTaskForm) {
    const dialogRef = this.dialog.open(ItemDescriptionTaskFormDialogComponent, {
      width: '560px',
      data: { itemDescriptionTaskForm }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.detroyed$))
      .subscribe(s => {
        this.taskFormTableEmitter.emit();
      });
  }

  openFormDialog(itemDescriptionTaskForm: ItemDescriptionTaskForm) {
    const dialogRef = this.dialog.open(ItemDescriptionTaskFormFormDialogComponent, {
      width: '760px',
      data: { itemDescriptionTaskForm }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.detroyed$))
      .subscribe(s => {
      });
  }

  updateNbTaskForms(nbTaskForms: number) {
    this.nbTaskForms = nbTaskForms;
  }
}
