import { Component, ElementRef, EventEmitter, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { LightItem } from '../../../core/models/light-item.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { RouteNameService } from '../../../core/services/route-name.service';
import { ActionAnomalyService } from '../../../core/api/action-anomaly.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BuildingService } from '../../../core/api/building.service';
import { TaskService } from '../../../core/api/task.service';
import { EntityOption } from '../../../shared/form-builder/components/ng-select/ng-select.component';
import { ColumnSortDirection, TableOptions } from '../../../shared/form-builder/components/table/table.component';
import { ActionAnomaly } from '../../../core/models/action-anomaly.model';
import { TaskHelper } from '../../../core/services/task-helper.service';
import { Params } from '@angular/router';
import { Task } from '../../../core/models/task.model';
import { ActionService } from '../../../core/api/action.service';
import { of } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'esomus-action-task-table',
  templateUrl: './action-task-table.component.html',
  styleUrls: ['./action-task-table.component.sass']
})
export class ActionTaskTableComponent implements OnInit {
  @ViewChild('headerActionTable', { static: true }) headerActionTable: TemplateRef<any>;
  @ViewChild('confirmModal', { static: true }) confirmModal: TemplateRef<any>;

  confirmModalRef: MatDialogRef<ActionTaskTableComponent>;

  @Input() taskID: number;
  @Input() displayAll: boolean;
  @Input() expanded: boolean;

  search: EventEmitter<any> = new EventEmitter<any>();

  cOptions: EntityOption;
  buildingOptions: EntityOption;
  form: FormGroup;

  itemTable: TableOptions;
  taskHelper = TaskHelper;

  nbItems: number;
  currentBuildingID = null;

  constructor(
    private element: ElementRef,
    private i18n: I18n,
    private dialog: MatDialog,
    private routeNameService: RouteNameService,
    private actionAnomalyService: ActionAnomalyService,
    private fb: FormBuilder,
    private buildingService: BuildingService,
    private taskService: TaskService,
    private actionService: ActionService
  ) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      c: [this.displayAll ? -1 : 0]
    });

    this.buildingOptions = {
      get: () =>
        this.buildingService.findAllByTask(this.taskID).pipe(
          map((items: Array<LightItem>) => {
            if (items.length) {
              let all = new LightItem();
              all.id = -1;
              all.name = 'Tous';
              items.push(all);
            }
            return items;
          })
        ),
      propName: 'name'
    };
    this.form.addControl('building', this.fb.control(null));

    this.cOptions = {
      get: () => of([
        { id: -1, label: 'Tout' }, { id: 0, label: 'Ouvert' }, { id: 1, label: 'Clôturé' }
      ]), propName: 'label'
    };

    this.itemTable = {
      columnDefs: [
        {
          name: this.i18n({ value: 'Site', id: 'site' }),
          prop: 'site.name', width: '15%'
        },
        {
          name: this.i18n({ value: 'Bâtiment', id: 'building' }),
          prop: 'building.name', width: '15%'
        },
        {
          name: this.i18n({ value: 'Local', id: 'local' }),
          prop: 'local.name', width: '15%'
        },
        {
          name: this.i18n({ value: 'Descripion Eqt', id: 'equipmentDescription' }),
          prop: 'item.label', width: '20%'
        },
        {
          name: this.i18n({ value: 'Localisation', id: 'location' }),
          prop: 'item.location', width: '20%'
        },
        {
          name: this.i18n({ value: 'Statut', id: 'status' }),
          prop: 'status', valueCb: (entity: Task) => this.taskHelper.getStatusLabel(entity.status), width: '10%'
        },
      ],
      findDataCb: (params: Params) => {
        if (!params.hasOwnProperty('type') || params.type === null || params.type === undefined) {
          if (this.displayAll) {
            params.type = -1;
          } else {
            params.type = 0;
          }
        }
        return this.actionService.findAllByTask(this.taskID, params);
      },
      defaultSortActive: 'description',
      defaultSortDirection: ColumnSortDirection.ASC,
      actions: {
        readCb: (entity: ActionAnomaly) => {
          this.routeNameService.goTo('action_view', { id: entity.id });
        },
        columnWidth: '5%'
      },
      headTemplate: this.headerActionTable
    };
  }

  updateNbItems(nbItems: number) {
    this.nbItems = nbItems;
  }

  cChange() {
    let c = this.form.get('c').value;
    if (c === null) {
      c = -1;
    }
    this.search.emit({
      type: c,
      buildingID: this.currentBuildingID
    });
  }

  buildingChange() {
    this.currentBuildingID = this.form.get('building').value;
    this.cChange();
  }

  closeActions() {
    if (!this.currentBuildingID) {
      return;
    }

    this.taskService.postValidateActionByBuilding(this.taskID, this.currentBuildingID).subscribe((task: Task) => {
      this.currentBuildingID = null;

      this.form.get('building').setValue(null);

      this.cChange();
    });

    this.closeConfirmModal();
  }

  openConfirmModal() {
    this.confirmModalRef = this.dialog.open(this.confirmModal);
  }

  closeConfirmModal() {
    this.confirmModalRef.close();
    this.confirmModalRef = null;
  }
}
